import React from 'react';
import PropTypes from 'prop-types';

export const InputText = ({
  id,
  name,
  value,
  label,
  register,
  placeholder,
  type,
  callback,
  hasError,
  getValues,
  setValue,
  pattern,
  className,
  containerClassName,
  suggestions,
  required,
  validationSchema,
  minValue,
  maxValue,
  maxLength,
  minLength,
  callbackSubmit
}) => {

  const errorClassList = "";

  const { onChange, ...rest } = register(name);

  const getCustomValidator = () => {
    let customValidator = {};
    if (pattern) customValidator.pattern = new RegExp(pattern);
    if (minValue) customValidator.pattern = minValue;
    if (maxValue) customValidator.pattern = maxValue;
    if (maxLength) customValidator.pattern = maxLength;
    if (minLength) customValidator.pattern = minLength;
    return customValidator;
  }

  React.useEffect(() => {
    if (getValues && getValues(name) === "" && !!value) {
      setValue(name, value);
    }
  });

  React.useEffect(() => {
    if (getValues(name) !== value) {
      setValue(name, value)
    }
  }, [value]);

  const classList = `form-input text-base rounded-md py-0.5r px-1r border-px border-gray-300 focus:border-indigo-300 outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md ${className}`;

  return (
    <div className={containerClassName}>
      <input
        className={classList}
        {...register(name, {
          ...validationSchema,
          pattern: new RegExp(validationSchema.pattern),
          required: required,
          ...getCustomValidator()
        })}
        type={type}
        onChange={event => {
          callback(event.target.value);
          const pattern = new RegExp(validationSchema.pattern);
          onChange(event);
        }}
        {...rest}
        id={id}
        list={"list" + id}
        defaultValue={value}
        placeholder={placeholder} />

      {suggestions && <datalist id={"list" + id}>
        {
          suggestions.map((suggestion, index) => <option value={suggestion} key={index} />)
        }
      </datalist>}
    </div>
  );
};
InputText.defaultProps = {
  className: "",
  containerClassName: ""
}
InputText.propTypes = {}
