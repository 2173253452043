import {getFile, service} from "./service";
import {getFormattedValuteDefault} from "../utils/numeral";
import {calculateSummary} from "../views/app-views/bdds/bdds-table";
import {calcWeeksInMonth} from "@/utils/date";
import moment from 'moment'
import {BddsUtils} from "@/utils/bdds";
import {saveResponseAsFile} from "@/utils/saveResponseAsFile";

export const fetchBddsList = () => service.get('/bdds').then(r => r.data )

const fetchItem = (id, type = 'months', month) => service.get(`/bdds/${id}`, { params: { type, month } }).then( r => {
  const { tree, balances, lastSync } = r.data
  const currentYear = new Date(lastSync).getFullYear()

  const prepareBalanceRow = (row, reduceMonthsToYear) => {
    row.type = 'balance'
    if (row.days) {

      for (let dayNumber in row.days) {
        for (let j in BddsUtils.innerKeys) {
          const innerKey = BddsUtils.innerKeys[j]
          const value = row.days[dayNumber][innerKey]
          row.days[dayNumber][`${innerKey}-formatted`] = getFormattedValuteDefault(value)
        }
      }

      row.month = reduceMonthsToYear(row.days)
    } else {
      for (let i = 1; i < 13; i++) {
        for (let j in BddsUtils.innerKeys) {
          const innerKey = BddsUtils.innerKeys[j]
          const value = row.months[i][innerKey]
          const hasValue = value !== null && value !== undefined
          row.months[i][`${innerKey}-formatted`] = getFormattedValuteDefault(value)
        }
        if (row.months[i].weeks) {
          for (let weekNumber in row.months[i].weeks) {
            row.months[i].weeks[weekNumber]['actual-formatted'] = getFormattedValuteDefault(row.months[i].weeks[weekNumber].actual)
          }
        }
      }
      row.year = reduceMonthsToYear(row.months)
    }
      row.level = 1
    return row
  }

  const balancesPrepared = [
    prepareBalanceRow(balances.start, (columns, isDays) => {
      if (isDays) {
        let v = null
        Object.entries(columns).forEach( ([col,day]) => {
          if (v !== null) return
          if (day.actual !== null) {
            v = day
          }
        })

        return v
      } else {
        // берем первый непустой месяц
        let counter = 1
        while (counter < 13 && columns[counter].actual == null) {
          counter++
        }
        return columns[counter] || columns[1]
      }
    }),
    prepareBalanceRow(balances.end, (columns, isDays) => {
      if (!isDays) return columns[12]
      else {
        let cols = Object.keys(columns)
        let lastCol = cols[cols.length - 1]
        return columns[lastCol]
      }
    }),
  ]

  balancesPrepared[0].codeString = 'start'
  balancesPrepared[1].codeString = 'end'

  const treePrepared = BddsUtils.prepareBddsItems(tree, currentYear);
  return {
    items: [...treePrepared, ...balancesPrepared],
    lastSync
  }
} )

const updatePlans = (bddsId, { codeId, month, value, date, comment }) => service.put(`/bdds/${bddsId}/plans`, { codeId, month, value, date, comment })

const fetchRoi = () => service.get('/bdds/roi')

export const BddsService = {
  fetchActualData: () => service.get(`/bdds/actual-data`).then( r => r.data ),
  fetchRoi,
  fetchStatistic: (fetchParams) => service.get(`/stock/balance-statistic`, { params: fetchParams }).then(r => r.data),
  fetchGasSchedule: ({ minDate, maxDate }) => service.get(`/bdds/gas-purchase-schedule`,
    { params: { minDate, maxDate }}).then(r => r.data),
  fetchList: fetchBddsList,
  fetchRawBddsItem: (id, type = 'months') => service.get(`/bdds/${id}`, { params: { type } }).then( r => r.data ),
  fetchItem,
  fetchBdrItem: (year, month) => service.get(`/bdr/summary/`, { params: { year, month } }).then( r => r.data ),
  fetchBdrExcel: (year, month) => getFile(`/bdr/summary/`, { year, month, download: 1 }),
  fetchBdrStatistic: () => service.get('/bdr/statistic').then( r => r.data ),
  // only for donugli
  updateBdrPlans: (year, month, codeId, { sum, quantity, comment, note }) => service
    .put(`/bdr/${codeId}/plans/${year}/${month}`, { sum, quantity, comment, note })
    .then(r => r.data ),
  fetchBddsLastSync: (bddsId) => service.get(`/bdds/${bddsId}`).then( r => {
    return r.data.lastSync
  }),
  downloadExcel: (bddsId) => getFile(`/bdds/${bddsId}`),
  fetchBddsStatistic: ({ minDate, maxDate }) => service.get(`bdds/1/balance-statistic`, { params: { minDate, maxDate }}).then( r => r.data ),
  updatePlans
}
