import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from '@/components/shared-components/Loading';
import { useHistory, Link } from "react-router-dom";

export const AppViews = ({match}) => {


  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${match.url}/invite`} component={(props) => {
          const { email, activationCode } = props.location.state
          const Invite = lazy(() => import(`./invite`))
          return <Invite email={email} activationCode={activationCode}/>
        }} />
        <Route path={`${match.url}/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${match.url}/reset-password`} component={(props) => {
          const { email, activationCode } = props.location.state
          const ResetPassword = lazy(() => import(`./authentication/reset-password`))
          return <ResetPassword {...props} email={email} activationCode={activationCode}/>
        }} />
        <Route path={`${match.url}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Redirect from={`${match.url}`} to={`${match.url}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

