import React, {useCallback, useEffect, useState} from 'react'
import { LoadingOutlined, PlusOutlined, De } from '@ant-design/icons';
import {Upload, message} from 'antd'
import {UserApi} from "@/api/users";

function getBase64(img) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.readAsDataURL(img);
  })
}

export const AvatarUploader = props => {
  const { uploadMethod, removeMethod, value } = props
  const [loading,updateLoading] = useState(false)
  const [imageUrl, updateImageUrl] = useState(null)

  useEffect(() => {
    updateImageUrl(value)
  }, [value])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </div>
  );

  const beforeUpload = async (file) => {
    console.log('handleChange', file)
    if (file) {
      uploadMethod(file).then( imgUrl => {
        updateLoading(false)
        updateImageUrl(imgUrl)
      }).catch(e => {
        console.error(e)
        message.error(e)
      })
      return false
    } else {
      updateImageUrl(null)
      return false
    }
  };

  /*const image = <div className={"ant-upload-list-item ant-upload-list-item-list-type-picture-card"}>
    <div className={"ant-upload-list-item-info"}>
    <span>
      <a href="#" className={"ant-upload-list-item-thumbnail"}>
        <img src={imageUrl} className={"ant-upload-list-item-image"}/>
      </a>
    </span>
    </div>
    <span className={"ant-upload-list-item-actions"}>
      <button className={"ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"}>

      </button>
    </span>
  </div>*/

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={async (xhr) => {
        try {
          const file = xhr.file
          const result = await uploadMethod(file)
          console.log('customRequest result:', result)
          xhr.onSuccess(null, file)
        } catch (e) {
          console.error(e)
          xhr.onError(e)
        }
      }}
      onRemove={removeMethod}
    >
      {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
    </Upload>
  );


}
