import {service} from "@/api/service";
import axios from "axios";

const API_HOST = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_HOST_API}`;

const getHeaders = () => ({
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})

//Users
const getUsers = (filters, page, sort) => service.get(`/users`, {
    headers: getHeaders(),
    params: {
        ...filters,
        ...sort,
        page,
    },
    baseURL: API_HOST
})
    .then(r => r.data);

const getUser = (id) => service.get(`/users/${id}`, {
    headers: getHeaders(),
    baseURL: API_HOST
})
    .then(r => r.data);

const editUser = (id, data) => service.patch(`/users/${id}`,
    data,
    {
        headers: getHeaders(),
        baseURL: API_HOST
    })
    .then(r => r.data);

const addUser = (data) => service.post(`/users`, data, {
    headers: getHeaders(),
    baseURL: API_HOST
})
    .then(r => r.data);

const deleteUser = (id) => service.delete(`/users/${id}`, {
    headers: getHeaders(),
    baseURL: API_HOST
})
    .then(r => r.data);

//User's History
const getUsersHistory = (filters, page, sort) => service.get(`/user_history`, {
    headers: getHeaders(),
    params: {
        ...filters,
        ...sort,
        page
    },
    baseURL: API_HOST
})
    .then(r => r.data);

export const UsersAPI = {
    getUsers,
    getUser,
    editUser,
    addUser,
    deleteUser,
    getUsersHistory,
};
