import {
  ADD_COUNTERPARTIES,
  DELETE_COUNTERPARTIES,
  EDIT_COUNTERPARTIES,
  FETCH_ADD_COUNTERPARTIES,
  FETCH_DELETE_COUNTERPARTIES,
  FETCH_EDIT_COUNTERPARTIES,
  FETCH_GET_COUNTERPARTIES,
  FETCH_GET_COUNTERPARTY,
  FETCH_GET_COUNTERPARTY_REPORT,
  FETCH_GET_COUNTERPARTY_TYPE_PRODUCT,
  FETCH_GET_SELLING_PRICES,
  GET_COUNTERPARTIES,
  GET_COUNTERPARTY,
  GET_COUNTERPARTY_REPORT,
  GET_COUNTERPARTY_TYPE_PRODUCT,
  GET_SELLING_PRICES,
  FETCH_COUNTERPARTY_SAILING_MARKS_CHART,
  GET_SAILING_MARKS_CHART,
  FETCH_DEBIT_CREDIT_CHART,
  GET_DEBIT_CREDIT_CHART, SET_COUNTERPARTY_FILTERS,
} from "@/redux/constants/Counterparties";

export const getCounterparty = (counterparty) => {
  return {
    type: GET_COUNTERPARTY,
    payload: counterparty
  }
};

export const setCounterpartyFilters = (filter) => {
  return {
    type: SET_COUNTERPARTY_FILTERS,
    payload: filter
  }
};

export const fetchGetCounterparty = id => {
  return {
    type: FETCH_GET_COUNTERPARTY,
    id,
  }
};

export const getCounterparties = (counterparties) => {
  return {
    type: GET_COUNTERPARTIES,
    payload: counterparties,
  }
};

export const fetchGetCounterparties = ({ filters = {}, page = 1, sort = {} }) => {
  return {
    type: FETCH_GET_COUNTERPARTIES,
    payload: {
      filters,
      page,
      sort
    }
  }
};

export const addCounterparties = (counterparty) => {
  return {
    type: ADD_COUNTERPARTIES,
    payload: counterparty
  }
};

export const fetchAddCounterparties = (counterparty) => {

  return {
    type: FETCH_ADD_COUNTERPARTIES,
    payload: counterparty
  }
};

export const editCounterparties = (counterparty) => {
  return {
    type: EDIT_COUNTERPARTIES,
    payload: counterparty
  }
};

export const fetchEditCounterparties = (counterparty) => {
  return {
    type: FETCH_EDIT_COUNTERPARTIES,
    payload: counterparty
  }
};

export const deleteCounterparties = (id) => {
  return {
    type: DELETE_COUNTERPARTIES,
    payload: id
  }
};

export const fetchDeleteCounterparties = (id) => {
  return {
    type: FETCH_DELETE_COUNTERPARTIES,
    payload: id
  }
};

export const getCounterpartyReport = (counterpartyReport) => {
  return {
    type: GET_COUNTERPARTY_REPORT,
    payload: counterpartyReport
  }
};

export const fetchGetCounterpartyReport = ({ filters = {}, id = "", sort = {} }) => {
  return {
    type: FETCH_GET_COUNTERPARTY_REPORT,
    payload: {
      filters,
      id,
      sort
    }
  }
};

export const getCounterpartyTypeProduct = (counterpartyTypeProduct) => {
  return {
    type: GET_COUNTERPARTY_TYPE_PRODUCT,
    payload: counterpartyTypeProduct
  }
};

export const fetchGetCounterpartyTypeProduct = ({ filters = {}, page = 1, sort = {} }) => {
  return {
    type: FETCH_GET_COUNTERPARTY_TYPE_PRODUCT,
    payload: {
      filters,
      page,
      sort
    }
  }
};

export const getSellingPrices = (sellingPrices) => {
  return {
    type: GET_SELLING_PRICES,
    payload: sellingPrices
  }
};

export const fetchGetSellingPrices = ({ filters = {}, page = 1, sort = {} }) => {
  return {
    type: FETCH_GET_SELLING_PRICES,
    payload: {
      filters,
      page,
      sort
    }
  }
};

export const fetchSailingMarksChart = ({ id, filters }) => {
  return {
    type: FETCH_COUNTERPARTY_SAILING_MARKS_CHART,
    filters,
    id,
  }
};

export const getCounterpartySailingMarksChart = (data) => {
  return {
    type: GET_SAILING_MARKS_CHART,
    payload: data,
  }
};

export const fetchDebitCreditChart = ({ id, filters }) => {
  return {
    type: FETCH_DEBIT_CREDIT_CHART,
    filters,
    id,
  }
};

export const getDebitCreditChart = (data) => {
  return {
    type: GET_DEBIT_CREDIT_CHART,
    payload: data,
  }
};
