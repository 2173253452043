import {service} from "@/api/service";

const fetchUserList = ({ search, groups }) => service.get('/users', { params: { search, groups }}).then( r => r.data )
const fetchUserGroupsList = (userId) => service.get(`/users/${userId}/groups`).then( r => r.data )

export const UserApi = {
  fetchList: fetchUserList,
  fetchGroupsList: fetchUserGroupsList,
  user: {
    avatar: {
      upload: (userId, file) => service.post(`/users/${userId}/avatar`, { file }, { headers: { "Content-Type": "multipart/form-data" } })
        .then( r => r.data ),
      delete: (userId) => service.delete(`/users/${userId}/avatar`)
    }
  }
}
