import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonComponent = ({
  link,
  target,
  isFile,
  submit,
  text,
  className,
  icon,
  callback,
  background,
  backgroundIntensity,
  useBackgroundIntensity,
  fontWeight,
  fontColor,
  fontHover,
  onClick
}) => {

  const backgroundColor = `bg-${background ? `${background}${backgroundIntensity && useBackgroundIntensity ? `-${backgroundIntensity}` : ""}` : "primary-green"}`;
  const hoverBackgroundColor = `hover:bg-${background ? `${background}${backgroundIntensity && useBackgroundIntensity ? `-${backgroundIntensity - 100}` : ""}` : "primary-green-hover"}`;

  const classList = `min-w-50 text-${fontColor} font-${fontWeight} border-transparent focus:outline-none text-base tracking-wide  rounded-md transition-colors
    duration-200 hover:text-${fontHover} py-2 cursor-pointer px-4 border-px border-${backgroundColor} hover:border-${hoverBackgroundColor}  ${className} inline-flex items-center ${backgroundColor} ${hoverBackgroundColor}`;

  const getButtonInternals = () => {
    return (
      <>
        {icon && <FontAwesomeIcon className={"text-sm pb-px font-normal text-white mr-2"} icon={icon} />}
        {text}
      </>
    )
  }

  if (link) {
    return (
      <a target={"_blank"} href={link} download={isFile} className={classList}>
        {getButtonInternals()}
      </a>
    );
  } else if (target) {
    return (
      <Link to={target} className={classList}>
        {getButtonInternals()}
      </Link>
    );
  } else if (submit) {
    return (
      <input type={"submit"} className={classList} value={text} />
    );
  } else {
    return (
      <div onClick={callback} className={classList}>
        {getButtonInternals()}
      </div>
    );
  }

};

ButtonComponent.defaultProps = {
  className: "",
  backgroundIntensity: 400,
  useBackgroundIntensity: true,
  fontWeight: "bold",
  fontHover: "white",
  fontColor: "white"
}

ButtonComponent.propTypes = {
  link: PropTypes.string,
  target: PropTypes.string,
  isFile: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  callback: PropTypes.func
}

export const Button = React.memo(ButtonComponent);
