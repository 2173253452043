import React from 'react';
import PropTypes from 'prop-types';
import {SortCell} from "@/components/table-components/TableHead/SortCell/SortCell";

export const TableHead = ({headers, callback, canView, canEdit, canDelete, sortState, setSortState}) => {

    return (
        <thead>
        <tr
            className={"children:px-2.5 children:py-3 children:bg-gray-200 children:text-left children:text-sm children:leading-4 children:font-normal children:text-gray-500 children:tracking-wider"}>
            {headers.map((column, index) => {

                if (column?.filter) {
                    return (
                        <SortCell
                            key={index}

                            sortState={sortState}
                            setSortState={setSortState}
                            column={{...column}}/>
                    )
                }

                return (
                    <th className={`${column?.position}`} key={index}>
                        {column?.name || column}
                    </th>
                )
            })}
            {(canDelete || canEdit || canView) && <th/>}
        </tr>
        </thead>
    );
};

TableHead.propTypes = {
    headers: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)]).isRequired,
    canView: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
    canEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
    canDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
}
