const BREAKPOINTS = {
  0: 'xs',
  576: 'sm',
  768: 'md',
  992: 'lg',
  1200: 'xl'
}

const BREAKPOINTS_PREP_ENTRIES = Object.entries(BREAKPOINTS).map( ([px, key],i,arr) => {
  let query
  if (i === 0) query = `(max-width: ${px}px)`
  else if (i === arr.length - 1) query = `(min-width: ${px}px)`
  else {
    const nextPx = arr[i+1][0]
    query = `(min-width: ${px}px) and (max-width: ${nextPx}px)`
  }

  return [key, query]
})
const matchGrid = (wod) => {
  const getMatchFunction = (key) => (event) => {
    if (event.matches) {
      document.body.classList.add(key);
    } else {
      document.body.classList.remove(key);
    }
  };

  BREAKPOINTS_PREP_ENTRIES.forEach(([key, query]) => {
    const onMatch = getMatchFunction(key);
    const mediaQuery = window.matchMedia(query);
    mediaQuery.addEventListener('change', onMatch);
    // Execute our match function once to set
    // the correct breakpoint for current media
    onMatch(mediaQuery);
  })
}

matchGrid()
