import React from 'react';
import PropTypes from 'prop-types';

export const InputSelect = ({
                                id,
                                name,
                                value,
                                label,
                                placeholder,
                                options,
                                register,
                                errors,
                                type,
                                callback,
                                getValues,
                                setValue,
                                className,
                                required,
                                containerClassName,
                                defaultHint
                            }) => {

    const errorClassList = "border-red-500";

    const [hint, setHint] = React.useState(defaultHint);
    const [actualValue, setActualValue] = React.useState(value);
    const {onChange, ...rest} = register(name);

    const getHint = (value) => {
        if (value) {
            value = options.find(option => option.value === value);
            if (value?.hint !== hint) {
                setHint(value?.hint);
            }
        } else {
            if (hint !== defaultHint) {
                setHint(defaultHint);
            }
        }
    };

    const onChangeSelect = event => {
        getHint(event.target.value);
        setActualValue(event.target.value);
        onChange(event);
    };

    React.useEffect(() => {
        if (getValues && getValues(name) === "" && !!value) {
            setValue(name, value);
        }
    });

    React.useEffect(() => {
        if (isNaN(value) || value === "") {
          setValue(name, "");
        }
    }, [value]);

    const classList = `appearance-none text-base block bg-grey-lighter border-gray-300 focus:border-indigo-300 text-grey-darker focus:ring focus:ring-indigo-200 focus:ring-opacity-50 outline-none border-px rounded-md py-0.5r px-1r ${className}`;

    return (
        <div className={`relative ${containerClassName}`}>

            {/*<label htmlFor={id} className={`absolute top-0 left-0 ${classList}`}>{placeholder}</label>*/}

            <select
                {...register(name, {required, validate: value => required ? value !== placeholder : true})}
                defaultValue={value}
                className={classList}
                name={name}
                id={id}
                {...rest}
                onChange={onChangeSelect}>
                {placeholder && <option selected={getValues(name) === ""} value={""}>{placeholder}</option>}
                {options?.map(option => {
                    const selected = actualValue === option.value || value === option.value;

                    if (selected) {
                        getHint(option.value);
                    }

                    return (
                        <option key={option.value} value={option.value}>
                            {option.text}
                        </option>
                    )
                })}

            </select>
            {hint && <span className="text-xs text-gray-400 ">{hint}</span>}
        </div>
    );
};

InputSelect.defaultProps = {
    className: "",
    value: "",
    containerClassName: "",
    defaultHint: ""
};

InputSelect.propTypes = {};
