import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import './Pagination.scss';

export const Pagination = ({className, ...props}) => {

  return (
    <ReactPaginate
      previousLabel={<span>«</span>}
      nextLabel={<span>»</span>}
      containerClassName={`pagination-container ${className}`}
      {...props}
    />
  );
};

Pagination.propTypes = {};
