import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {InfoLayout} from "@/components/layout-components/InfoLayout/InfoLayout";
import {TitlePage} from "@/components/shared-components/TitlePage/TitlePage";
import {fetchGetUser} from "@/redux/actions/Users";
import {connect} from "react-redux";
import LoaderWrapper from "@/components/util-components/LoaderWrapper/LoaderWrapper";
import {Redirect} from "react-router-dom";
import {withToast} from "@/hoc/withToast";

class User extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchGetUser(+this.props.match.params.id);
    }

    setURL = () => {
        let prevURL = this.props.prevURL;

        if ((prevURL.includes("users") || prevURL.includes("history")) && !prevURL.includes("edit")) {
            return prevURL
        } else {
            return '/users'
        }
    }

    render() {

        const blueprint = {
            data: [
                {
                    "label": "ФИО",
                    "value": ["lastName", "firstName", "patronymic"]
                },
                {
                    "label": "Должность",
                    "value": ["position"]
                },
                {
                    "label": "Роль",
                    "value": ["groupId"],
                },
                {
                    "label": "Email",
                    "value": ["email"]
                },
                {
                    "label": "Телефон",
                    "value": ["phone"]
                },
                {
                    "label": "Доступ",
                    "value": ["access"],
                    "type": "boolean"
                },
                {
                    "label": "Создан",
                    "value": ["created"]
                },
                {
                    "label": "Обновлен",
                    "value": ["updatedAt"]
                },
                {
                    "label": "Последний вход",
                    "value": ["lastVisited"]
                },
            ]
        };

        const {user, errorMessage, isLoaded} = this.props;

        return (
            <div>
                {errorMessage && <Redirect to={{
                    pathname: "/users",
                }}/>}

                <TitlePage title={"Карточка сотрудника"}
                           className={"mr-auto"}/>
                <div className={"py-6 flex flex-column min-w-full sm:px-2.5 lg:px-8"}>

                    <LoaderWrapper condition={isLoaded}>
                        <InfoLayout blueprint={blueprint}
                                    items={user} className={"my-8"}
                                    closeButton={this.setURL()}
                                    editButton={`/users/edit/${this.props.match.params.id}`}
                                    userId={this.props.match.params.id}/>
                    </LoaderWrapper>

                </div>
            </div>
        );
    }
}

User.propTypes = {};

const mapStateToProps = ({users, global}) => (
    {
        user: users.user,
        isLoaded: global.isLoaded,
    }
);

const mapDispatchToProps = (dispatch) => ({
    fetchGetUser: (e) => dispatch(fetchGetUser(e)),
});

export default withToast(connect(mapStateToProps, mapDispatchToProps)(User));
