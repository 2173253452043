import {getCurrentRange} from "@/helpers/HelperDate";

export const getFiltersFromUrl = (keysArray) => {
    const url = new URL(window.location.href);
    const newFilter = {};

    if (window.location.search) {
        for (let key of url.searchParams.keys()) {
            const newKey = key.replace(/([A-Z])/g, ' $1').split(" ");
            const date = newKey[1].toLowerCase() === "start" ? "startDate" : "endDate";
            newFilter[newKey[0]] = {...newFilter[newKey[0]]};
            newFilter[newKey[0]][date] = url.searchParams.get(key);
        }
    } else {
        keysArray.forEach( key => newFilter[key] = getCurrentRange());
    }

    return newFilter
}

export const callbackFilters = (filters, getDataFunction, props) => {
    const { page, sort } = props;
    props.setIsLoadedGlobal(false);
    props.setFilters(filters);
    getDataFunction({ filters, page, sort });
};