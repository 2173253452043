import {IMaskInput} from 'react-imask';
import React, {useRef, useEffect, useMemo, useCallback} from "react";

export const MaskedInput = React.forwardRef((props, ref) => {
  const { value, onChange, onBlur, mask, disabled, size, processValue, prepareValue } = props
  const inputRef = useRef(null);

  const wrapperSizeClass = useMemo(() => {
    if (size === 'small') return 'ant-input-affix-wrapper-sm'
    else return ''
  },[size])

  const inputSizeClass = useMemo(() => {
    if (size === 'small') return 'ant-input-sm'
    else return ''
  },[size])

  useEffect(() => {
    if (inputRef.current) {
      ref.current = inputRef.current
    }
    console.log('MaskedInput', inputRef.current)
  },[inputRef])

  const maskOptions = useMemo(() => {
    const opts = { mask }
    if (processValue && typeof prepareValue === 'function') {
      opts.prepare = function (value, masked) {
        const processed = prepareValue(value, masked)
        console.log('mask:prepare', processed)
        return processed
      }
    }
    if (processValue && typeof processValue === 'function') {
      opts.commit = function (value, masked) {
        const processed = processValue(value, masked)
        console.log('mask:commit', processed)
        return processed
      }
    }
    return opts
  },[mask, processValue])

  const _onChange = useCallback((v, inputMask, inputEvent) => {
    if (processValue && typeof processValue === 'function') {
      onChange(processValue(v, inputMask, inputEvent))
    } else {
      onChange(v, inputMask, inputEvent)
    }
  },[onChange,processValue])

  return <IMaskInput value={value && value.toString() || ''}
                     disabled={disabled}
                     className={`ant-input ${disabled ? 'ant-input-disabled' : ''} ${inputSizeClass}`}
                     inputRef={el => inputRef.current = el}
                     { ...maskOptions }
                     onBlur={onBlur}
                     onAccept={_onChange}
  />
})
