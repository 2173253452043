import React, {useCallback, useEffect, useRef} from "react"
import {useDebouncedFetch} from "@/utils/useDebouncedFetch";
import {Input, AutoComplete, Spin} from "antd";
import {containsCaseInsensetive} from "@/utils/filter";


export const InputAutocomplete = React.forwardRef(({ fetchMethod, searchThreshold, prefetch,
                                                     ...props }, forwardRef) => {

  const { loading, results, onSearch, fetch } = useDebouncedFetch(fetchMethod, searchThreshold)

  const inputRef = useRef(forwardRef)

  useEffect(function disableNativeAutocomplete() {
    inputRef.current.autocomplete = "off"
    console.log('inputRef.current', inputRef.current)
    forwardRef(inputRef)
  },[])

  const _onFocus = useCallback(() => {
    if (prefetch) {
      fetch()
    }
  },[prefetch])

  return <AutoComplete
    autocomplete="off"
    options={results}
    backfill={true}
    ref={inputRef}
    onSearch={onSearch}
    allowClear={true}
    filterOption={containsCaseInsensetive}
    onFocus={_onFocus}
    onClear={e => {
      inputRef.current.focus()
    }}
    notFoundContent={loading ? <Spin size="small"/> : null}
    {...props}
  > <Input autocomplete="off"/>
  </AutoComplete>
})
