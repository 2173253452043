const nopic = require("../assets/nopic.png");
const FILES_ROUTE = 'erp.tata-gas.ru/files'

export class UtilsUser {

  static getUserFullName(userData) {
    if (userData.company) return userData.company.name
    else {
      const { firstName, lastName } = userData
      return `${firstName || ''} ${lastName || ''}`
    }
  }

  static getAvatarPath(path) {
    return path ? ("//" + FILES_ROUTE + "/" + path) : nopic
  }

}
