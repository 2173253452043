import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputText } from "@/components/forms/Inputs/InputText";
import { InputSelect } from "@/components/forms/Inputs/InputSelect";
import { InputCheckbox } from "@/components/forms/Inputs/InputCheckbox";
import { DateRangeInput } from "@/components/forms/Inputs/DateRangeNew/DateRange";
import { InputFile } from "@/components/forms/Inputs/InputFile";
import { TagInput } from "@/components/forms/Inputs/TagInput/TagInput";
import { FormError } from "@/components/forms/Error/FormError";

import validationJSON from "@/content/Form/validationMasks.json";
import {InputMultiSelect} from "@/components/forms/Inputs/InputMultiSelect/InputMultiSelect";

export const Input = ({
  input,
  type,
  className,
  callback,
  getValues,
  containerClassName,
  register,
  errors,
  control,
  setValue,
  external,
  callbackSubmit
}) => {

  const [currentInput, setCurrentInput] = useState(input);

  const onInputChange = value => {
    callback({ ...currentInput, value });
  };

  // TEMPORARY
  const getErrorMessage = () => {
    const validationSchema = validationJSON[currentInput.validator] || validationJSON["default"];
    let message = "Поле обязательно к заполнению";
    const value = getValues(currentInput.name);

    if (type === "text") {
      if (value) {

        const getEnding = (number) => {
          const value = number?.toString();
          const lastChar = value?.substr(value.length - 1);
          return lastChar === "1" ? "а" : "ов";
        }

        const maxLength = currentInput?.maxLength || validationSchema?.maxLength;
        const minLength = currentInput?.minLength || validationSchema?.minLength;

        if (value.length > 0) {
          if (value.length < minLength) {
            message = `Введенное значение не может быть короче ${minLength} символ${getEnding(currentInput.value)}`;
          }
          if (value.length > maxLength) {
            message = `Введенное значение не может быть больше ${maxLength} символ${getEnding(currentInput.value)}`;
          }
        }

        if (!(new RegExp(validationSchema.pattern).test(value))) {
          message = "Введено некорректное значение";
        }

      }
    }
    return message;
  }
  // TEMPORARY

  useEffect(() => {
    if (input.type !== "file") {
      setCurrentInput(input);
    }
  }, [input]);

  const inputTypes = {
    "text": <InputText className={className}
                       register={register}
                       getValues={getValues}
                       setValue={setValue}
                       validationSchema={validationJSON[currentInput.validator || "default"]}
                       containerClassName={containerClassName}
                       callbackSubmit={callbackSubmit}
                       {...currentInput}
                       callback={onInputChange} />,

    "select": <InputSelect className={className}
                           register={register}
                           getValues={getValues}
                           setValue={setValue}
                           containerClassName={containerClassName}
                           {...currentInput}
                           callback={onInputChange} />,

    "multiSelect": <InputMultiSelect className={className}
                           register={register}
                           getValues={getValues}
                           setValue={setValue}
                           containerClassName={containerClassName}
                           {...currentInput}
                           callback={onInputChange} />,

    "dateRange": <DateRangeInput className={className}
                                 register={register}
                                 setValue={setValue}
                                 getValues={getValues}
                                 containerClassName={containerClassName}
                                 callbackSubmit={callbackSubmit}
                                 {...currentInput}
                                 callback={onInputChange} />,

    // "dateRangeDaily": <DateRangeInputDaily className={className} containerClassName={containerClassName} {...currentInput} callback={onInputChange}/>,

    "checkbox": <InputCheckbox className={className}
                               register={register}
                               containerClassName={containerClassName}
                               {...currentInput}
                               callback={onInputChange} />,

    "file": <InputFile className={className}
                       register={register}
                       containerClassName={containerClassName}
                       {...currentInput}
                       callback={onInputChange} />,

    "tags": <TagInput className={className}
                      register={register}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      containerClassName={containerClassName}
                      {...currentInput}
                      callback={onInputChange} />
  };

  return (
    <div className={`relative ${containerClassName}`}>
      {inputTypes[type]}
      <FormError
        active={errors[currentInput?.name]}
        className={className}
        external={external}
        label={currentInput?.label}
        message={currentInput?.errorMessage || currentInput?.required ? getErrorMessage() : "Ошибка"} />
    </div>
  );
};
Input.defaultProps = {
  type: "text",
  containerClassName: "",
  callback: () => {},
  errors: {}
}

Input.propTypes = {};
