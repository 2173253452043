import React from 'react';
import PropTypes from 'prop-types';
import {TableHead} from "@/components/table-components/TableHead/TableHead";
import {TableBody} from "@/components/table-components/TableBody";
import {TableFilters} from "@/components/table-components/Filters/TableFilters";
import LoaderWrapper from "@/components/util-components/LoaderWrapper/LoaderWrapper";
import {Pagination} from "@/components/util-components/Pagintaion/Pagination";

const TableCore = ({
  filters,
  headers,
  updated,
  title,
  rows,
  Component,
  props,
  canView,
  canEdit,
  canDelete,
  className,
  hideCount,
  filtersObj,
  sortState,
  setSortState,
  pagination
}) => {

  const parsedDate = !!updated && updated.toLocaleDateString("ru", {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });

  React.useEffect( () => () => setSortState ? setSortState({}) : null,[]);

  return (
    <React.Fragment>
      <TableFilters className={!updated && "pb-6"}
                    volume={filters?.totalItems || rows.length}
                    hideCount={hideCount}
                    external={false}
                    callback={filters?.callback}
                    filters={filters?.filters}
                    title={title}
                    filtersObj={filtersObj}
                    buttons={filters?.buttons}/>
      <div className={`overflow-x-scroll ${className}`}>
        {updated &&
        <span
          className={"leading-8 italic text-gray-400 text-xs"}>{`Последняя синхронизация ${parsedDate}`}</span>}
        <LoaderWrapper useLoaderGlobal={true}>
        <table className="min-w-full table-auto divide-y divide-gray-200"
               border={0}
               cellPadding={0}
               cellSpacing={0}>

          {headers && <TableHead headers={headers}
                                 sortState={sortState}
                                 setSortState={setSortState}
                                 canView={canView}
                                 canEdit={canEdit}
                                 canDelete={canDelete}/>}

            <TableBody headers={headers}
                       rows={rows}
                       Component={Component}
                       props={{...props, headers}}
                       canView={canView}
                       canEdit={canEdit}
                       canDelete={canDelete}
            />

        </table>
        {pagination && <Pagination className={"mt-4 float-right"} {...pagination}/>}
        </LoaderWrapper>
      </div>
    </React.Fragment>
  );
};

TableCore.defaultProps = {
  updated: new Date(),
}

TableCore.propTypes = {
  headers: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)]),
  rows: PropTypes.arrayOf(PropTypes.object),
  Component: PropTypes.func,
  props: PropTypes.object,
  canView: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  canEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  canDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  className: PropTypes.string
}

export default React.memo(TableCore);
