import React from 'react';
import PropTypes from 'prop-types';
import { TableRowDefault } from "@/components/table-components/Rows/TableRowDefault";

import './TableRowDropdown.scss';

export const TableRowDropdown = ({
  data,
  ParentRow,
  triggerData,
  disableToggle,
  canView,
  canEdit,
  canDelete,
  defaultOpen,
  hideControls,
  headers
}) => {
  const [isShow, setShow] = React.useState(defaultOpen);
  const triggers = [];

  const showCallback = (cell) => {
    cell = cell !== isShow ? cell : "";
    setShow(cell);
  }

  return (
    <>
      {ParentRow
        ? <ParentRow canView={canView}
                     canEdit={canEdit}
                     canDelete={canDelete}
                     className={"parent-row"}
                     callback={showCallback}
                     triggers={triggers}
                     data={data} />
        : <TableRowDefault canView={canView}
                           canEdit={canEdit}
                           canDelete={canDelete}
                           className={"parent-row"}
                           callback={showCallback}
                           triggers={triggers}
                           headers={headers}
                           data={data} />}

      {Object.keys(triggerData).map(item => {

        return data[triggerData[item]?.childKey]?.map(childRow => {

          if (childRow) {
            triggers.push(item);
          }

          if (triggerData[item]?.Component) {
            const ChildRow = triggerData[item].Component;

            return (isShow === item || disableToggle) && <ChildRow className={"child-row bg-gray-500"}
                                                                   canView={false}
                                                                   canEdit={false}
                                                                   canDelete={false}
                                                                   hideControls={hideControls}
                                                                   headers={headers}
                                                                   id={childRow?.id}
                                                                   data={childRow} />
          } else {
            return (isShow === item || disableToggle) && <TableRowDefault className={"child-row bg-gray-500"}
                                                                          canView={false}
                                                                          canEdit={false}
                                                                          canDelete={false}
                                                                          hideControls={hideControls}
                                                                          headers={headers}
                                                                          data={childRow} />
          }
        })
      })}
    </>
  );
};

TableRowDropdown.propTypes = {
  data: PropTypes.object,
  ParentRow: PropTypes.func,
  canView: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  canEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  canDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  defaultOpen: PropTypes.string
}
