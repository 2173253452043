import moment from 'moment'
import { useMemo } from 'react'
import {getDaysArrayBetween} from "@/utils/date";
import {COLORS} from "@/constants/ChartConstant";
import {formatRUBSimple, getFormattedValuteDefault, getFormattedValuteDefaultWithPostfix} from "@/utils/numeral";
import {capitalize} from "@/utils/string";

export const viewTypes = [
  {
    name: 'Месяц',
    value: 'month',
    fetchParams: {
      maxDate: moment(new Date()).format('YYYY-MM-DD'),
      minDate: moment(new Date()).startOf('day').add(-1,'months').format('YYYY-MM-DD')
    }
  },
  {
    name: 'Три месяца',
    value: 'three-months',
    fetchParams: {
      maxDate: moment(new Date()).format('YYYY-MM-DD'),
      minDate: moment(new Date()).startOf('day').add(-3,'months').format('YYYY-MM-DD')
    }
  },
  {
    name: 'Год',
    value: 'year',
    fetchParams: {
      maxDate: moment(new Date()).format('YYYY-MM-DD'),
      minDate: moment(new Date()).startOf('day').add(-1,'years').format('YYYY-MM-DD')
    }
  },
  /*{
    name: 'Период',
    value: 'custom',
    fetchParams: null
  },*/
]

export const gasPriceReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_VIEW': {
      return {
        isLoading: true,
        data: state.data,
        period: action.payload.period
      }
    }
    case 'SUCCESS': {
      return {
        isLoading: false,
        data: action.payload.data,
        period: state.period
      }
    }
    case 'ERROR': {
      return {
        isLoading: false,
        data: {},
        period: state.period
      }
    }
  }
}

export const useChartData = ({ yAxisInterval, data, period, seriesToShow = ['eoid', 'spimex', 'stock'] }) => {

  const dates = useMemo(() => {
    const [ minDate, maxDate ] = period
    return getDaysArrayBetween(minDate, maxDate)
  },[data, period])

  const seriesInit = ([
    {
      connectNulls: true,
      smooth: false,
      smoothMonotone: "x",
      name: 'eoil',
      id: 'eoil',
      type: 'line',
      showSymbol: null,
      areaStyle: {
        opacity: 0,
      },
      itemStyle: {
        color: COLORS[6]
      },
      data: [],
      yAxisIndex: 0,
      z: 2
    },{
      connectNulls: true,
      smooth: false,
      smoothMonotone: "x",
      name: 'spimex',
      id: 'spimex',
      type: 'line',
      showSymbol: null,
      areaStyle: {
        opacity: 0,
      },
      itemStyle: {
        color: COLORS[0]
      },
      data: [],
      z: 3
    },{
      connectNulls: true,
      smooth: false,
      smoothMonotone: "x",
      name: 'spimex2',
      id: 'spimex2',
      type: 'line',
      showSymbol: null,
      areaStyle: {
        opacity: 0,
      },
      itemStyle: {
        color: COLORS[3]
      },
      data: [],
      z: 3
    },{
      connectNulls: true,
      smooth: true,
      smoothMonotone: "x",
      name: 'ТАТА-ГАЗ',
      id: 'stock',
      type: 'line',
      areaStyle: {
        opacity: 0,
      },
      itemStyle: {
        // opacity: 0.4,
        color: COLORS[2]
      },
      lineStyle: {
        width: 4
      },
      data: [],
      z: 1
    },{
      connectNulls: true,
      smooth: true,
      smoothMonotone: "x",
      name: 'Отгружено',
      id: 'sold-tonnage',
      type: 'bar',
      itemStyle: {
        opacity: 0.4,
        color: COLORS[6]
      },
      yAxisIndex: 1,
      data: [],
      z: 1
    },
  ]).filter( series => seriesToShow.includes(series.id) )

  return {
    tooltip: {
      trigger: 'axis',
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      backgroundColor: '#FFF',
      textStyle: {
        color: '#000'
      },
      formatter: function(params) {
        const dateFormatted = moment(params[0].axisValue).format('DD.MM.YYYY')

        const dateString = `
          <span>${dateFormatted}</span>
          <br/>`

        return params.reduce( (acc, param) => {
          acc += `<span>${param.marker}${capitalize(param.seriesName)} : ${param.value !== null && param.value !== undefined 
            ? param.seriesId === 'sold-tonnage' ? getFormattedValuteDefaultWithPostfix(param.value, 'TONNAGE') :  formatRUBSimple(param.value)
            : '-'
          }</span><br/>`
          return acc
        }, dateString)
      },
    },
    grid: {
      show: false,
      left: 0,
      right: 0,
      bottom: '3%',
      top: '10px',
      containLabel: false
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          lineStyle: {
            opacity: 0,
            width: 2
          }
        },
        axisLabel: {
          inside: true,
          color: '#c1c1c1',
          show: true,
          interval: (i, value) => dates[i].formatted.endsWith('15') ? 15 : false,
          formatter: (value, i) => dates[i].moment.format('MMM YY')
        },
        axisTick: {
          show: true,
          interval: (i, value) => {
            const m = dates[i].moment
            const isStartOfMonth = m.date() === 1
            return isStartOfMonth
              ? m.daysInMonth()
              : false
          },
          lineStyle: {
            opacity: 0.3,
            width: 2
          }
        },
        minorTick: {
          lineStyle: {
            opacity: 0.3,
            width: 2
          }
        },
        boundaryGap: true,
        data: dates.map( date => date.formatted ),
      }
    ],
    yAxis: [
      {
        type: 'value',
        min: function (value) {
          let values = [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100].map( v => v * 1000 )
          let len = values.length
          let i = 0;
          let min = null
          while (min === null && i < len ) {
            if ((value.min >= values[i] && value.min < values[i+1])) {
              min = values[i]
            }
            i++
          }
          if (min === null) {
            min = 5
          }
          return min;
        },
        max: function (value) {
          let values = [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100].map( v => v * 1000 )
          let len = values.length
          let i = 0;
          let max = null
          while (max === null && i < len ) {
            if ((value.max > values[i] && value.max <= values[i+1])) {
              max = values[i+1]
            }
            i++
          }
          if (max === null) {
            max = 100 * 1000
          }
          return max;
        },
        interval: yAxisInterval,
        axisLabel: {
          inside: true,
          formatter(value) {
            return formatRUBSimple(value, '')
          }
        },
        axisLine: {
          lineStyle: {
            opacity: 0,
            width: 2
          }
        },
        axisTick: {
          lineStyle: {
            opacity: 0.3,
            width: 2
          }
        },
        minorTick: {
          lineStyle: {
            opacity: 0.3,
            width: 2
          }
        },
        show: true,
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.5,
            width: 1,
            type: 'dashed'
          }
        }
      },{
        type: 'value',
        position: 'right',
        axisLabel: {
          inside: true,
          formatter(value) {
            return value + 'т.'
          }
        },
        axisLine: {
          lineStyle: {
            opacity: 0,
            width: 2
          }
        },
        axisTick: {
          lineStyle: {
            opacity: 0.3,
            width: 2
          }
        },
        minorTick: {
          lineStyle: {
            opacity: 0.3,
            width: 2
          }
        },
        show: true,
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.5,
            width: 1,
            type: 'dashed'
          }
        }
      },
    ],
    series: dates.reduce((acc, { formatted }) => {
      const item = data[formatted]
      if (item) {
        acc.forEach( s => {
          const value = item[s.id] && (item[s.id].price || item[s.id].tonnage)  || null
          s.data.push(value)
        })
      } else {
        acc.forEach( s => s.data.push(null) )
      }
      return acc
    },seriesInit)
  };
}
