import {
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  GET_USER,
  GET_USERS,
  GET_USERS_HISTORY
} from "@/redux/constants/Users";
import {SET_ERROR_MESSAGES, SET_SUCCESS} from "@/redux/constants/global";


const initState = {
  users: {
    items: []
  },
  usersHistory: [],
  isLoaded: false
};

const Counterparties = (state = initState, action) => {
  switch (action.type) {
    case SET_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload
      }
    case SET_ERROR_MESSAGES:
      return {
        ...state,
        errorMessage: action.payload
      }
    case GET_USERS:
      return {
        ...state,
        users: {...action.payload, isLoaded: true},
      }
    case GET_USERS_HISTORY:
      return {
        ...state,
        usersHistory: {...action.payload, isLoaded: true},
        isLoaded: true
      }
    case GET_USER:
      return {
        ...state,
        user: action.payload
      }
    case ADD_USER:
      return {
        ...state,
        users: {...state.users, items: [...state.users.items, action.payload]}
      }
    case EDIT_USER:
      return {
        ...state,
        user: action.payload
      }
    case DELETE_USER:
      return {
        ...state,
        users: {...state.users, items: state.users.items.filter(item => item.id !== action.payload)}
      }
    default:
      return state;
  }
};

export default Counterparties;
