import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import getScrollBarSize from "rc-util/es/getScrollBarSize";
import moment from 'moment'
import "@/utils/grid-responsive.js"
import {RegistryPaymentsService} from "@/api/registry-payments";

  let w = getScrollBarSize()
  document.body.style.setProperty('--scroll-size', w !== null && w !== undefined ? `${w}px` : 0)
  window.moment = moment

  ReactDOM.render(<App />, document.getElementById('root'));
