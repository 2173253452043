import { service } from "@/api/service";
import moment from 'moment';
import { saveResponseAsFile } from "@/utils/saveResponseAsFile";

const API_HOST = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_HOST_API}`;

export const saveTableAsFile = (url, filters, filename = url, extension = "xlsx") => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    },
    baseURL: API_HOST,
    params: { ...filters },
    responseType: "arraybuffer"
  }

  service.get(url, config).then(data => {
    saveResponseAsFile(data, () => `${filename} ${moment().format('DD-MM-YYYY HH:MM:SS')}.${extension}`);
  });
}
