import {IMaskInput} from 'react-imask';
import React, {useCallback, useEffect, useMemo, useRef} from "react";

export const PhoneInput = React.forwardRef(function PhoneInput(props, forwardRef) {
  const { value, onChange, onBlur, disabled, size, onFocus } = props
  const inputRef = useRef(null);

  const inputSizeClass = useMemo(() => {
    if (size === 'small') return 'ant-input-sm'
    else return ''
  },[size])

  const _prepare = useCallback((v, ...rest) => {
    console.log('_prepare', v, rest)
    return v
  },[])

  const _onChange = useCallback((v, inputMask, inputEvent) => {
    if (!inputEvent) return null
    console.log('_onChange', v)
    const vPrepared = +('8' + (v.substr(2).replace(/\D/g,'')))
    console.log('vPrepared', vPrepared)

    const target = inputEvent ? { ...inputEvent.target } : {}
    target.value = Number.isNaN(vPrepared) ? null : vPrepared
    const eventPrepared = { ...(inputEvent || null), target }
    onChange(eventPrepared)
  }, [onChange])

  const _valuePrepared = useMemo(() => {
    if (!value) return null
    const vStr = value.toString()
    if (vStr.startsWith('8') || vStr.startsWith('7')) return vStr.substr(1)
    else return vStr
  },[value])

  const onSetRefInput = useCallback((el) => {
    /*const ref = {
      target: {

      }
    }*/
    inputRef.current = el
    const ref = {
        focus: () => {
          inputRef.current.focus()
        },
        blur: () => {
          inputRef.current.blur()
        }
    }
    forwardRef && forwardRef(el)
  },[])

  return <IMaskInput value={_valuePrepared}
                     disabled={disabled}
                     className={`ant-input ${disabled ? 'ant-input-disabled' : ''} ${inputSizeClass}`}
                     inputRef={onSetRefInput}
                     mask={'+{7}(000)000-00-00'}
                     onBlur={onBlur}
                     onFocus={onFocus}
                     onAccept={_onChange}
  />
})
