import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  FETCH_ADD_USER,
  FETCH_DELETE_USER,
  FETCH_EDIT_USER,
  FETCH_GET_USER,
  FETCH_GET_USERS,
  FETCH_GET_USERS_HISTORY
} from "@/redux/constants/Users";
import {
  addUser,
  deleteUser,
  editUser,
  getUser,
  getUsers,
  getUsersHistory,
} from "@/redux/actions/Users";
import { UsersAPI } from "@/api/usersAPI";
import {setErrorMessage, setIsLoadedGlobal, setSort, setSuccess} from "@/redux/actions/global";

//Users
export function* fetchGetUsers() {
  yield takeLatest(FETCH_GET_USERS, function* ({ payload }) {
    try {
      const response = yield call(() => UsersAPI.getUsers(payload.filters, payload.page, payload.sort));
      yield put(getUsers(response));
      yield put(setIsLoadedGlobal(true));
      yield put(setSort(payload.sort));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export function* fetchGetUser() {
  yield takeEvery(FETCH_GET_USER, function* ({ payload }) {
    try {
      const response = yield call(() => UsersAPI.getUser(payload));
      yield put(getUser(response));
      yield put(setIsLoadedGlobal(true));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export function* fetchEditUser() {
  yield takeEvery(FETCH_EDIT_USER, function* ({ payload }) {
    try {
      const response = yield call(() => UsersAPI.editUser(payload.id, payload));
      yield put(editUser(response));
      yield put(setSuccess("Пользователь изменен"));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export function* fetchAddUser() {
  yield takeEvery(FETCH_ADD_USER, function* ({ payload }) {

    try {
      const response = yield call(() => UsersAPI.addUser(payload));
      yield put(addUser(response));
      yield put(setSuccess("Пользователь добавлен"));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export function* fetchDeleteUser() {
  yield takeEvery(FETCH_DELETE_USER, function* ({ payload }) {
    try {

      yield call(() => UsersAPI.deleteUser(payload));
      yield put(deleteUser(payload));
      yield put(setSuccess("Пользователь удален"));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

//User's History
export function* fetchGetUsersHistory() {
  yield takeEvery(FETCH_GET_USERS_HISTORY, function* ({ payload }) {
    try {
      const response = yield call(() => UsersAPI.getUsersHistory(payload.filters, payload.page, payload.sort));
      yield put(getUsersHistory(response));
      yield put(setIsLoadedGlobal(true));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchGetUsers),
    fork(fetchGetUser),
    fork(fetchEditUser),
    fork(fetchAddUser),
    fork(fetchDeleteUser),
    fork(fetchGetUsersHistory)
  ]);
}
