import {Badge} from "antd";
import React from "react";

export const CrmOrderStatusMap = {
  'new': 'Новые',
  'on-payment': 'Ожидают оплаты',
  'paid': 'Оплачены',
  'pass-registration': 'Оформление пропуска',
  'on-shipment': 'Ожидают отгрузки',
  'shipped': 'Отгружены',
  'cancelled': 'Отменены',
}

export const CrmOrderStatusFetchParams = {
  'new': {},
  'on-payment': {},
  'paid': {},
  'pass-registration': {},
  'on-shipment': {},
  'shipped': { limit: 15 },
  'cancelled': { limit: 15 },
}

export const CrmOrderStatusColors = {
  'new': 'bg-info',
  'on-payment': 'bg-gray-light',
  'paid': 'bg-success',
  'pass-registration': 'bg-secondary',
  'on-shipment': 'bg-warning',
  'shipped': 'bg-success',
  'cancelled': 'bg-danger',
}

export const CrmOrderStatusColorsComputed = {
  'new': '#0069b4',
  'on-payment': '#72849a',
  'paid': '#04d182',
  'pass-registration': '#D77D1A',
  'on-shipment': '#ffc542',
  'shipped': '#04d182',
  'cancelled': '#ff6b72',
}

export const StatusesWithDetailInfo = [
  ,'new'
  ,'on-payment'
  ,'paid'
  ,'pass-registration'
  ,'on-shipment'
]

export const getRequisitionStatusBadge = (type) => {
  switch (type) {
    case 'new':
      return <><Badge status="processing" /><span>Новый</span></>
    case 'on-payment':
      return <><Badge status="processing" /><span>Ожидает оплаты</span></>
    case 'cancelled':
      return <><Badge status="error" /><span>Отменен</span></>
    case 'paid':
      return <><Badge status={"success"} /><span>Оплачен</span></>
    case 'pass-registration':
      return <><Badge status="warning" /><span>Оформление пропуска</span></>
    case 'on-shipment':
      return <><Badge status="warning" /><span>Ожидает отгрузки</span></>
    case 'shipped':
      return <><Badge status="success" /><span>Отгружен</span></>
    default:
      return null
  }
}

export const getRequisitionStatusBadgeNoText = (type) => {
  switch (type) {
    case 'new':
      return <><Badge status="processing" /></>
    case 'on-payment':
      return <><Badge status="processing" /></>
    case 'cancelled':
      return <><Badge status="error" /></>
    case 'paid':
      return <><Badge status="warning" /></>
    case 'pass-registration':
      return <><Badge status="warning" /></>
    case 'on-shipment':
      return <><Badge status="warning" /></>
    case 'shipped':
      return <><Badge status="success" /></>
    default:
      return null
  }
}

export const getCrmBadgeClassName = (st, highlight) => `crm-order-status-${st} ${highlight ? 'crm-order-status-active' : ''}`
