import { viewTypes } from "@/views/app-views/home/widgets/GasPriceWidget/utils";
import {Radio, Button, Popover, DatePicker } from "antd";
import React, { useRef, useState, useEffect, useMemo } from "react";
import {ViewTypes} from "@/views/app-views/home/widgets/fin-widget/utils";
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/ru_RU';
import {usePrevious} from "@/utils/hooks";
import { CalendarOutlined } from "@ant-design/icons"
import BddsPage from "@/views/app-views/bdds";
import {COLORS} from "@/constants/ChartConstant";

export const PeriodSelector = ({ periods, value, onUpdatePeriod }) => {

  const [ isVisibleDatepicker, setIsVisibleDatepicker ] = useState(false)


  const dateInterval = useMemo(() => {
    const { minDate, maxDate } = value.fetchParams
    return [ moment(minDate), moment(maxDate)]
  },[value])

  const tempDateInterval = useRef(
    dateInterval.map( v => v.clone() )
  )

  const oldDateInterval = usePrevious(dateInterval)

  const rangePickerRef = useRef(null)
  const popoverRef = useRef()


  useEffect(() => {
    if (isVisibleDatepicker) {
      rangePickerRef.current.focus()
    }
  }, [isVisibleDatepicker])

  const onChangePopoverVisibility = isVisible => {
    setIsVisibleDatepicker(isVisible)
    return
    if (isVisible) {
      //rangePickerRef.current.focus()
    }
  }

  const onChangeDefinedPeriod = (periodName) => {
    const view = viewTypes.find( v => v.value === periodName )
    if (view) {
      const { minDate, maxDate } = view.fetchParams
      onUpdatePeriod([minDate,maxDate])
    }
  }

  const onChangeDatePicker = (dates) => {
    tempDateInterval.current = dates.map( d => d.clone() )
  }

  const onOpenChangeDatePicker = (isOpen) => {
    if (!isOpen) {
      const [ oldMin, oldMax ] = dateInterval.map( d => d.format('YYYY-MM-DD'))
      const [ newMin, newMax ] = tempDateInterval.current.map( d => d.format('YYYY-MM-DD'))

      // if dates are not the same
      if (oldMin === newMin && oldMax === newMax) {
        return
      }

      onUpdatePeriod(tempDateInterval.current.map( v => v.format('YYYY-MM-DD')))
    }
  }

  function disabledDate(current) {
    return current && current.format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')
  }

  const datePickerRanges = useMemo(() => {
    return Object.fromEntries(periods.map( ({ fetchParams, name, value }) => {
      return [name, [ moment(new Date(fetchParams.minDate)), moment(new Date(fetchParams.maxDate)) ]]
    }))
  },[])

  const suffixIcon = <CalendarOutlined style={{ color: COLORS[0] }}/>

  const datepicker = <DatePicker.RangePicker
    className={"custom-period-selector"}
    ranges={datePickerRanges} disabledDate={disabledDate} format={'DD.MM.YY'} allowClear={false} inputReadOnly={true} locale={locale} size={"small"} ref={rangePickerRef} value={tempDateInterval.current}
    onChange={onChangeDatePicker}
    onOpenChange={onOpenChangeDatePicker}
    suffixIcon={suffixIcon}
  />

  const buttonTitle = useMemo(() => {
    const { dateMin, dateMax } = value.fetchParams
    return `${moment(dateMin).format('DD.MM.YYYY')} - ${moment(dateMax).format('DD.MM.YYYY')}`
  },[value])

  return datepicker
}
