import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Users from "./Users";
import Counterparties from "./Counterparties";

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Users(),
    Counterparties(),
  ]);
}
