import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@/components/util-components/Button/Button';
import LoaderWrapper from "@/components/util-components/LoaderWrapper/LoaderWrapper";

export const FormLayout = ({
  blueprint,
  items,
  className,
  closeButton,
  resetButton,
  userId,
  reset,
  handleSubmit,
  onSubmit,
  generateChildren
}) => {

  const condition = blueprint.predefinedValues ? items : true;


  return (
    <LoaderWrapper condition={condition}>
      <form onSubmit={handleSubmit(onSubmit)}
            className={`p-4 bg-white w-full  ${className}`}>
        <div className={`grid  md:grid-cols-card gap-x-12 gap-y-5`}>
          {generateChildren()}
        </div>
        <div className={'flex items-center children:mr-1r mt-6'}>
          <Button submit
                  text={(!!userId || userId?.toString() === '0') ? `Сохранить` : `Создать`}
            // background={'green'}
            // backgroundIntensity={500}
                  fontWeight={'normal'}/>

          {closeButton && <Button target={closeButton}
                                  text={'Закрыть'}
                                  background={'white'}
                                  className={'border-px border-gray-400 self-start inline-block'}
                                  fontColor={'gray-800'}
                                  fontWeight={'normal'}
                                  fontHover={'text-gray-800'}
                                  useBackgroundIntensity={false}/>}

          {resetButton && <Button callback={reset}
                                  text={'Отмена'}
                                  background={'white'}
                                  className={'border-px border-gray-400 self-start inline-block'}
                                  fontColor={'gray-800'}
                                  fontWeight={'normal'}
                                  fontHover={'text-gray-800'}
                                  useBackgroundIntensity={false}/>}
        </div>
      </form>
    </LoaderWrapper>
  );

};

FormLayout.defaultProps =
  {
    className: ''
  };

FormLayout.propTypes = {};
