import React, {useReducer, useMemo, useEffect, useCallback} from 'react'
import {gasPriceReducer, useChartData, viewTypes} from "@/views/app-views/home/widgets/GasPriceWidget/utils";
import {EChartWidget} from "@/components/shared-components/EChartWidget"
import { PeriodSelector } from "@/views/app-views/home/widgets/GasPriceWidget/period-selector";
import {showErrorDefault} from "@/utils/hooks";
import {GasService} from "@/api/gas-service";
import PropTypes from 'prop-types'
import {Card, Col, Radio, Row} from "antd";
import {ViewTypes} from "./utils";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {CurrencyStat} from "@/components/shared-components/currency-stat";
import moment from 'moment'
import {COLORS} from "@/constants/ChartConstant";
import {CrmService} from "@/api/crm-orders";

export const GasPriceWidget = ({ yAxisInterval, initialView, sellsTonnageIsVisible, seriesToShow, actualSeriesToShow, allowSelectPeriod, inCard, height }) => {

  const [ state, dispatch ] = useReducer(gasPriceReducer, undefined,() => {
    const initView = viewTypes.find( el => el.value === initialView )
    return { isLoading: true, data: {}, period: [initView.fetchParams.minDate, initView.fetchParams.maxDate] }
  })

  const onUpdatePeriod = useCallback((period) => dispatch({ type: 'UPDATE_VIEW', payload: { period } }),[])

  const currentPeriod = useMemo(() => {
    const [ minDate, maxDate ] = state.period
    const found = viewTypes.find( ({ value, fetchParams }) => fetchParams && fetchParams.minDate === minDate &&
      fetchParams.maxDate === maxDate
    )
    if (!found) {
      const name = `${moment(minDate).format('DD.MM.YYYY')} - ${moment(maxDate).format('DD.MM.YYYY')}`
      return {
        name,
        value: 'custom',
        fetchParams: { minDate, maxDate }
      }
    }
    else return found
  },[state])

  useEffect(() => {
    async function fetch() {
      try {
        const [ minDate, maxDate ] = state.period
        const promises = [GasService.fetchGasPrices({ minDate, maxDate })]
        const soldTonnageIsVisible = seriesToShow.includes('sold-tonnage')
        if (soldTonnageIsVisible) {
          promises.push(CrmService.stat.fetchSoldTonnageStat({ minDate, maxDate }))
        }
         const [ priceData, tonnageData ] = await Promise.all(promises)
        let payloadData
        if (soldTonnageIsVisible) {
          payloadData = Object.fromEntries(
            Object.entries(priceData).map( ([dateString, values]) => ([dateString, {
              ...values,
              'sold-tonnage': { tonnage: tonnageData[dateString] || null}
            }]))
          )
        } else {
          payloadData = priceData
        }
        dispatch({ type: 'SUCCESS', payload: { data: payloadData } })
      } catch (e) {
        showErrorDefault(e)
        dispatch({ type: 'ERROR' })
      }
    }

    fetch()
  },[state.period, seriesToShow])
  //const dates

  const currencyStatData = useMemo(() => {

    const entries = Object.entries(state.data)

    if (entries.length === 0) return null

    let actualEOILPrice,
      prevEOILPrice,
      actualEOILDate,
      actualSPIMEXPrice,
      prevSPIMEXPrice,
      actualSPIMEXDate,
      actualTataPrice,
      prevTataPrice,
      actualTataDate
    let index = entries.length - 1
    while ((!actualEOILPrice && !actualSPIMEXPrice && !prevEOILPrice && !prevSPIMEXPrice && !actualTataPrice && !prevTataPrice) || index >= 0) {
      const [ dateActual, { eoil, spimex, stock }] = entries[index]
      {
        if (eoil && eoil.price && actualEOILPrice === undefined) {
          actualEOILPrice = eoil.price
          actualEOILDate = dateActual
        }
        if (spimex && spimex.price && actualSPIMEXPrice === undefined) {
          actualSPIMEXPrice = spimex.price
          actualSPIMEXDate = dateActual
        }
        if (stock && stock.price && actualTataPrice === undefined) {
          actualTataPrice = stock.price
          actualTataDate = dateActual
        }
      }
      {
        if (index !== 0) {
          const [ datePrev, { eoil, spimex, stock }] = entries[index - 1]
          if (actualEOILPrice && eoil && eoil.price && prevEOILPrice === undefined) {
            prevEOILPrice = eoil.price
          }
          if (actualSPIMEXPrice && spimex && spimex.price && prevSPIMEXPrice === undefined) {
            prevSPIMEXPrice = spimex.price
          }if (actualTataPrice && stock && stock.price && prevTataPrice === undefined) {
            prevTataPrice = stock.price
          }
        }
      }
      index--
    }

    let prices;
    prices = [
      {
        id: 'eoil',
        title: 'EOIL',
        value: actualEOILPrice || 0,
        date: actualEOILDate,
        color: COLORS[6],
        deltaPercent: prevEOILPrice && actualEOILPrice ? Math.floor(((actualEOILPrice - prevEOILPrice) / prevEOILPrice) * 100 * 100) / 100 : null
      },
      {
        id: 'spimex',
        title: 'SPIMEX',
        value: actualSPIMEXPrice || 0,
        date: actualSPIMEXDate,
        color: COLORS[0],
        deltaPercent: prevSPIMEXPrice && actualSPIMEXPrice ? Math.floor(((actualSPIMEXPrice - prevSPIMEXPrice) / prevSPIMEXPrice) * 100 * 100) / 100 : null
      },{
        id: 'stock',
        title: 'ТАТА-ГАЗ',
        value: actualTataPrice || 0,
        date: actualTataDate,
        color: COLORS[2],
        deltaPercent: prevTataPrice && actualTataPrice ? Math.floor(((actualTataPrice - prevTataPrice) / prevTataPrice) * 100 * 100) / 100 : null
      },
    ];

    return prices.filter( price => actualSeriesToShow.includes(price.id) )
  },[state])

  const chartOptions = useChartData({ data: state.data, period: state.period, seriesToShow, yAxisInterval })

  const widgetHeight = useMemo(() => {
    return height || 250
  },[height])

  const widget = (<>
    <Row justify={'space-between'} className={"mb-3"} gutter={1}>
      <Col xl={23} sm={23}>
        { currencyStatData && <Row align={"bottom"}>
          { currencyStatData.map( ({ value, title, deltaPercent, date, color}, i, arr) =>
            <CurrencyStat key={i} className={i !== arr.length - 1 ? "mr-5" : ""} value={value} title={(<>
                  <span>
                    <span className={"title-dot"} style={{ backgroundColor: color, opacity: i === 2 ? 0.7 : 1 }}></span>
                    {title}
                  </span>
              <span className={"text-gray-light"}>{ date && ` на ${moment(date).format('DD.MM.YYYY')}`} </span>
            </>)} percent={deltaPercent}/>
          )}
        </Row>}
      </Col>
      <Col xl={1} sm={1} className={"text-right"}>
        {allowSelectPeriod && <PeriodSelector periods={viewTypes} value={currentPeriod} onUpdatePeriod={onUpdatePeriod}/>}
      </Col>
    </Row>
    <EChartWidget card={false} height={widgetHeight} option={chartOptions}/>
  </>)

  return inCard ? (
    <Card>
      {widget}
    </Card>
  ) : widget
}

GasPriceWidget.propTypes = {
  inCard: PropTypes.bool,
  allowSelectPeriod: PropTypes.bool,
  initialView: PropTypes.string,
  seriesToShow: PropTypes.array,
  actualSeriesToShow: PropTypes.array,
  yAxisInterval: PropTypes.number
}

GasPriceWidget.defaultProps = {
  inCard: true,
  allowSelectPeriod: true,
  initialView: 'three-months',
  actualSeriesToShow: ['eoil','spimex', 'stock', 'sold-tonnage'],
  seriesToShow: ['eoil','spimex', 'spimex2', 'stock', 'sold-tonnage'],
  yAxisInterval: 5000
}
