import {service} from "@/api/service";
import moment from 'moment'

const round = v => Math.floor(v * 100) / 100

const fetchGasPrices = ({ minDate, maxDate }) => service.get(`/stock/price-statistic`, { params: { minDate, maxDate }}).then(
  r => Object.fromEntries(
    Object.entries(r.data)
      .map( ([date, values]) =>
        ([
          date,
          { ...values, ...(values.spimex ? { spimex2: { price: values.spimex.price && (values.spimex.price + 9050) || null } } : null)}
        ])
      )
  )
)

export const GasService = {
  fetchGasPrices,
  fetchStockActualPrice: () => fetchGasPrices({
    minDate: moment(new Date()).add(-1,"days").format('YYYY-MM-DD'),
    maxDate: moment(new Date()).format('YYYY-MM-DD')
  }).then( gasPriceDateValuesMap => {
    const values = Object.values(gasPriceDateValuesMap)
    if (!values.length) return null
    const value = values[values.length - 1]
    return value['eoil'] && value['eoil'].price || null
  }),
  fetchMaxGasVolume: () => service.get(`/settings`).then(r => r.data['totalVolume'] )
}
