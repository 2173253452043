import { createClient } from '@gagydzer/my-axios-service'
import axios from "axios";
//import store from "@/store";
import {getAuth, removeAuth, setAuth} from "../utils/auth";
import CustomError from '../utils/error'
import {saveResponseAsFile} from '../utils/saveResponseAsFile'
import store from '../redux/store';
import {authUpdate, signOutSuccess} from "../redux/actions/Auth";

const API_HOST = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_HOST_API}`

export const noAuthService = axios.create({
  baseURL: API_HOST
})

export const service = createClient({
  setAuthData: data => {
    setAuth(data, window.localStorage)
    const authData = getAuth(window.localStorage)
    console.log('client:setAuthData', authData)
    store.dispatch(authUpdate(authData))
  },
  getAuthData: () => getAuth(window.localStorage),
  onFailAuth: () => {
    removeAuth()
    store.dispatch(signOutSuccess())
  },
  errorClass: CustomError,
  axios: axios.create({
    baseURL: API_HOST
  })
})

export const getFile = (url, params = {}, method = 'get') => service(url, { method, params: { download: 1, ...params  }, responseType: "arraybuffer" })
  .then(saveResponseAsFile)
