import React, { useMemo } from 'react'
import { OrderBlockTitle } from "./order-block-title"
import { Button, Col, DatePicker, Form, Image, Row, Space, Typography } from "antd";
import { useDocumentsDownload } from "@/views/app-views/crm/requisitions/order-form/hooks";
import { PaperClipOutlined } from "@ant-design/icons";
import { cutSpace } from "@/views/app-views/crm/requisitions/order-form/utils";

const Text = Typography.Text

export const OrderPassRegistrationCompletedBlock = ({ order }) => {

	const docPass = useDocumentsDownload(order, 'pass-documents')
	const docRequisition = useDocumentsDownload(order, 'requisition-documents')

	const { passCarNumber, passTrailerNumber } = useMemo(() => {
		return {
			passCarNumber: cutSpace(order.passCarNumber),
			passTrailerNumber: cutSpace(order.passTrailerNumber)
		}
	}, [order])

	return <div className={"mb-4"}>
		<OrderBlockTitle status={'pass-registration'}
		                 title={"оформление пропуска"}
		                 dateTime={order.passRegistrationStarted}
		                 highlight={order.status === 'pass-registration'}/>

		<div className={"p-3"} style={{ backgroundColor: 'white', borderRadius: '0.625rem' }}>
			<Row gutter={16}>
				<Col sm={6}>
					<h5>
						Документы
					</h5>
					<Space direction={"vertical"}>

						<Button size={"small"} block className={"download-order-button"}
						        icon={<PaperClipOutlined/>}
						        type="link"
						        onClick={docRequisition.download}
						        loading={docRequisition.downloading}
						        title={"Приложение и счет"}>
							Заявка.pdf
						</Button>

						{!order || order.status !== 'pass-registration' &&
						<Button size={"small"} block className={"download-order-button"}
						        loading={docPass.downloading}
						        icon={<PaperClipOutlined/>} type="link"
						        onClick={docPass.download}
						        title={"Приложение и счет"}>
							Пропуск.pdf
						</Button>}

					</Space>
				</Col>
				<Col sm={9}>
					<h5>Автомобиль</h5>
					<Space direction={"vertical"}>
						<Text>
							{order.passCarModel}
						</Text>
						<Space direction={"horizontal"}>
							<Image width={'100%'} src={`https://club2108.ru/numgen/index.php/?num=${passCarNumber}`}/>
							<Image width={'100%'}
							       src={`https://club2108.ru/numgen/index.php/?num=${passTrailerNumber}`}/>
						</Space>
					</Space>
				</Col>
				<Col sm={9}>
					<Form.Item
						label="Дата отгрузки (план)"
						name="shipmentPlanDateTime"
						rules={[{ required: true, message: 'Обязательно' }]}
					>
						<DatePicker size={"small"}
						            style={{ width: '100%' }}
						            format="DD.MM.YYYY HH:00"
						            disabled={order && order.status !== 'pass-registration'}
						            showTime={{
							            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 18, 19, 20, 21, 22, 23],
							            hideDisabledOptions: true
						            }}
						/>
					</Form.Item>
				</Col>
			</Row>
		</div>
	</div>
}
