import React from 'react';
import PropTypes from 'prop-types';
import { TextBubble } from "@/components/util-components/TextBubble/TextBubble";
import { IconCell } from "@/components/table-components/Cell/IconCell/IconCell";

export const TableRowDefault = ({
  id,
  data,
  className,
  canView,
  canEdit,
  canDelete,
  triggers,
  callback,
  headers,
  ...props
}) => {

  const triggerClick = (cell, index) => {
    if (triggers && cell === triggers[index]) {
      callback(cell);
    }
  };

  const setNewData = (dataObj) => {
    let newData = {};
    const dataKeys = Object.keys(dataObj);
    headers.forEach(item => {
      if (dataKeys.includes(item.value)) {
        newData[item.value] = dataObj[item.value];
      }
    })
    return newData;
  }

  id = id || data["id"];

  return (
    <tr {...props} className={`children:px-2.5 children:py-3.5 bg-white-000 ${className}`}>
      {Object.keys(setNewData(data)).map((cell, index) => {

        let isInt = parseFloat(data[cell]) || parseInt(data[cell]) ? true : false;

        if (typeof setNewData(data)[cell] !== "string") {
          setNewData(data)[cell] = <TextBubble bool={setNewData(data)[cell]} />
        }

        return (
          <td onClick={() => triggerClick(cell, index)}
              className={`text-base leading-5 text-gray-900 ${triggers && cell === triggers[index] && +id !== 0 ? "underline cursor-pointer" : ""} ${+id === 0 ? "font-bold" : ""} ${isInt ? "text-nowrap" : ""}`}
              key={index}>{data[cell]}</td>
        )
      })}

      {
        (canDelete || canEdit || canView) && <IconCell id={id}
                                                       canDelete={canDelete}
                                                       canEdit={canEdit}
                                                       canView={canView} />
      }
    </tr>
  );
};

TableRowDefault.defaultProps = {
  className: "",
  isEmptyTD: false,
}

TableRowDefault.propTypes = {
  data: PropTypes.object.isRequired,
  canView: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  canEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  canDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  isEmptyTD: PropTypes.bool,
}
