import React from 'react';
import PropTypes from 'prop-types';
import Loading from "@/components/shared-components/Loading";
import {connect} from "react-redux";
import {setIsLoadedGlobal} from "@/redux/actions/global";

class LoaderWrapper extends React.Component {

  componentWillUnmount() {
    this.props.setIsLoadedGlobal(false);
  }

  render() {
    let {condition, children, useLoaderGlobal, isLoaded} = this.props;

    if (useLoaderGlobal) {
      condition = isLoaded;
    }

    return (
      <>
        {!condition
          ? <Loading cover={"content"}/>
          : children}
      </>
    );
  }
}

LoaderWrapper.propTypes = {};

const mapStateToProps = ({global}) => (
  {
    isLoaded: global.isLoaded,
  });

const mapDispatchToProps = (dispatch) => ({
  setIsLoadedGlobal: (e) => dispatch(setIsLoadedGlobal(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoaderWrapper);