import moment from "moment";

/**
 * User: denisverstov
 * Date: 21.04.2021
 * Time: 05:44
 */

/**
 *
 * @returns {{endDate: string, startDate: string}}
 */

const getCurrentRange = () => {
  return {
    startDate: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
    endDate: moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
  }
};

/**
 *
 * @param filters
 * @returns {{}}
 */

const getRangeFilters = (filters = {}) => {
  let currentDate = getCurrentRange();
  if (!filters.startDate && !filters.endDate) {
    filters.date = "";
    filters.startDate = currentDate.startDate;
    filters.endDate = currentDate.endDate;
  }

  return filters;
};


export {
  getCurrentRange,
  getRangeFilters
}
