import { Base64 } from "js-base64";

export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const EXPIRES = "expires_in";
export const TOKEN_FIELDS = ["sub", "role", "groups"];

export function getAuth(storage, root = false) {
  const getFieldKey = key => (root ? `root_${key}` : key);
  const getFinalFieldKey = key => (root ? key.replace("root_", "") : key);
  let access = storage.getItem(getFieldKey(ACCESS_TOKEN));
  let refresh = storage.getItem(getFieldKey(REFRESH_TOKEN));
  let expires = storage.getItem(getFieldKey(EXPIRES));

  if (
    !refresh ||
    !access ||
    refresh === "undefined" ||
    access === "undefined"
  ) {
    return false;
  }

  return Object.entries({
    [getFieldKey(ACCESS_TOKEN)]: access,
    [getFieldKey(REFRESH_TOKEN)]: refresh,
    [getFieldKey(EXPIRES)]: expires,
    ...Object.entries(
      fieldsAdaptor(
        getFieldsFromParsed(parseToken(access), TOKEN_FIELDS) || {},
        {
          userId: "sub",
          role: "Role"
        }
      )
    ).reduce((acc, [k, v]) => ({ ...acc, [getFieldKey(k)]: v }), {})
  }).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(value && value !== "undefined" && value !== "null"
        ? { [getFinalFieldKey(key)]: value }
        : {})
    }),
    {}
  );
}

function fieldsAdaptor(obj, map) {
  return Object.entries(map).reduce(
    (acc, [newKey, oldKey]) => ({
      ...acc,
      ...(obj[oldKey] ? { [newKey]: obj[oldKey] } : {})
    }),
    obj
  );
}

export const getRootAuth = storage => getAuth(storage, true);

export const setRootAuth = (authData, storage) =>
  setAuth(
    Object.entries(authData).reduce(
      (acc, [k, v]) => ({
        ...acc,
        ["root_" + k]: v
      }),
      {}
    ),
    storage
  );

export function setAuth(authData, storage) {
  if (!authData) return false;

  /* if (authData[ACCESS_TOKEN]) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + authData[ACCESS_TOKEN];
  } else {
    delete axios.defaults.headers.common["Authorization"];
  } */

  return Object.entries(authData).map(([key, value]) =>
    storage.setItem(key, value)
  );
}

export function removeAuth(root) {
  const getFieldKey = key => (root ? `root_${key}` : key);
  [ACCESS_TOKEN, REFRESH_TOKEN, EXPIRES, ...TOKEN_FIELDS].forEach(key =>
    window.localStorage.removeItem(getFieldKey(key))
  );
}

export function removeRootAuth() {
  return removeAuth(true);
}

export function checkExpires(expires) {
  return expires > new Date().getTime();
}

export function parseToken(token) {
  let splited = tokenDecode(splitToken(token || false));
  return splited;
}

export function splitToken(token) {
  if (typeof token !== "string") return false;
  let splited = token.split(".");
  return splited.length === 3 ? splited : false;
}

export function tokenDecode(tokenArr) {
  return tokenArr
    ? parseIfString(tokenArr[1] ? Base64.decode(tokenArr[1]) : false)
    : false;

  function parseIfString(json) {
    if (!json) return false;

    try {
      let parsed = JSON.parse(json);
      return parsed;
    } catch (e) {
      return false;
    }
  }
}

export function getFieldsFromParsed(parsed, fields) {
  return parsed
    ? fields.reduce((acc, field) => ({ ...acc, [field]: parsed[field] }), {})
    : false;
}
