import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "@/components/util-components/Button/Button";
import { useForm } from "react-hook-form";

export const TableFilters = ({
  filters,
  buttons,
  title,
  callback,
  className,
  external,
  volume,
  hideCount,
}) => {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    control,
    setValue
  } = useForm();

  const onSubmit = (data) => {
    data.date = "";
    callback(data)
  };

  const onReset = () => {
    buttons[0].callback();
    reset({}, { keepIsSubmitted: false });
  };

  const [init, setInit] = React.useState(false);

  React.useEffect( () => () => {
    if (init) {
      callback({});
    }
    setInit(true);
  },[]);

  return (
    <form onChange={handleSubmit(onSubmit)}
          className={`flex items-center w-full ${external && "py-6 flex flex-column min-w-full sm:px-2.5 lg:px-8"} ${className}`}>

      {external &&
      <span className={"self-start block flex-grow text-2xl mb-1r font-gray-900"}>{title}</span>}

      <div className={"flex items-stretch w-full"}>

        {title && !external &&
        <span className={"block flex-grow text-2xl font-gray-900"}>
          {title} {volume && !hideCount && ` (${volume})`}
        </span>}

        <div className={`flex items-stretch children:ml-4 ${external && "flex-grow pr-1r"}`}>
          {
            filters?.map((filter, index) => {
              const Component = filter.Component;
              return <Component key={index}
                                getValues={getValues}
                                external={true}
                                register={register}
                                errors={errors}
                                control={control}
                                setValue={setValue}
                                callbackSubmit={handleSubmit(onSubmit)}
                                {...filter.props} />
            })
          }
        </div>

        <div className={"flex ml-auto items-center children:ml-4"}>
          {
            buttons?.map((button, index) => {
                return <Button key={index}
                               callback={button.reset ? onReset : button.callback}
                               {...button} />
              }
            )
          }
        </div>

      </div>
    </form>
  );

};

TableFilters.defaultProps = {
  className: "",
  external: true,
  hideCount: true,
  title: "Фильтры",
  callback: () => {},
}

TableFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  buttons: PropTypes.arrayOf(PropTypes.object),
  callback: PropTypes.func,
}
