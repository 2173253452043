import React, { useState ,useEffect } from 'react'
import {getFormattedValuteDefault} from "@/utils/numeral";
import { Skeleton, List, message } from 'antd'
import moment from 'moment'

export const PaymentsList = (props) => {
  const { payments } = props

  const renderPayment = payment => (
    <List.Item
      className={"bdds-payments-list-item"}
      key={payment.id}
      extra={<div style={{textAlign: 'right', paddingLeft: '20px', paddingRight: '5px'}}>
        {
          payment.amount < 0
            ? <span className={'value-negative'} style={{fontSize: '1.3em'}}>{getFormattedValuteDefault(payment.amount)}&nbsp;₽</span>
            : <span className={'value-positive'} style={{fontSize: '1.3em'}}>+{getFormattedValuteDefault(payment.amount)}&nbsp;₽</span>
        }
      </div>}
    >
        <List.Item.Meta
          title={ moment(payment.dateTime).format('LLL').replace(' г.,','') }
          description={ payment.counterpartyName === '#NULL!' ? 'Нет имени' : payment.counterpartyName }
        />
        <div>
          { payment.purpose }

        </div>
    </List.Item>
  )

  return (<List
    className="bdds-payments-list"
    itemLayout="vertical"
    dataSource={payments}
    renderItem={renderPayment}
  />)
}
