import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@/components/util-components/Button/Button';

export const CardLayout = ({
  className,
  closeButton,
  editButton,
  userId,
  generateChildren
}) => {

    return (
      <div className={'p-4 bg-white flex-1'}>
        <div className={` grid md:grid-cols-card gap-x-12 gap-y-5 ${className}`}>
          {generateChildren()}
        </div>

        <div className={'flex items-center children:mr-1r'}>
          {closeButton && <Button target={closeButton} text={'Закрыть'}
                                  background={'white'}
                                  className={'border-px border-gray-400 self-start inline-block mt-4'}
                                  fontColor={'gray-800'}
                                  fontWeight={'normal'}
                                  fontHover={'text-gray-800'}
                                  useBackgroundIntensity={false} />}

          {(!!userId || userId?.toString() === '0') &&
          <Button target={editButton}
                  text={'Редактировать'}
                  background={'yellow'}
                  backgroundIntensity={500}
                  fontWeight={'normal'}
                  className={'self-start inline-block mt-4'}
                 />}
        </div>
      </div>
    );
};

CardLayout.defaultProps =
  {
    className: ''
  };

CardLayout.propTypes = {};
