import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react'
import { Modal, Form, Spin } from 'antd'
import { CrmService } from "@/api/crm-orders";
import {
	getOrderMessage,
	mapFormToOrder,
	mapOrderToFormData,
} from "@/views/app-views/crm/requisitions/order-form/utils";
import {
	useApplyOrderMeta,
	useFetchOrder,
	useFormOrderActions,
	useGasPrice,
	useMaxTonnage,
	useShowBlockConditions
} from "@/views/app-views/crm/requisitions/order-form/hooks";
import OrderMainBlock from "@/views/app-views/crm/requisitions/order-form/main-block";
import OrderPaymentBlock from "@/views/app-views/crm/requisitions/order-form/payment-block";
import { OrderPassRegistrationBlock } from "@/views/app-views/crm/requisitions/order-form/pass-registration-block";
import { connect } from "react-redux";
import { OrderPassRegistrationCompletedBlock } from "@/views/app-views/crm/requisitions/order-form/pass-registration-completed-block";
import { OrderCancelledBlock } from "@/views/app-views/crm/requisitions/order-form/cancelled-block";
import { OrderShipmentBlock } from "@/views/app-views/crm/requisitions/order-form/shipped-block";
import OrderPassRegistrationClientBlock from "./pass-registration-form-client-block"
import { GasPriceWidget } from "@/views/app-views/home/widgets/GasPriceWidget";
import { usePrevious } from "@/utils/hooks";

const OrderFormModal = ({ onSendForm, onClose, visible, orderId, isClient, userData, authData }) => {

	const { order, loading, resetOrder, orderPriceDataRef } = useFetchOrder(orderId)
	const prevOrder = usePrevious(order)

	const canConfirmPayment = useMemo(() => {
		if (!authData) return false

		return authData.groups.includes('order_payments_rw') || authData.groups.includes('root')
	}, [authData])

	const [staffPassRegistrationIsVisible, setStaffRegistrationBlockVisibility] = useState(false)


	const modalRef = useRef(null)

	const { maxTonnage, maxTonnageRef } = useMaxTonnage(visible)
	const { actualPriceDataRef, loading: gasPriceIsLoading } = useGasPrice(!orderId, visible)

	// FORM
	const formTitle = useMemo(() => orderId ? `Заказ №${orderId}` : 'Новый заказ', [orderId])
	const [form] = Form.useForm()

	const [maxTonnageTooltipVisible, setMaxTonnageTooltipVisible] = useState(false)

	const [formErrors, setFormErrors] = useState([])

	// SET ACTUAL PRICE IN NEW ORDER
	const isNeedToSetActualPrice = useMemo(() => {
		return visible && !orderId && actualPriceDataRef.current.actualPrice
	}, [visible, actualPriceDataRef.current, orderId])

	useEffect(() => {
		if (isNeedToSetActualPrice) {
			const price = actualPriceDataRef.current.actualPrice
			form.setFieldsValue({ price })
		}
	}, [isNeedToSetActualPrice])

	const { fetchAndApplyOrderMeta, orderMetaIsLoading } = useApplyOrderMeta(form)


	// SET ORDER ONLOAD
	useEffect(() => {
		if (order) {
			let orderMerged = { ...order }
			orderMerged.sum = orderMerged.price * orderMerged.tonnage
			const formData = mapOrderToFormData(orderMerged)
			form.setFieldsValue(formData)
			console.log('formData', formData)

			if (!isClient && order.status === 'new' && formData.clientId) {
				// fetchAndApplyOrderMeta(formData.clientId)
			}
		}
	}, [order, isClient])


	// SET CLIENT_ID IN NEW ORDER
	useEffect(() => {
		if (visible && isClient && userData && !orderId) {
			form.setFieldsValue({ clientId: userData.clientId })
		}
	}, [visible, orderId, isClient, authData])

	const onValuesChange = useCallback((changedValues, allValues) => {
		console.log('onValuesChange', changedValues, allValues, actualPriceDataRef.current, maxTonnage)

		if (changedValues.hasOwnProperty('clientId')) {
			if (!orderId) {
				fetchAndApplyOrderMeta(changedValues.clientId, true)
			}
		}

		if (changedValues.hasOwnProperty('tonnage') || changedValues.hasOwnProperty('price')) {
			setMaxTonnageTooltipVisible(false)
			const price = allValues['price']
			const tonnage = allValues['tonnage']

			if (!price || !tonnage) {
				form.setFieldsValue({ sum: 0 })
			} else {
				let maxTonnage = maxTonnageRef.current
				if (tonnage > maxTonnage) {
					// form.setFieldsValue({ sum: 0 })
					// form.setFieldsValue({ tonnage: 0 })
					// setMaxTonnageTooltipVisible(true)
				} else {
					// const sum = Math.floor(tonnage * price * 100) / 100
					// form.setFieldsValue({ sum: sum })
				}

				const sum = Math.floor(tonnage * price * 100) / 100
				form.setFieldsValue({ sum: sum })
			}

			let { minPrice, maxPrice, actualPrice } = orderId ? orderPriceDataRef.current : actualPriceDataRef.current

			if (!price || price === 0) {
				form.setFieldsValue({ price: actualPrice })
				const sum = Math.floor(tonnage * actualPrice * 100) / 100
				form.setFieldsValue({ sum: sum })
			} else {
				const sum = Math.floor(tonnage * price * 100) / 100
				form.setFieldsValue({ sum: sum })
			}

		}

	}, [orderId])

	// CANCEL ORDER
	const [isCancelling, setIsCancelling] = useState(false)

	const onCancelOrder = useCallback(async () => {
		setFormErrors([])
		setIsCancelling(true)
		try {
			const orderResult = await CrmService.orders.cancel(orderId)
			const message = getOrderMessage({ id: orderId, status: orderResult.status, isClient })
			onSendForm(orderResult)
			console.log('message', message)
			onClose(message)
		} catch (e) {
			setFormErrors([`Ошибка отмены заказа: ${e.toString()}`])
		}
		setIsCancelling(false)
	}, [orderId])

	// CONFIRM ORDER
	const [isSending, setIsSending] = useState(false)
	const onUpdateOrderStatus = useCallback(async (method) => {
		form.validateFields()
			.then(() => {
				setIsSending(true)
				const payload = { ...mapFormToOrder(form) }
				if (orderId) payload.id = orderId
				return method(payload)
			})
			.then(orderUpdated => {
				console.log('orderUpdated', orderUpdated)
				setIsSending(false)
				const message = getOrderMessage({ id: orderUpdated.id, status: orderUpdated.status, isClient })
				onSendForm(orderUpdated)
				onClose(message)
			})
			.catch(e => {
				if (!e.errorFields) {
					setFormErrors(Array.isArray(e.message) ? e.message : [e.toString()])
				}
				setIsSending(false)
			})
	}, [orderId])

	const formBlockConditions = useShowBlockConditions(order, isClient)

	const modalFooter = useFormOrderActions({
		canConfirmPayment,
		isClient,
		orderStatus: order && order.status,
		onCancelOrder: onCancelOrder,
		onUpdateOrderStatus: onUpdateOrderStatus,
		isCancelling: isCancelling,
		isLoading: isSending,
		staffPassRegistrationIsVisible
	})

	const _afterClose = useCallback(() => {
		resetOrder()
		form.resetFields()
		setFormErrors([])
		setStaffRegistrationBlockVisibility(false)
	}, [])

	const RenderedPriceWidget = useMemo(() => {
		if (order && order.status !== 'new') return null
		if (!isClient) return <div className={"mb-4"}><GasPriceWidget height={150}/></div>
		else {
			const props = {
				allowSelectPeriod: false,
				initialView: 'month',
				seriesToShow: ['eoil'],
				actualSeriesToShow: ['eoil'],
				yAxisInterval: 1000
			}
			return <div className={"mb-4"}><GasPriceWidget height={150} {...props} /></div>
		}
	}, [order, isClient])

	return (<Modal
		maskClosable={false}
		width={800}
		ref={modalRef}
		style={{ top: '1rem' }}
		bodyStyle={{ maxHeight: '70vh', overflowY: "scroll", backgroundColor: '#f0f2f5' }}
		visible={visible}
		title={formTitle}
		onCancel={onClose}
		footer={modalFooter}
		afterClose={_afterClose}
	>
		<Form
			layout="vertical"
			className={"crm-order-form"}
			requiredMark={false}
			form={form}
			autocomplete={"off"}
			onValuesChange={onValuesChange}
			validateTrigger={'onBlur'}
			name="basic"
		>

			<Spin tip={"Загрузка"} spinning={loading || orderMetaIsLoading}>

				{RenderedPriceWidget}

				<OrderMainBlock order={order} maxTonnage={maxTonnage} gasPriceIsLoading={gasPriceIsLoading}
				                tonnageTooltipVisible={maxTonnageTooltipVisible}/>

				{formBlockConditions.isShowPaymentBlock && <OrderPaymentBlock order={order}/>}

				{(!staffPassRegistrationIsVisible && formBlockConditions.isShowWaitingPassRegistrationBlock) &&
				<OrderPassRegistrationBlock order={order}
				                            onShowPassRegistrationForm={() => setStaffRegistrationBlockVisibility(true)}/>}

				{(staffPassRegistrationIsVisible || formBlockConditions.isShowPassRegistrationClientBlock) &&
				<OrderPassRegistrationClientBlock order={order}/>}

				{formBlockConditions.isShowPassRegistrationStaffBlock &&
				<OrderPassRegistrationCompletedBlock order={order}/>}

				{formBlockConditions.isShowCancelledBlock && <OrderCancelledBlock order={order}/>}

				{formBlockConditions.isShowShipmentBlock && <OrderShipmentBlock order={order}/>}

			</Spin>

			<div className={"text-danger"}>
				<Form.ErrorList errors={formErrors}/>
			</div>
		</Form>
	</Modal>)
}


const mapStateToProps = ({ auth }) => {
	const { authData, userData } = auth;
	const isClient = authData && authData.groups && authData.groups.includes('clients')
	return { isClient, userData, authData }
};

export default connect(mapStateToProps, {})(OrderFormModal)
