import React, {lazy} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {connect} from "react-redux";
import {Input} from "@/components/forms/Inputs/Input";
import {fetchGetUsersHistory} from "@/redux/actions/Users";
import {saveTableAsFile} from "@/utils/saveTable";
import {withToast} from "@/hoc/withToast";
import {withFilters} from "@/hoc/withFilters";
import {callbackFilters} from "@/helpers/HelpersFilters";

const UsersHistory = lazy(() => import("./UserHistory"));

class UsersHistoryContainer extends React.Component {
	constructor(props) {
		super(props);

	}

	getData = (obj) => {
		const {filters, page, sort} = this.props;
		this.props.setIsLoadedGlobal(false);
		this.props.fetchGetUsersHistory({sort, filters, page});
	}

	componentDidMount() {
		const {paramsFiltersObj, paramsSortObj} = this.props.parseURLToObj(true);
		this.props.setFilters(paramsFiltersObj);
		this.props.setSort(paramsSortObj);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.sort !== this.props.sort || prevProps.filters !== this.props.filters || prevProps.page !== this.props.page) {
			this.getData();
		}
	}

	onPageChange = page => {
		page = page.selected + 1;
		this.props.setPage(page);
		window.scrollTo(0, 0);
	};

	render() {

		let {totalCount, initialPage, filters} = this.props;

		const headers = [
			{
				name: "Сотрудник",
				value: "lastName",
				filter: true,
			},
			{
				name: "URI",
				value: "path",
				filter: true,
			},
			{
				name: "Действие",
				value: "action",
				filter: true,
			},
			{
				name: "Дата",
				value: "createdAt",
				filter: true,
			}
		];

		const filtersSettings = {
			buttons: [
				{
					icon: "file-excel",
					text: "Экспорт",
					background: "blue",
					className: "mr-2",
					callback: () => {
						saveTableAsFile("/user_history/excel", this.props.filters, "История активности сотрудников")
					}
				},
			]
		}

		const externalFilters = {
			filters: [
				{
					Component: Input,
					props: {
						"type": "text",
						"value": [""],
						"containerClassName": "ml-0 min-w-1/4",
						"className": "w-full",
						"input": {
							"id": 0,
							"name": "search",
							"label": "Имя, Email, URI",
							"placeholder": "Имя, Email, URI",
							"value": this.props.filters.search
						}
					},
				},
				{
					Component: Input,
					props: {
						"type": "select",
						"containerClassName": " min-w-1/4",
						"className": "w-full",
						"input": {
							"id": 54,
							"name": "action",
							"label": "Действие",
							"placeholder": "Действие",
							"value": this.props.filters.action,
							"options": [
								{
									"value": "open",
									"text": "Открытие",
								},
								{
									"value": "save",
									"text": "Редактирование",
								},
								{
									"value": "create",
									"text": "Создание",
								},
								{
									"value": "delete",
									"text": "Удаление",
								},
							]
						}
					}
				},
				{
					Component: Input,
					props: {
						"type": "dateRange",
						"value": [moment(filters.startDate), moment(filters.endDate)],
						"input": {
							"calendarDateType": "day",
							"value": !filters.startDate && !filters.endDate ? undefined : [moment(filters.startDate), moment(filters.endDate)]
						}
					}
				},
			],
			buttons: [
				{
					text: "Сброс",
					background: "white",
					fontColor: "gray-900",
					fontHover: "gray-700",
					callback: () => callbackFilters({}, fetchGetUsersHistory, this.props),
					reset: true,
					useBackgroundIntensity: false
				}
			],
			callback: (filters) => callbackFilters(filters, fetchGetUsersHistory, this.props)
		}

		return (
			<UsersHistory headers={headers}
			              rows={this.props.usersHistory}
			              filtersSettings={filtersSettings}
			              totalCount={totalCount}
			              onPageChange={this.onPageChange}
			              initialPage={initialPage}
			              externalFilters={externalFilters}
			              getData={this.getData}
			              {...this.props} />

		);
	}
}

const mapStateToProps = ({users, global}) => (
	{
		usersHistory: users.usersHistory.items,
		totalCount: users.usersHistory?.last || 0,
		initialPage: users.usersHistory?.current - 1 || 0,
		isLoaded: users.usersHistory.isLoaded,
	}
);

const mapDispatchToProps = (dispatch) => ({
	fetchGetUsersHistory: (e) => dispatch(fetchGetUsersHistory(e)),
});

export default withFilters(withToast(connect(mapStateToProps, mapDispatchToProps)(UsersHistoryContainer)));
