import {service,noAuthService} from "./service";

const loginErrorMap = {
  "user not found or password wrong": "Неверный email-адрес или пароль.",
  "need email and password": "Введите логин и пароль."
}


const login = ({ email, password }) => service("/auth/login", {
  method: "POST",
  /*headers: {
    "Content-Type": "multipart/form-data"
  },*/
  data: { email, password }
}).then( response => response.data ).catch(e => {
  if (e.message && Array.isArray(e.message)) {
    throw e.message.map( str => loginErrorMap[str] || str)
  } else {
    throw e
  }
  throw e
})

const recoveryPassword = email => noAuthService.post('auth/recovery-password', { email })

const resetPassword = (email, activationCode, password) => noAuthService.post('/auth/reset-password', { email, activationCode, password })

const updatePassword = ({ password, newPassword }) => service.post('/users/change-password', { password, newPassword })

const fetchUser = (id) => service.get(`/users/profile`).then( response => response.data )

export const AuthService = {
  login,
  fetchUser,
  password: {
    recovery: recoveryPassword,
    reset: resetPassword,
    update: updatePassword
  },
  registrationComplete: ({ email, activationCode, password, firstName, lastName, phone }) =>
    service.post(`/auth/registration-complete`, { email, activationCode, password, firstName, lastName, phone }).then( r => r.data ),
  checkoutToUser: userId => service.patch('/auth/emulation',{userId}).then(r => r.data ),
  updatePassword: (id, form) => service.patch(`/users/updateProfilePassword`, form).then( r => r.data ),
  updateAvatarPath: (id, file) => service.post(`/users/${id}/avatar`, {file}, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }).then( r => r.data )
}
