import React from 'react';
import PropTypes from 'prop-types';

export const InputCheckbox = ({id, name, value, label, placeholder, register, required, type, callback, hasError, className}) => {
    const errorClassList = "";



    return (
            <label className="inline-flex items-center py-0.5r">
                <input type="checkbox" {...register(name, {required: required})} defaultChecked={!!value} className="form-checkbox text-base cursor-pointer focus:border-indigo-300 outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-gray-300 rounded-md h-5 w-5 text-blue-400"/>
                <span className="ml-2 text-gray-700">{placeholder || label}</span>
            </label>
    );
};
InputCheckbox.defaultProps = {
    className: ""
}
InputCheckbox.propTypes = {}