import React, {useCallback, useMemo} from 'react'
import { OrderBlockTitle } from "./order-block-title"
import {ClientsApi} from "@/api/clients";
import {connect} from "react-redux";
import {checkoutToRoot, signOut, toggleCollapsedNavProfile, updateUserData} from "@/redux/actions";
import {NavProfile} from "@/components/layout-components/NavProfile";
import {Button, Col, Form, Row} from "antd";
import {InputSelectRemote} from "@/components/shared-components/input-select-remote";
import {CustomInputNumber} from "@/components/shared-components/input-number";
import {getFormattedValuteDefault} from "@/utils/numeral";
import {useDocumentsDownload} from "@/views/app-views/crm/requisitions/order-form/hooks";
import {PaperClipOutlined} from "@ant-design/icons";
import {showErrorDefault, useFetchWithStatus} from "@/utils/hooks";
import {CrmService} from "@/api/crm-orders";

const OrderPaymentBlock = ({ order, isClient }) => {

  const { date, title } = useMemo(() => {
    if (order.status === 'on-payment') {
      return {
        date: order.invoiceCreated,
        title: 'Ожидает оплаты'
      }
    } else {
      return {
        date: order.invoicePaid,
        title: 'Оплачен'
      }
    }
  },[order])

  const docPayment = useDocumentsDownload(order, 'invoice-documents')
  const docPaymentTemplate = useDocumentsDownload(order, 'invoice-documents', 1)
  const docResend = useDocumentsDownload(order, 'send-invoice-documents')

  const isShowDocResend = useMemo(() => order.status === 'on-payment', [order])

  return <div className={"mb-4"}>
    {
      order.status !== 'on-payment'
        ? <OrderBlockTitle status={'paid'} title={title} dateTime={date} highlight={false}/>
        : <OrderBlockTitle status={'on-payment'} title={title} dateTime={date} highlight={true}/>
    }
    <div className={"p-3"} style={{backgroundColor: 'white', borderRadius: '0.625rem'}}>
      <Row gutter={16}>
        <Col sm={8} className={""}>
          <h5>Документы</h5>
          <Button size={"small"}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  className={"download-order-button"}
                  icon={<PaperClipOutlined />}
                  type="link"
                  loading={docPayment.downloading}
                  onClick={docPayment.download}
                  title={"Приложение и счет"}>
            Приложение и счет.pdf
          </Button>
          <Button size={"small"}
                  style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  className={"download-order-button"}
                  icon={<PaperClipOutlined />}
                  type="link"
                  loading={docPaymentTemplate.downloading}
                  onClick={docPaymentTemplate.download}
                  title={"Приложение и счет"}>
            Шаблон приложения и счета.pdf
          </Button>
        </Col>
        <Col sm={10}>
          <h5>Получатели</h5>
          {
            order.client.users.map( ({ email }) => email ).join(', ')
          }
        </Col>
        { isShowDocResend && <Col sm={6} className={"text-right"}>
          <h5>&nbsp;</h5>
          <Button size={"small"} onClick={docResend.download} loading={docResend.downloading}
                  type="primary">Переотправить</Button>
        </Col>}
      </Row>
    </div>
  </div>
}
const mapStateToProps = ({ auth }) => {
  const { authData } = auth;
  const isClient = authData && authData.groups && authData.groups.includes('clients')
  return { isClient }
};

export default connect(mapStateToProps, {})(OrderPaymentBlock)
