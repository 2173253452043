import { service } from "@/api/service";
import { orderBy } from "lodash";

const refactorSellingData = (counterparties, data) => {
  return counterparties.map(counterparty => {
    // let markData = new Array(data.items.length).fill({
    //   name: "",
    //   value: 0,
    //   type: "",
    // });

    let markData = [];

    let marksValues = {};

    data.items.forEach((item, index) => {
      marksValues[item.name] = 0;
      let findItem = item.data.find(moneyItem => moneyItem.name === counterparty);
      if (findItem) {
        let { name, value, type } = findItem;
        markData[index] = { name, value, type };
      }
    });

    return {
      name: counterparty,
      data: markData,
    }
  });
};

const API_HOST = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_HOST_API}`;

const getHeaders = () => ({
  Accept: "application/json",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})

const priceMarketExport = filters => service.get(`/widgets/priceMarketExport`, {
  baseURL: API_HOST,
  headers: getHeaders(),
  params: {
    ...filters,
  },
}).then(response => {
  response.data.items = orderBy(response.data.items, ["name"]);
  return response.data;
});

const sellingByClientsAndTypes = filters => service.get(`/widgets/sellingByClientsAndTypes`, {
  baseURL: API_HOST,
  headers: getHeaders(),
  params: {
    ...filters,
  },
}).then(response => {

  let { data } = response;

  let { counterparties, money, tons } = data;

  data.money.items = refactorSellingData(counterparties, money);
  data.tons.items = refactorSellingData(counterparties, tons);

  return data;
});

const debitCredit = (filters, id) => service.get(`/widgets/debitCredit`, {
  baseURL: API_HOST,
  headers: getHeaders(),
  params: {
    ...filters,
    id,
  },
}).then(r => r.data);

const sellingByMarks = filters => service.get(`/widgets/sellingByMarks`, {
  baseURL: API_HOST,
  headers: getHeaders(),
  params: {
    ...filters,
  },
}).then(r => r.data);

export const WidgetService = {
  priceMarketExport,
  sellingByClientsAndTypes,
  debitCredit,
  sellingByMarks
};
