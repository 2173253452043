import React, {lazy, Suspense, useMemo} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Loading from '@/components/shared-components/Loading';
import {IS_DON_UGLI, IS_RWSP} from "@/utils/env"
import {message} from 'antd'
import moment from 'moment'
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import User from "@/views/app-views/users/User/User";
import UserCreate from "@/views/app-views/users/UserCreate/UserCreate";
import UsersHistoryContainer from "@/views/app-views/users/UsersHistory/UsersHistoryContainer";
import {Toast} from "@/components/util-components/Toast/Toast";
import Counterparty from "@/views/app-views/counterparties/Counterparty/Counterparty";
import CounterpartyCreate from "@/views/app-views/counterparties/CounterpartyCreate/CounterpartyCreate";

const Users = lazy(() => import("./users/UsersContainer"));
const Counterparties = lazy(() => import("./counterparties/CounterpartiesContainer"));

const BddsView = lazy(() => import(`./bdds/index`))
const BdrView = lazy(() => import(`./bdr/index`))
const GasTransfer = lazy(() => import(`./gas-transfer`))

export const AppViews = ({match}) => {
  library.add(fas);

  const HomePage = useMemo(() => {
    if (IS_DON_UGLI) return <Route path={[`/home`]} exact component={lazy(() => import('./home-donugli'))}/>
    else return <Route path={[`/home`]} exact component={lazy(() => import('./home'))}/>
  }, [])

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Toast/>
      <Switch>
        {HomePage}
        <Route path={`/gas-transfers`} render={(props) => {
          const searchParams = new URLSearchParams(props.location.search)
          const maxDate = moment(searchParams.get('maxDate') || new Date())
          const minDate = searchParams.get('minDate') ? moment(searchParams.get('minDate')) : moment(new Date()).add(-30, "days")
          const dateInterval = [minDate, maxDate]
          // const monthParam = searchParams.get('month')
          // const month = moment(monthParam || new Date())

          return <GasTransfer {...props} dateInterval={dateInterval}/>

        }}/>
        <Route path={`/bdds`}
               render={(props) => {
                 const searchParams = new URLSearchParams(props.location.search)
                 const year = +searchParams.get('year') || new Date().getFullYear()
                 return <BddsView {...props} year={year}/>
               }}
        />
        <Route path={`/bdr`}
               render={(props) => {
                 const searchParams = new URLSearchParams(props.location.search)
                 const view = (searchParams.get('view') || 'year')
                 const year = view === 'year'
                   ? +searchParams.get('year') || new Date().getFullYear()
                   : null
                 const month = view === 'month'
                   ? searchParams.get('month') || moment(new Date()).format('YYYY-MM')
                   : null
                 return <BdrView {...props} year={year} view={view} month={month}/>
               }}
        />
        <Route path={`/crm`} component={lazy(() => import(`./crm`))}/>
        <Route path={`/dispatch`} component={lazy(() => import(`./dispatch`))}/>
        <Route path={`/test`} component={lazy(() => import(`./test`))}/>
        <Route exact path={"/users"} component={Users}/>
        <Route exact path={"/users/history"} component={UsersHistoryContainer}/>
        <Route exact path={"/users/edit/:id"} component={UserCreate}/>
        <Route exact path={"/users/add"} component={UserCreate}/>
        <Route exact path={"/users/view/:id"} component={User}/>
        <Route exact path={"/counterparties"} component={Counterparties}/>
        <Route exact path={"/counterparties/view/:id"} component={Counterparty}/>
        <Route exact path={"/counterparties/edit/:id"} component={CounterpartyCreate}/>
        <Route exact path={"/counterparties/add/"} component={CounterpartyCreate}/>
      </Switch>
    </Suspense>
  )
}

export default AppViews;
