import React from 'react';
import PropTypes from 'prop-types';

export const TitlePage = ({title, className}) => {
    return (
        <div className={`py-4 sm:px-2.5 lg:px-8 bg-white w-full ${className}`}>
            <h1>{title}</h1>
        </div>
    );
};

TitlePage.propTypes = {
    
};