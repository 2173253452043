import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Skeleton, Card } from 'antd';
import ApexChart from "react-apexcharts";
import {
	apexLineChartDefaultOption,
	apexBarChartDefaultOption,
	apexAreaChartDefaultOption
} from '@/constants/ChartConstant';
import ReactResizeDetector from 'react-resize-detector';
import ReactEcharts from 'echarts-for-react'
import { ReactEchartsPropsTypes} from "echarts-for-react";

const titleStyle = {
	// position: 'absolute',
	// zIndex: '1',
	// width: '100%'
}

const extraStyle ={
	position: 'absolute',
	zIndex: '1',
	right: '0',
	top: '-2px'
}

const getChartTypeDefaultOption = type => {
	switch (type) {
		case 'line':
			return apexLineChartDefaultOption
		case 'bar':
			return apexBarChartDefaultOption
		case 'area':
			return apexAreaChartDefaultOption
		default:
			return apexLineChartDefaultOption;
	}
}

export const EChartWidget = ({loading, title, card, extra, option, noPaddingCard, className, width, height, onEvents }) =>  {

	const isMobile = window.innerWidth < 768


	const extraRef = useRef(null);
	const chartRef = useRef();

	const onResize = () => {
		setTimeout(() => {
		}, 600);
	}

	const renderChart = (
		<ReactResizeDetector onResize={onResize()}>
			<div className={"custom-echart flex-grow-1 d-flex flex-column"} ref={chartRef}>
				<ReactEcharts option={option} opts={{ renderer: "svg", width, height }} onEvents={onEvents} className={"flex-grow-1"}/>
			</div>
		</ReactResizeDetector>
	)

	return (
		<>
			{card ?
				<Card className={["height-100", noPaddingCard && "custom-card-no-padding" || null, className]}>
					<Skeleton loading={loading} className={noPaddingCard ? "p-3" : ""}>
					<div style={{ height: '100%' }} className={"position-relative d-flex flex-column"}>
						<div className={[noPaddingCard && "custom-card-no-padding__title" || null]}>
							{<div className="" style={!isMobile ? titleStyle : {}}>{title}</div>}
							{extra && <div ref={extraRef} style={!isMobile ? extraStyle : {}}>{extra}</div>}
						</div>
						{renderChart}
					</div>
					</Skeleton>
				</Card>
				:
				<Skeleton loading={loading}>{renderChart}</Skeleton>
			}
		</>
	)
}

EChartWidget.propTypes = {
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	option: PropTypes.object,
	card: PropTypes.bool,
}

EChartWidget.defaultProps = {
	card: true
};

export default EChartWidget
