import React from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete'
import './tagInput.scss';

class TagInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: props.value,
      suggestions: props.suggestions,
      shownSuggestions: props.suggestions.map(object => ({ ...object })),
    }

    this.reactTags = React.createRef();
  }

  componentDidMount() {
    this.updateSuggestions();
    if (Object.keys(this.state.tags).length > 0) {
      this.updateInput();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      const { getValues, name, suggestions } = this.props;
      if (!Array.isArray(getValues(name))) {
        this.setState({ tags: [], shownSuggestions: suggestions });
      }
      this.setState({ tags: this.props.value, suggestions }, this.updateSuggestions);
    }
  }

  updateInput() {
    let { setValue, name } = this.props;
    let { tags } = this.state;
    setValue(name, tags);
  }

  onDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags }, () => {
      this.updateInput();
      this.props.callback(tags);
      this.updateSuggestions();
    });
  }

  onAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags }, () => {
      this.updateInput();
      this.props.callback(tags);
      this.updateSuggestions();
    });
  }

  updateSuggestions() {
    const shownSuggestions = this.state.suggestions.map(object => ({ ...object }));
    this.state.tags.forEach(value => {
      if (shownSuggestions.some(suggestion => JSON.stringify(suggestion) === JSON.stringify(value))) {
        shownSuggestions.forEach((suggestion, index) => {
          if (JSON.stringify(suggestion) === JSON.stringify(value)) {
            shownSuggestions.splice(index, 1);
          }
        })
      }
    });
    this.setState({ shownSuggestions });
  }

  render() {
    const {
      className,
      placeholder,
      id,
      register,
      name,
      required,
      minValue,
      maxValue,
      maxLength,
      minLength
    } = this.props;

    const { shownSuggestions, tags } = this.state;

    return (
      <div className={className}>
        <input type={"hidden"} {...register(name, {
          required,
          min: minValue,
          max: maxValue,
          maxLength,
          minLength
        })} />

        <ReactTags id={id}
                   ref={this.reactTags}
                   tags={tags}
                   placeholderText={placeholder}
                   suggestions={shownSuggestions}
                   onDelete={this.onDelete.bind(this)}
                   allowBackspace={false}
                   maxSuggestionsLength={20}
          // allowNew={true}
                   onAddition={this.onAddition.bind(this)} />

      </div>
    )
  }
};

TagInput.defaultProps = {
  suggestions: [],
  className: "",
  placeholder: "",
  name: "tags",
  value: []
}

TagInput.propTypes = {
  callback: PropTypes.func.isRequired,
  suggestions: PropTypes.array,
  className: PropTypes.string
}

export { TagInput };
