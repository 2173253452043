import { service } from "./service"
import { CrmOrderStatusMap } from '@/views/app-views/crm/utils'
import { getFile } from "./service"
import { wait } from "@gagydzer/my-axios-service/src/utils";
import { message } from 'antd'

/**
 *
 * @param clientId {number}
 * @param status {string}
 */
const fetchList = ({clientId, status, page = 1, limit = 0, minDate, maxDate} = {}) =>
  service.get('/orders', { params: { clientId, status, page, limit, minDate, maxDate } }).then( r => r.data.items )
const createOrder = (order) => service.post('/orders', order).then( r => r.data )
const fetchOrder = orderId => service.get(`/orders/${orderId}`).then( r => r.data )
const fetchOrderDocuments = orderId => getFile(`/orders/${orderId}/documents`)
const fetchActualPrice = () => service.get('/orders/actual-price').then(r => r.data )
const passRegistration = order => service.patch(`/orders/${order.id}/pass-registration`, order).then( r => r.data )
const updateOrder = (order) => service.patch(`/orders/${order.id}`, order).then( r => r.data )

const documents = {
  ['invoice-documents']: (orderId, params = {}) => getFile(`/orders/${orderId}/invoice-documents`, params),
  ['send-invoice-documents']: orderId => service.get(`/orders/${orderId}/send-invoice-documents`),
  ['requisition-documents']: orderId => getFile(`/orders/${orderId}/requisition-documents`),
  ['pass-documents']: orderId => getFile(`/orders/${orderId}/pass-documents`),
  ['zalivka-documents']: orderId => getFile(`/orders/${orderId}/zalivka-documents`),
  ['ttn-documents']: orderId => getFile(`/orders/${orderId}/ttn-documents`),
  ['acceptance-documents']: orderId => getFile(`/orders/${orderId}/acceptance-documents`),
}

const getOrdersStat = orders => orders.reduce((acc, order) => {
  acc.tonnage += order.tonnage
  acc.sum += (order.price * order.tonnage)
  return acc
},{
  count: orders.length,
  tonnage: 0,
  sum: 0
})

const concatArrays = arrays => arrays.reduce( (acc, arr) => {
  acc.push(...arr)
  return acc
},[])

const fetchOrderByStatus = status => service.get('/orders', { params: { status } }).then( r => r.data.items ).catch( e => {
  console.error(e)
  return []
})

const fetchDashboardWidgetData = (statuses = ['new', 'on-payment', ['paid', 'pass-registration', 'on-shipment']]) => Promise.all(
  statuses.map( status => (
      Array.isArray(status)
        ? Promise.all(status.map(fetchOrderByStatus)).then(concatArrays)
        : fetchOrderByStatus(status)
    ).then( orders => {
      return {
        ...getOrdersStat(orders),
        status,
        title: Array.isArray(status) ? 'К отгрузке' : CrmOrderStatusMap[status]
      }
    })
  )
).then( items => items.filter( item => item !== null ))

const fetchInitOrderMeta = async (clientId) => {
  const orderMeta = {}
  const [ lastOrder, lastClientOrder ] = await Promise.all([
    fetchList({ limit: 1 }).then( orders => orders && orders.length ? orders[0] : null ).catch( e => {
      console.error(e)
      return null
    }),
    fetchList({ limit: 1, clientId }).then( orders => orders && orders.length ? orders[0] : null ).catch( e => {
      console.error(e)
      return null
    })
  ])

  if (lastOrder) {
    orderMeta.productPassportNumber = lastOrder.productPassportNumber || null
    orderMeta.productPassportDate = lastOrder.productPassportDate || null
    orderMeta.productGrade = lastOrder.productGrade || null
  }

  if (lastClientOrder) {
    orderMeta.annexNumber = (lastClientOrder.annexNumber || 0) + 1
  }

  return orderMeta
}

export const CrmService = {
  orders: {
    fetchInitOrderMeta,
    fetchList,
    fetchOrder,
    createOrder,
    fetchOrderDocuments,
    updateOrder,
    passRegistration,
    documents,
    putOrder: (order) => service.put('/orders', order).then( r => r.data ),
    cancel: (orderId) => service.patch(`/orders/${orderId}/cancel`).then( r => r.data ),
    approve: (order) => service.patch(`/orders/${order.id}/approve`).then( r => r.data ),
    confirmPayment: (order) => service.patch(`/orders/${order.id}/set-paid`).then( r => r.data ),
    startShipment: (orderId) => service.patch(`/orders/${orderId}/start-shipment`).then( r => r.data ),
    completeShipment: (orderId, data) => service.patch(`/orders/${orderId}/complete-shipment`, data).then( r => r.data ),
  },
  fetchDashboardWidgetData,
  fetchActualPrice,
  updateActualPrice: price => service.patch(`/orders/actual-price`, { price }),
  fetchActualTonnageStatistic: () => service.get(`/stock/actual-statistic`).then( r => r.data ),
  fetchMaxTonnage: () => service.get(`/stock/actual-statistic`).then( r => r.data ),
  stat: {
    fetchSoldTonnageStat: ({ minDate, maxDate }) => fetchList({ status: 'shipped', minDate, maxDate })
      .then( orders => Object.fromEntries(
          Object.entries(orders.reduce( (acc, order) => {
            const invoiceDateTimeString = order.invoiceCreated
            if (invoiceDateTimeString === null) {
              console.warn(`fetchSoldTonnageStat order #${order.id} has no invoiceCreated`)
            } else {
              const [date, time] = invoiceDateTimeString.split(' ')
              if (acc[date] === undefined) {
                acc[date] = 0
              }
              acc[date] += order.shipmentNet
            }
            return acc
          }, {})).map( ([dateString, kilo]) => ([dateString, Math.round((kilo / 1000) * 100 ) / 100 ]))
        )
      ).catch(e => {
        console.error(e)
        return {}
      })
  },
  autocomplete: (column, search) => service.get('orders/autocomplete', { params: { column, search }})
    .then( r => r.data )
}
