import React, {useCallback, useMemo} from 'react'
import { OrderBlockTitle } from "./order-block-title"
import {ClientsApi} from "@/api/clients";
import {connect} from "react-redux";
import {checkoutToRoot, signOut, toggleCollapsedNavProfile, updateUserData} from "@/redux/actions";
import {NavProfile} from "@/components/layout-components/NavProfile";
import {Button, Col, Form, Row} from "antd";
import {InputSelectRemote} from "@/components/shared-components/input-select-remote";
import {CustomInputNumber} from "@/components/shared-components/input-number";
import {getFormattedValuteDefault} from "@/utils/numeral";
import {useDocumentsDownload} from "@/views/app-views/crm/requisitions/order-form/hooks";
import {PaperClipOutlined} from "@ant-design/icons";
import {showErrorDefault, useFetchWithStatus} from "@/utils/hooks";
import {CrmService} from "@/api/crm-orders";

export const OrderPassRegistrationBlock = ({ order, onShowPassRegistrationForm }) => {

  return <div className={"mb-4"}>
    <OrderBlockTitle dateTime={order.invoicePaid}
                     title={'Ожидание оформления пропуска'}
                     status={'pass-registration'}
                     highlight={true}/>

    <div className={"p-3"} style={{backgroundColor: 'white', borderRadius: '0.625rem'}}>

      <Button size={"small"} onClick={onShowPassRegistrationForm}
              type="primary">Оформить пропуск</Button>
    </div>
  </div>

}
