import numeral from "numeral";

export const num = (value, postfix = " ₽", detailed, simple = false) => {
  let val = detailed
    ? numeral(value).format("0,0.0000")
    : numeral(value).format("0,0.00");
  if (simple) {
    val = numeral(value).format("0,0")
  }
  return val.replace(/,/g, " ").replace(/\./g, ",") + '\xa0' + postfix
};

const isWrongValue = value => value === null || value === undefined || Number.isNaN(value)

export const getFormattedValuteDefault = (value, simple = false, cutMinus) => {
  if (isWrongValue(value)) return '-'
  else {
    let vPrep = num(value, '', false, simple)
    if (cutMinus && value < 0) {
      return vPrep.substr(1)
    } else return vPrep
  }
}

const postfixMap = {
  RUB: '₽',
  TONNAGE: 'т.'
}

const getPostfixByCode = code => {
  return postfixMap[code] || ''
}

export const formatRUBSimple = (value, postfix = '₽') => num(value, postfix, false, true)

export const getFormattedValuteDefaultWithPostfix = (value, postfixCode) => num(value, getPostfixByCode(postfixCode), false, false)
