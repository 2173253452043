import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Avatar } from 'antd'
const nopic = require("@/assets/nopic.png");

export const AvatarWithFallback = props => {
  const {src, ...rest} = props

  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setIsError(false)
  },[src])

  const avatarUrl = useMemo(() => isError ? nopic : src,[src,isError])

  const onError = useCallback(() => {
    console.log('avatar on error')
    setIsError(true)
  },[])

  return <Avatar {...rest} src={avatarUrl} onError={onError}/>
}
