import React, {useCallback, useEffect, useState, useMemo} from 'react'
import {Modal, Form, Input, Button, message, Typography, Upload} from 'antd'
import {wait} from "@gagydzer/my-axios-service/src/utils";
import {PhoneInput} from "@/components/PhoneInput";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {AvatarUploader} from "@/components/avatar-uploader";
import {UserApi} from "@/api/users";

export const ModalUpdateUserData = ({ userData, userId, visible, sendMethod, closeModal, getInitialValue }) => {
  const [form] = Form.useForm()

  const [formErrors,updateFormErrors] = useState([])

  useEffect(() => {
    form.setFieldsValue(getInitialValue())
  },[form,getInitialValue])

  const onOk = useCallback(async () => {
    try {
      await form.validateFields()
    } catch (e) {
      console.error(e)
      return
    }

    try {
      const values = form.getFieldsValue()
      await sendMethod(values)
      updateFormErrors([<div className={"text-right"}><Typography.Text type={"success"}>Профиль обновлен</Typography.Text></div>])
      await wait(1000)
      closeModal()
    } catch (e) {
      console.error(e)
      updateFormErrors([<div className={"text-right"}><Typography.Text type={"danger"}>{e.toString()}</Typography.Text></div>])
    }
  },[form,sendMethod, formErrors])

  const avatarUploader = useMemo(() => {
    if (!userData) return null
    return <AvatarUploader
      value={userData.avatarPath}
      uploadMethod={file => UserApi.user.avatar.upload(userId, file)}
      removeMethod={() => UserApi.user.avatar.delete(userId)}
    />
  },[userId, userData])

  return (<Modal
    maskClosable={false}
    visible={visible}
    title="Редактирование профиля"
    okText="Обновить"
    cancelText="Назад"
    onOk={onOk}
    onCancel={closeModal}>
    <Form
      layout="vertical"
      form={form}
      validateTrigger={'onSend'}
      name="basic"
    >
      <Form.Item
        label="Имя"
        name="firstName"
        rules={[{ required: true, message: 'Имя обязательно' }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Фамилия"
        name="lastName"
        rules={[{ required: true, message: 'Фамилия обязательна' }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Телефон"
        name="phone"
      >
        <PhoneInput name="phone"/>
      </Form.Item>
      <Form.Item
        label="Пароль"
        name="password"
        rules={[{ required: true, message: 'Введите пароль' }]}
      >
        <Input.Password/>
      </Form.Item>
      <Form.ErrorList errors={formErrors}/>
    </Form>
  </Modal>)
}
