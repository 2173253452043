import axios from "axios"
import {showErrorDefault} from "@/utils/hooks";

const axiosUpdateSevice = axios.create()

export const updateService = (onUpdateAvailable) => {
  let TIME_INTERVAL = 30000
  let updateIsAvailable = false
  let _lastModified = null

  const checkUpdate = async () => {
    if (updateIsAvailable) return
    try {
      const response = await axiosUpdateSevice.get(window.location.origin)
      const lastModified = response.headers["last-modified"];

      if (_lastModified === null) _lastModified = lastModified
      else {
        if (_lastModified !== lastModified) {
          _lastModified = lastModified
          updateIsAvailable = true
          onUpdateAvailable()
        }
      }
    } catch (e) {
      showErrorDefault(e)
    }
  }

  setInterval(checkUpdate, TIME_INTERVAL)
}
