import React, {useCallback, useMemo} from 'react'
import { OrderBlockTitle } from "./order-block-title"
import {ClientsApi} from "@/api/clients";
import {connect} from "react-redux";
import {checkoutToRoot, signOut, toggleCollapsedNavProfile, updateUserData} from "@/redux/actions";
import {NavProfile} from "@/components/layout-components/NavProfile";
import {Button, Col, DatePicker, Form, Image, Row, Space, Typography} from "antd";
import {InputSelectRemote} from "@/components/shared-components/input-select-remote";
import {CustomInputNumber} from "@/components/shared-components/input-number";
import {getFormattedValuteDefault} from "@/utils/numeral";
import {useDocumentsDownload} from "@/views/app-views/crm/requisitions/order-form/hooks";
import {PaperClipOutlined} from "@ant-design/icons";
import {showErrorDefault, useFetchWithStatus, useStubFetch} from "@/utils/hooks";
import {CrmService} from "@/api/crm-orders";
import moment from "moment";

const Text = Typography.Text

export const OrderCancelledBlock = ({ order }) => {

  const title = useMemo(() => {
    return order && order.cancelledUser
      ? `Заказ отменен: ${order.cancelledUser.firstName} ${order.cancelledUser.lastName}`
      : 'Заказ отменен автоматически'
  },[order])

  return <div className={"mb-4"}>
    <OrderBlockTitle status={order.status} title={title} dateTime={order.cancelledDateTime} highlight={true}/>
  </div>

}
