import {
  IS_LOADED_GLOBAL,
  SET_ACTIVE_FILTER,
  SET_ACTIVE_SORT,
  SET_ERROR_MESSAGES, SET_PAGE, SET_PREV_URL,
  SET_SUCCESS
} from "@/redux/constants/global";


const initState = {
  isSuccess: false,
  isLoaded: false,
  sort: {},
  filters: {},
  page: 1,
  prevURL: ''
};

const Global = (state = initState, action) => {
  switch (action.type) {
    case SET_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload
      }
    case SET_ERROR_MESSAGES:
      return {
        ...state,
        errorMessage: action.payload.toString()
      }
    case IS_LOADED_GLOBAL:
      return {
        ...state,
        isLoaded: action.payload
      }
    case SET_ACTIVE_SORT:
      return {
        ...state,
        sort: action.payload
      }
    case SET_ACTIVE_FILTER:
      return {
        ...state,
        filters: action.payload
      }
    case SET_PAGE:
      return {
        ...state,
        page: action.payload
      }
    case SET_PREV_URL:
      return {
        ...state,
        prevURL: action.payload
      }
    default:
      return state;
  }
};

export default Global;
