import React, {useEffect, useMemo, useRef, useState} from "react";
import {IMaskInput} from "react-imask";
import { IS_DON_UGLI } from "@/utils/env.js"

export const MyInputComponent = (props) => {
  const { signed, value, onEnter, onBlur, onChange, onTab } = props
  let sign = signed || true
  const [ tempValue, updateTempValue ] = useState(value)
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.select()
  },[])
  const valuePrepared = useMemo(() => !tempValue ? 0 : tempValue,[tempValue])
  return <IMaskInput value={valuePrepared.toString()}
                     mask={Number}
                     scale={2}
                     unmask={true}
                     thousandsSeparator={" "}
                     padFractionalZeros={true}
                     signed={true}
                     normalizeZeros={true}
                     radix="."
                     inputRef={el => inputRef.current = el}
                     onKeyUp={e => {
                       if (e.keyCode === 13) {
                         e.target.blur()
                         onEnter(+tempValue || 0)
                       } else if (e.key === 'tab') {
                         onTab()
                       }
                     }
                     }
                     onBlur={onBlur}
                     onAccept={(v, inputMask, inputEvent) => {
                       console.log('onAccept', v)
                       let vPrep = IS_DON_UGLI && v.startsWith('-') ? v.substr(1) : v
                       updateTempValue(Number.isNaN(vPrep) ? 0 : vPrep)}
                     }/>
}
