import moment from "moment";
import {CrmService} from "@/api/crm-orders";
import {formatPhoneNumber} from "@/utils/format-phone";

export const getOrderMessage = ({ id, status, isClient }) => {
  let successMessage
  switch (status) {
    case 'new':
      if (isClient) {
        successMessage = 'Заказ успешно создан и находится на рассмотрении у менеджера Тата-газа.\nПосле расмотрения заявки вы получите Приложение и счет на почту.'
      } else {
        successMessage = 'Новый заказ успешно создан'
      }
      break
    case 'on-payment':
      successMessage = `Заказ №${id} ожидает оплаты`
      break
    case 'pass-registration':
      if (isClient) {
        successMessage = `Заказ №${id}: Заявка на пропуск успешно отправлена \\nи ожидает утверждения от диспетчера Тата-газа`
      } else {
        successMessage = `Заказ №${id} ожидает отгрузки`
      }
      break
    case 'cancelled':
      successMessage = `Заказ №${id} отменен`
      break
    default:
      successMessage = null
  }

  return successMessage
}

export const mapOrderToFormData = order => {
  const orderPrepared = Object.fromEntries(
    Object.entries(order).map( ([key, value ]) => {
      if (key.toLowerCase().includes('datetime') || key === 'powerOfAttorneyDate' || key === 'productPassportDate') {
        return [key, value ? moment(value) : null]
      } else return [key, value ]
    })
  )

  return orderPrepared
}

export const mapFormToOrder = form => {
  const values = form.getFieldsValue()
  const valuesPrepared = Object.fromEntries(
    Object.entries(values).map( ([key, value]) => {
      if (value && (typeof value.format === 'function')) {
        if (key === 'powerOfAttorneyDate' || key === 'productPassportDate') {
          return [key, value.format('YYYY-MM-DD')]
        } else {
          return [key, value.format('YYYY-MM-DD HH:mm')]
        }
      } else return [key, value]
    })
  )
  console.log('mapFormToOrder', valuesPrepared)
  return valuesPrepared
}

export const mergeOrderWithExtraFields = (order,extraFields) => {
  return Object.fromEntries(
    Object.entries(order).map( ([key, value]) => {
      if (value === null || value === undefined) return [key,extraFields[key] || null]
      else return [key,value]
    })
  )
}

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export const disabledDateTime = {
  disabledHours: () => range(0, 24).splice(4, 20),
  disabledMinutes: () => range(30, 60),
  disabledSeconds: () => [55, 56],
}

export const cutSpace = str => (typeof str === 'string') && str.replace(/\s/g,'') || ''

export const processCarNumber = (value) => {
  return cutSpace(value).toUpperCase()
}
export const prepareCarNumberValue = v => v.toUpperCase()
export const mapSimpleValueToAutocomplete = value => ({label: value, value})

const mapAutocompleteResponse = values => values.map(mapSimpleValueToAutocomplete)

export const CrmAutocomplete = {
  productPassportNumber: search => CrmService.autocomplete('productPassportNumber', search)
    .then(mapAutocompleteResponse),
  passDriverPhone: search => CrmService.autocomplete('passDriverPhone', search)
    .then( values => values.map( value => ({ label: formatPhoneNumber(value), value })) ),
  passCarModel: search => CrmService.autocomplete('passCarModel', search)
    .then(mapAutocompleteResponse),
  passCarNumber: search => CrmService.autocomplete('passCarNumber', search)
    .then(mapAutocompleteResponse),
  passTrailerNumber: search => CrmService.autocomplete('passTrailerNumber', search)
    .then(mapAutocompleteResponse),
  passDriverName: search => CrmService.autocomplete('passDriverName', search)
    .then(mapAutocompleteResponse),
  passDriverLicenseNumber: search => CrmService.autocomplete('passDriverLicenseNumber', search)
    .then(mapAutocompleteResponse),
  consignee: search => CrmService.autocomplete('consignee', search)
    .then(mapAutocompleteResponse),
  carrier: search => CrmService.autocomplete('carrier', search)
    .then(mapAutocompleteResponse),
  unloadingPoint: search => CrmService.autocomplete('unloadingPoint', search)
    .then(mapAutocompleteResponse),
  customer: search => CrmService.autocomplete('customer', search)
    .then(mapAutocompleteResponse),
  payer: search => CrmService.autocomplete('payer', search)
    .then(mapAutocompleteResponse),
  powerOfAttorneyNumber: search => CrmService.autocomplete('powerOfAttorneyNumber', search)
    .then(mapAutocompleteResponse),
  powerOfAttorneyPerson: search => CrmService.autocomplete('powerOfAttorneyPerson', search)
    .then(mapAutocompleteResponse)
}
