import { service } from "@/api/service";

const API_HOST = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_HOST_API}`;

const getHeaders = () => ({
  Accept: "application/json",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})

//Counterparties
const getCounterparties = (filters, page, sort) => {
  return  service.get(`/counterparties`, {
    headers: getHeaders(),
    baseURL: API_HOST,
    params: {
      ...filters,
      ...sort,
      page,
    },
  }).then(r => r.data);
}

const getCounterparty = (id) => service.get(`/counterparties/${id}`, {
  headers: getHeaders(),
  baseURL: API_HOST,
}).then(r => r.data);

const editCounterparty = (id, data) => service.patch(`/counterparties/${id}`, data,
  {
    headers: getHeaders(),
    baseURL: API_HOST,
  }).then(r => r.data);

const addCounterparty = (data) => service.post(`/counterparties`, data,
  {
    headers: getHeaders(),
    baseURL: API_HOST,
  })
  .then(r => r.data);

const deleteCounterparty = (id) => service.delete(`/counterparties/${id}`, {
  headers: getHeaders(),
  baseURL: API_HOST,
}).then(r => r.data);

//Counterparties reports
const getCounterpartiesReport = (filters, id, sort) => service.get(`/counterparties/report`, {
  headers: getHeaders(),
  baseURL: API_HOST,
  params: {
    ...filters,
    ...sort,
    id,
  }
}).then(r => r.data);

//Type product reports
const getTypeProductReports = (filters, page, sort) => service.get(`/counterparties/type-products-report`, {
  headers: getHeaders(),
  baseURL: API_HOST,
  params: {
    ...filters,
    page,
    ...sort
  },
}).then(r => r.data);

//Selling price
const getSellingPrice = (filters, page, sort) => service.get(`/counterparties/selling-price`, {
  headers: getHeaders(),
  baseURL: API_HOST,
  params: {
    ...filters,
    page,
    ...sort
  },
}).then(r => r.data);

//Settings marks
const getSettingsMarks = (filters, page, sort) => service.get(`/products`, {
  headers: getHeaders(),
  baseURL: API_HOST,
  params: {
    ...filters,
    page,
    ...sort
  },
}).then(r => r?.data || r);

const getSettingsMark = id => service.get(`/products/${id}`, {
  headers: getHeaders(),
  baseURL: API_HOST,
}).then(r => r?.data || r);

const editSettingsMarks = (id, data) => service.post(`/products/${id}`, data, {
  headers: getHeaders(),
  baseURL: API_HOST,
}).then(r => r.data);

const addSettingsMarks = (data) => service.post(`/products`, data, {
  headers: getHeaders(),
  baseURL: API_HOST,
}).then(r => r.data);

const deleteSettingsMarks = (id) => service.delete(`/products/${id}`, {
  headers: getHeaders(),
  baseURL: API_HOST,
}).then(r => r.data);

//Nomenclatures
const getNomenclatures = () => service.get(`/nomenclatures`, {
  headers: getHeaders(),
  baseURL: API_HOST,
}).then(r => r?.data || r);

const getCounterpartySellingByMarks = (id, filters = {}) => service.get(`/widgets/counterpartySellingByMarks/${id}`, {
  headers: getHeaders(),
  baseURL: API_HOST,
  params: filters,
}).then(r => r?.data || r);

const getDebitCredit = (filters = {}) => service.get(`/widgets/debitCredit`, {
  headers: getHeaders(),
  baseURL: API_HOST,
  params: filters,
}).then(r => r?.data || r);

const getDebitCreditByID = (id, filters = {}) => service.get(`/widgets/debitCredit/${id}`, {
  headers: getHeaders(),
  baseURL: API_HOST,
  params: filters,
}).then(r => r?.data || r);

export const CounterpartiesAPI = {
  getCounterparties,
  getCounterparty,
  editCounterparty,
  addCounterparty,
  deleteCounterparty,
  getCounterpartiesReport,
  getTypeProductReports,
  getSellingPrice,
  getSettingsMarks,
  editSettingsMarks,
  addSettingsMarks,
  deleteSettingsMarks,
  getNomenclatures,
  getSettingsMark,
  getCounterpartySellingByMarks,
  getDebitCredit,
  getDebitCreditByID
};
