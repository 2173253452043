import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from '@/constants/ThemeConstant';
import { env } from './EnvironmentConfig'
import { IS_DON_UGLI, IS_RWSP } from "@/utils/env"


// TATA
import TATA_WELCOME_BG from "@/assets/wellcome-bg.jpg"
import TATA_LOGO_HORISONTAL from "@/assets/svg/logo-horisontal.svg"

// DON_UGLI
import DON_UGLI_WELCOME_BG from "@/assets/du_bg.jpg"
import DON_UGLI_LOGO from "@/assets/don-ugli.png"

// RWSP
import RWSP_WELCOME_BG from "@/assets/rwsp_bg.jpg"
import RWSP_LOGO from "@/assets/rwsp_logo.png"

console.log('IS_DON_UGLI', IS_DON_UGLI)

export const APP_NAME = process.env.REACT_APP_TITLE;
export const API_BASE_URL = env.API_ENDPOINT_URL

const getProjectBasedVariables = () => {
	if (IS_DON_UGLI) return {
		welcomeBg: DON_UGLI_WELCOME_BG,
		logo: DON_UGLI_LOGO,
		siderClassName: 'my-layout-sider--don-ugli',
		rootClassName: 'theme-don-ugli'
	}
	else if (IS_RWSP) return {
		welcomeBg: RWSP_WELCOME_BG,
		logo: RWSP_LOGO,
		siderClassName: 'my-layout-sider--rwsp',
		rootClassName: ''
	}
	else return {
			welcomeBg: TATA_WELCOME_BG,
			logo: TATA_LOGO_HORISONTAL,
			siderClassName: '',
			rootClassName: ''
	}
}

const { logo, rootClassName, siderClassName, welcomeBg } = getProjectBasedVariables()


export const THEME_CONFIG = {
	navCollapsed: true,
	navProfileCollapsed: true,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3573B3',
	headerNavColor: '#ffffff',
	mobileNav: false,
	welcomeBg,
	logo,
	siderClassName,
	rootClassName
};
