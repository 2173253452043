import moment from "moment";
import {COLORS} from "../../../../../constants/ChartConstant";
import {getFormattedValuteDefault, getFormattedValuteDefaultWithPostfix} from "../../../../../utils/numeral";

export const ViewTypes = [
  { name: 'Неделя', value: 'week' },
  { name: 'Месяц', value: 'month' }
]

export const getMonthFetchParams = (fromDate = new Date()) => {
  let todayMoment = moment(fromDate)
  let maxDate = todayMoment.format('YYYY-MM-DD')
  let startMonth = todayMoment.add(-30, "days")
  let minDate = startMonth.format('YYYY-MM-DD')
  return { maxDate, minDate }
}

export const getWeekFetchParams = (fromDate = new Date()) => {
  let todayMoment = moment(fromDate)
  return {
    maxDate: todayMoment.format('YYYY-MM-DD'),
    minDate: todayMoment.add(-6,'days' ).format('YYYY-MM-DD')
  }
}

export const getFetchParams = type => {
  switch (type) {
    case 'week': {
      const fetchParamsActual = getWeekFetchParams()
      const prevPeriod = getWeekFetchParams(moment(fetchParamsActual.minDate).add( -1, 'days').toDate())
      return {
        actual: fetchParamsActual,
        previous: prevPeriod
      }
    }
    case 'month': {
      const fetchParamsActual = getMonthFetchParams()
      const prevPeriod = getMonthFetchParams(moment(fetchParamsActual.minDate).add(-1, 'days').toDate())
      return {
        actual: fetchParamsActual,
        previous: prevPeriod
      }
    }
  }
}

const getMonthChartDates = () => {
  let categories = []
  let counter = 0
  let currentDay = moment(new Date()).add(-30, "days")
  while (counter < 30) {
    const dayString = currentDay.format('YYYY-MM-DD')
    categories.push(dayString)
    currentDay.add('days', 1)
    counter++
  }
  return categories
}

const getWeekChartDates = () => {
  let categories = []
  let counter = 0
  let todayMoment = moment(new Date())
  while (counter < 7) {
    const dayString = todayMoment.format('YYYY-MM-DD')
    categories.push(dayString)
    todayMoment.add('days', -1)
    counter++
  }
  return categories.reverse()
}

const getChartDays = type => {
  switch (type) {
    case 'week':
      return getWeekChartDates()
    case 'month':
      return getMonthChartDates()
  }
}

export const FinWidgetGetInitState = () => ({
  view: 'week', isLoading: true, chartOptions: null, summary: null, summaryPrev: null
})

export const getFormattedPeriod = (fetchParams) => {

  let momentStart = moment(fetchParams.minDate)
  let momentEnd = moment(fetchParams.maxDate)

  if (momentStart.year() !== momentEnd.year()) {
    return `${momentStart.format('DD.MM.YYYY')} - ${momentEnd.format('DD.MM.YYYY')}`
  } else if (momentStart.month() !== momentEnd.month()) {
    return `${momentStart.format('DD.MM')} - ${momentEnd.format('DD.MM')}, ${momentStart.year()}`
  } else {
    return `${momentStart.format('DD')} - ${momentEnd.format('DD')} ${momentStart.format('MMM YYYY')}`
  }

}

export const FinWidgetDataReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        isLoading: true,
        chartOptions: null,
        ...state
      }
    case "SUCCESS":
      return {
        isLoading: false,
        chartOptions: action.payload.chartOptions,
        summary: action.payload.summary,
        summaryPrev: action.payload.summaryPrev
      }
    case "ERROR":
      return {
        isLoading: false,
        chartOptions: null,
        ...state
      }
    default:
      throw new Error();
  }
}

export const getMapperOfChartApiPayloadToChartOptions = type => {
  return (chartData, chartDataInOut, MAX_GAS_VOLUME) => mapChartApiPayloadToChartOptions(type, chartData, chartDataInOut, MAX_GAS_VOLUME)
}

const mapChartApiPayloadToChartOptions = (type, chartData, chartDataInOut, MAX_GAS_VOLUME) => {


  const categories = getChartDays(type)


  const seriesInit = [
    {
      name: 'Тоннаж',
      type: 'bar',
        showBackground: true,
        backgroundStyle: {
        color: 'rgba(220, 220, 220, 0.8)'
      },

      areaStyle: {
        opacity: 1
      },
      data: [],
      barWidth: '80%',
      itemStyle: {
        color: COLORS[0]
      },
      z: 1
    },
    {
      connectNulls: true,
      smooth: true,
      areaStyle: {
        opacity: "0.5"
      },
      smoothMonotone: "x",
      name: 'Приход',
      type: 'line',
      symbolSize: 8,
      data: [],
      itemStyle: {
        color: COLORS[1]
      },
      z:2
    },
    {areaStyle: {
        opacity: "0.5"
      },
      smooth: true,
      smoothMonotone: "x",
      name: 'Расход',
      type: 'line',
      symbolSize: 8,
      data: [],
      roundCap: true,
      barWidth: '40%',
      //barGap: '-100%',
      itemStyle: {
        color: COLORS[2]
      },
      z: 3
    },
    {
      name: 'Сумма',
      type: 'line',
      roundCap: true,
      yAxisIndex: 1,
      barWidth: 0,
      data: [],
      lineStyle: {
        opacity: 0
      },
      itemStyle: {
        color: COLORS[3],
        opacity: 0
      }
    },
    {
      name: 'Сумма приход',
      type: 'line',
      roundCap: true,
      yAxisIndex: 1,
      barWidth: 0,
      data: [],
      lineStyle: {
        opacity: 0
      },
      itemStyle: {
        color: COLORS[3],
        opacity: 0
      }
    },
    {
      name: 'Сумма расход',
      type: 'line',
      roundCap: true,
      yAxisIndex: 1,
      barWidth: 0,
      data: [],
      lineStyle: {
        opacity: 0
      },
      itemStyle: {
        color: COLORS[3],
        opacity: 0
      }
    },
  ]

  return {
    tooltip: {
      trigger: 'axis',
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      backgroundColor: '#FFF',
      formatter: function(params, values) {
        const [tonnageData, inData, outData, sumData, sumInData, sumOutData] = params

        const dateFormatted = moment(tonnageData.axisValue).format('DD.MM.YYYY')
        const tonnageMarker = tonnageData.marker
        const inMarker = inData.marker
        const outMarker = outData.marker
        const sumMarker = sumData.marker

        const isExistSumValue = tonnageData && (tonnageData.value === 0 || (tonnageData.value !== 0 && sumData && sumData.value !== 0))

        return `
          <span>${dateFormatted}</span>
          <br/>
          <span>${tonnageMarker}Остаток: ${getFormattedValuteDefault(tonnageData.value)} (${isExistSumValue ? getFormattedValuteDefaultWithPostfix(sumData.value,'RUB') : '-'})</span>
          <br/>
          <span>${inMarker}Приход: ${getFormattedValuteDefault(inData.value)} (${getFormattedValuteDefaultWithPostfix(sumInData.value,'RUB')})</span>
          <br/>
          <span>${outMarker}Расход: ${getFormattedValuteDefault(outData.value)} (${getFormattedValuteDefaultWithPostfix(sumOutData.value,'RUB')})</span>
        `
      },
      textStyle: {
        color: '#000'
      },
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      show: false,
      borderWidth: 0,
      right: 0,
      bottom: '10%',
      top: 0,
      left: 0
    },
    xAxis: [
      {
        type: 'category',
        data: categories,
        axisTick: {
          show: true
        },
        axisLine: {
          lineStyle: {
            color: 'rgb(224, 224, 224)'
          }
        },
        axisLabel: {
          show: true,
          color: '#455560',
          formatter: function(val) {
            let str = moment(val).format('DD MMM')
            return str.substr(0,str.length - 1).split(' ').join('\n')
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: '',
        show: true,
        min: 0,
        splitNumber: 5,
        max: MAX_GAS_VOLUME,
        interval: 50,
        axisLine: {show: false},
        splitLine: {
          show: false,
          lineStyle: {
            color: '#edf2f9'
          }
        },
        axisTick: {show: false },
        label: { show: false, color: "#455560" },
        labels: { show: true }
      },
      {
        type: 'value',
        name: 'sum',
        show: false,
        min: 'dataMin',
        max: 'dataMax',
        axisTick: {show: false },
        label: { show: false },
        labels: { show: false }
      },
    ],
    series: categories.reduce((acc, dateString) => {
      acc[0].data.push(Math.round(chartData[dateString].tonnage * 100) / 100)
      acc[1].data.push(
        chartDataInOut[dateString] &&
        chartDataInOut[dateString].in &&
        (Math.round(chartDataInOut[dateString].in.tonnage * 100) / 100) || 0
      )
      acc[2].data.push(
        chartDataInOut[dateString] &&
        chartDataInOut[dateString].out &&
        (Math.round(chartDataInOut[dateString].out.tonnage * 100) / 100) || 0
      )
      acc[3].data.push(chartData[dateString].sum)
      acc[4].data.push(
        chartDataInOut[dateString] &&
        chartDataInOut[dateString].in &&
        (Math.round(chartDataInOut[dateString].in.sum * 100) / 100) || 0
      )
      acc[5].data.push(
        chartDataInOut[dateString] &&
        chartDataInOut[dateString].out &&
        (Math.round(chartDataInOut[dateString].out.sum * 100) / 100) || 0
      )
      return acc
    }, seriesInit)
  }
}
