import React from 'react';
import PropTypes from 'prop-types';

export const SortCell = ({column, className, callback, sortState, setSortState}) => {

    const onClick = () => {
        if (sortState?.sortField === column.value) {
            switch (sortState?.sortDirection) {
                case ("ASC"): setSortState({sortDirection: "DESC", sortField: column.value}); break;
                case ("DESC"): setSortState({sortDirection: "", sortField: column.value}); break;
                default: setSortState({sortDirection: "ASC", sortField: column.value}); break;
            }
        } else {
            setSortState({sortDirection: "ASC", sortField: column.value});
        }

        // setOrder(order === "ASC" ? "DESC" : "ASC");
    }


    return (
        <th onClick={onClick}
            className={`${className} cursor-pointer  ${column?.position}`}>
            <div className={"flex items-center"}>
                <span className={"flex-grow select-none"}>{column?.name || column}</span>
                <div className={"flex flex-col items-center ml-2"}>
                    <svg className={`inline float-right ${sortState?.sortDirection === "DESC" && column.value === sortState.sortField && "invisible"}`} width="8" height="8"
                         viewBox="0 0 8 8" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4 0L8 6H0L4 0Z" fill="#373A3C"/>
                        {/*<path fillRule="evenodd" clipRule="evenodd" d="M4 16L8 10H0L4 16Z" fill="#373A3C"/>*/}
                    </svg>
                    <svg className={`inline float-right ${sortState?.sortDirection === "ASC" && column.value === sortState.sortField && "invisible"}`} width="8" height="8"
                         viewBox="0 0 8 8" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        {/*<path fillRule="evenodd" clipRule="evenodd" d="M4 0L8 6H0L4 0Z" fill="#373A3C"/>*/}
                        <path fillRule="evenodd" clipRule="evenodd" d="M4 8L8 2H0L4 8Z" fill="#373A3C"/>
                    </svg>
                </div>
            </div>
        </th>
    );
};

SortCell.propTypes = {}
