import React, {useMemo} from 'react'
import {OrderBlockTitle} from "./order-block-title"
import {connect} from "react-redux";
import {Col, DatePicker, Divider, Form, Row} from "antd";
import {MaskedInput} from "@/components/masked-input";
import {CrmAutocomplete, mapSimpleValueToAutocomplete} from "@/views/app-views/crm/requisitions/order-form/utils";
import {CrmService} from "@/api/crm-orders";
import {formatPhoneNumber} from "@/utils/format-phone";
import {InputAutocomplete} from "@/components/shared-components/input-autocomplete";
import {PhoneNumberAutocomplete} from "@/components/shared-components/phone-number-autocomplete.js";




const OrderPassRegistrationClientBlock = ({ order }) => {

  const fieldsAreDisabled = useMemo(() => {
    if (!order) return null
    return ['on-shipment','shipped','cancelled'].includes(order.status)
  },[order])

  return <div className={"mb-4"}>
    { order && <OrderBlockTitle status={order.status} title={"оформление пропуска"}
                                dateTime={order.created}
                                highlight={['paid', 'pass-registration'].includes(order.status)}/>}

    <div className={"p-3"} style={{backgroundColor: 'white', borderRadius: '0.625rem'}}>
      <Row gutter={16}>
        <Col sm={24}>
          <h4 className={"mb-4"}>Авто</h4>
        </Col>
        <Col sm={8}>
          <Form.Item label={'Модель'} name={"passCarModel"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.passCarModel} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item label={'Гос номер'} name={"passCarNumber"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.passCarNumber} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item label={'Гос номер ППЦ'} name={"passTrailerNumber"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.passTrailerNumber} />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Divider/>
          <h4 className={"mb-4"}>Водитель</h4>
        </Col>
        <Col sm={8}>
          <Form.Item label={'ФИО'} name={"passDriverName"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.passDriverName} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item label={'Телефон'} name={"passDriverPhone"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <PhoneNumberAutocomplete size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.passDriverPhone}/>
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item label={'№ В/У'} name={"passDriverLicenseNumber"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <MaskedInput mask={"** ** 000000"} size={"small"} disabled={fieldsAreDisabled} />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Divider/>
          <h4 className={"mb-4"}>Данные по отгрузке</h4>
        </Col>
        <Col sm={8}>
          <Form.Item label={'Грузополучатель'} name={"consignee"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.consignee} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item label={'Плательщик'} name={"payer"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.payer} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item label={'Перевозчик'} name={"carrier"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.carrier} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item label={'Пункт разгрузки'} name={"unloadingPoint"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.unloadingPoint} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item label={'Заказчик'} name={"customer"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.customer} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item label={'№ Доверенности'} name={"powerOfAttorneyNumber"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.powerOfAttorneyNumber} />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item label={'Дата доверенности'} name={"powerOfAttorneyDate"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}>
            <DatePicker size={"small"}
                        disabled={fieldsAreDisabled}
                        style={{width: '100%'}}
                        format="DD.MM.YYYY"
            />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item label={'ФИО Довер. лица'} name={"powerOfAttorneyPerson"}
                     rules={[{ required: true, message: 'Поле обязательно!' }]}
          >
            <InputAutocomplete prefetch={true} size={"small"} disabled={fieldsAreDisabled} fetchMethod={CrmAutocomplete.powerOfAttorneyPerson} />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item
            label="Дата отгрузки (план)"
            name="shipmentPlanDateTime"
            rules={[{ required: true, message: 'Обязательно' }]}
          >
            <DatePicker size={"small"}
                        style={{width: '100%'}}
                        format="DD.MM.YYYY HH:mm"
                        disabled={fieldsAreDisabled}
                        showTime={{
                          showNow: false,
                          disabledHours: (hour) => {
                            return [0,1,2,3,4,5,6,7,8,9,18,19,20,21,22,23]
                          },
                          hideDisabledOptions: true,
                          minuteStep: 60
                        }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  </div>
}

const mapStateToProps = ({ auth }) => {
  const { authData } = auth;
  const isClient = authData && authData.groups && authData.groups.includes('clients')
  return { isClient }
};

export default connect(mapStateToProps, {})(OrderPassRegistrationClientBlock)
