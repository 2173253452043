import {
  IS_LOADED_GLOBAL,
  SET_ACTIVE_FILTER,
  SET_ACTIVE_SORT,
  SET_ERROR_MESSAGES, SET_PAGE, SET_PREV_URL,
  SET_SUCCESS
} from "@/redux/constants/global";


export const setSuccess = (payload) => {
  return {
    type: SET_SUCCESS,
    payload: payload
  }
};

export const setErrorMessage = (string) => {
  return {
    type: SET_ERROR_MESSAGES,
    payload: string
  }
};

export const setIsLoadedGlobal = (bool) => {
  return {
    type: IS_LOADED_GLOBAL,
    payload: bool
  }
};

export const setSort = (obj) => {
  return {
    type: SET_ACTIVE_SORT,
    payload: obj
  }
};

export const setFilters = (obj) => {
  return {
    type: SET_ACTIVE_FILTER,
    payload: obj
  }
};

export const setPage = (num) => {
  return {
    type: SET_PAGE,
    payload: num
  }
};

export const setPrevUrl = (url) => {
  return {
    type: SET_PREV_URL,
    payload: url
  }
};