import React from 'react';
import moment from "moment";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import {
  fetchGetCounterparty,
  fetchGetCounterpartyReport,
  fetchGetSellingPrices,
  fetchSailingMarksChart,
  fetchDebitCreditChart, setCounterpartyFilters
} from "@/redux/actions/Counterparties";
import { Col, Row } from "antd";
import { InfoLayout } from "@/components/layout-components/InfoLayout/InfoLayout";
import { TitlePage } from "@/components/shared-components/TitlePage/TitlePage";
import { TableRowDropdown } from "@/components/table-components/Rows/TableRowDropdown";
import TableCore from "@/components/table-components/TableCore";
import { Input } from "@/components/forms/Inputs/Input";
import './Counterparty.scss';
import { setErrorMessage, setIsLoadedGlobal } from "@/redux/actions/global";
import LoaderWrapper from "@/components/util-components/LoaderWrapper/LoaderWrapper";
import { saveTableAsFile } from "@/utils/saveTable";
import { withToast } from "@/hoc/withToast";
import { TableRowCounterpartiesReports } from "@/components/table-components/Rows/TableRowCounterpartiesReports";
import { getRangeFilters } from "@/helpers/HelperDate";
import { getFiltersFromUrl } from "@/helpers/HelpersFilters";

class Counterparty extends React.Component {

  constructor(props) {
    super(props);
    this.typesFilters = null;
    this.creditFilters = null;

    this.triggerData = {
      name: {
        Component: TableRowCounterpartiesReports,
        childKey: "nameArray"
      }
    };

    this.headers = [
      {
        value: "name",
        name: "Название",
      },
      {
        value: "debitStart",
        name: "Задолженность покупателя на начало периода, руб.",
      },
      {
        value: "creditStart",
        name: "Задолженность перед покупателем на начало периода, руб.",
      },
      {
        value: "loaded",
        name: "Отгружено тонн.",
      },
      {
        value: "sold",
        name: "Продано, руб.",
      },
      {
        value: "receivedBuyers",
        name: "Поступило от покупателей, руб.",
      },
      {
        value: "debitEnd",
        name: "Задолженность покупателя на конец периода, руб.",
      },
      {
        value: "creditEnd",
        name: "Задолженность перед покупателем на конец периода, руб.",
      },
    ];

    this.blueprint = {
      data: [
        {
          "label": "Категория",
          "value": ["role"]
        },
        {
          "label": "Вид контрагента",
          "value": ["type"]
        },
        {
          "label": "Название",
          "value": ["name"],
          "hint": "fullName",
          "hintType": "value"
        },
        {
          "label": "Страна регистрации",
          "value": ["country"]
        },
        {
          "label": "ИНН",
          "value": ["inn"]
        },
        {
          "label": "КПП",
          "value": ["kpp"]
        },
        {
          "label": "ОГРН",
          "value": ["ogrn"]
        },
        {
          "label": "Счет",
          "value": ["checkingAccount", "correspondentAccount"],
          "className": "flex flex-col"
        },
        {
          "label": "Юр.адрес",
          "value": ["address"],
          "hint": "realAddress",
          "hintType": "compare",
          "hintTrue": "Совпадает с фактическим",
          "hintFalse": ""
        },
        {
          "label": "Генеральный директор",
          "value": ["ceo"]
        },
        {
          "label": "Телефон",
          "value": ["phone"]
        }
      ]
    }

    this.id = this.props.match.params.id;

  }

  callbackFilters = filters => {
    // this.props.setIsLoadedGlobal(false);
    this.props.setCounterpartyFilters({ common: undefined, table: filters });
    this.props.fetchGetCounterpartyReport({ filters, id: this.id });
  };

  componentDidMount() {
    const keysArray = ["common", "debit", "pie", "table"];
    // this.props.fetchGetCounterpartyReport({ filters: getFiltersFromUrl(keysArray).table, id: this.id });
    // this.props.fetchGetSellingPrices({ filters: getFiltersFromUrl(keysArray).pie, id: this.id });
    // this.props.fetchSailingMarksChart({ id: this.id, filters: getFiltersFromUrl(keysArray).debit })
    // this.props.fetchDebitCreditChart({ id: this.id, filters: getFiltersFromUrl(keysArray).debit });
    // this.props.setCounterpartyFilters(getFiltersFromUrl(keysArray));
    this.props.fetchGetCounterparty(this.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.counterpartyFilters !== this.props.counterpartyFilters) {
      if (this.props.counterpartyFilters) {
        const paramsObj = { ...this.props.counterpartyFilters };
        let filters = [];
        Object.keys(paramsObj).forEach(item => {
          if (paramsObj[item]) {
            filters.push(`${item}StartDate=${paramsObj[item].startDate}`);
            filters.push(`${item}EndDate=${paramsObj[item].endDate}`);
          }
        });

        filters = filters.filter(item => item);
        this.props.history.replace({
          search: "?" + filters.join("&")
        });

      }
    }
  }

  callbackFiltersPie = filters => {
    if (filters?.startDate === undefined) {
      filters = undefined;
    } else {
      filters = getRangeFilters(filters);
    }
    this.props.setCounterpartyFilters({ common: undefined, pie: filters });
    this.props.fetchSailingMarksChart({ id: this.id, filters })
  }

  callbackFiltersDebit = filters => {
    if (filters?.startDate === undefined) {
      filters = undefined;
    } else {
      filters = getRangeFilters(filters);
    }
    this.props.setCounterpartyFilters({ common: undefined, debit: filters });
    this.props.fetchDebitCreditChart({ id: this.id, filters });
  };

  chartsCallbackFilter = filters => {

    if (filters?.startDate === undefined) {
      filters = undefined;
    } else {
      filters = getRangeFilters(filters);
    }

    this.props.fetchSailingMarksChart({ id: this.id, filters });

    this.props.fetchDebitCreditChart({ id: this.id, filters });

    this.props.fetchGetCounterpartyReport({ filters, id: this.id });

    this.props.setCounterpartyFilters({ common: filters, debit: filters, pie: filters, table: filters });
  };

  clearFilters = () => {
    this.props.setCounterpartyFilters({ common: undefined, table: undefined });
    this.props.fetchGetCounterpartyReport({ filters: {} });
  }

  setURL = () => {
    let prevURL = this.props.prevURL;
    if ((prevURL.includes("counterparties") || prevURL.includes("history")) && !prevURL.includes("edit")) {
      return prevURL
    } else {
      return '/counterparties'
    }
  }

  render() {

    let {
      counterparty,
      counterpartyReports,
      debitCredit,
      fetchGetCounterpartyReport,
      isLoaded,
      counterpartySellingPrices,
      counterpartyFilters
    } = this.props;

    if (counterpartyFilters?.table) {
      counterpartyFilters.table.id = this.id;
    }

    return (

      <div className={"counterparty-page"}>
        {/*        {errorMessage && <Redirect to={{
          pathname: "/counterparties",
        }} />}*/}

        <TitlePage title={"Карточка контрагента"}
                   className={"mr-auto"} />
        <div className={"py-6 flex flex-column min-w-full sm:px-2.5 lg:px-8"}>

          <LoaderWrapper condition={isLoaded}>

            <div className={"flex items-start"}>
              <InfoLayout blueprint={this.blueprint}
                          items={counterparty}
                          className={"my-8 flex-1"}
                          closeButton={this.setURL()}
                          editButton={`/counterparties/edit/${this.props.match.params.id}`}
                          userId={this.props.match.params.id} />

            {/*  <div style={{ paddingLeft: "16px", maxWidth: "650px", flex: "1" }}>*/}
            {/*    <Row>*/}
            {/*      <Col className={"w-full"}>*/}
            {/*        <ChartFilter filterCallback={this.chartsCallbackFilter}*/}
            {/*                     value={counterpartyFilters?.common} />*/}
            {/*      </Col>*/}
            {/*      <Col className={"w-full"}>*/}
            {/*        <EChartLines signs={["Руб", "Тонн"]}*/}
            {/*                     filterCallback={this.callbackFiltersDebit}*/}
            {/*                     data={[firstMultiLinesData]}*/}
            {/*                     toggleNames={["Деньги", "Тонны"]}*/}
            {/*                     xAxis={debitCredit?.dates || []}*/}
            {/*                     height={300}*/}
            {/*                     showFilter={true}*/}
            {/*                     value={counterpartyFilters?.debit}*/}
            {/*                     title={"Дебиторская и кредиторская задолженности"} />*/}
            {/*      </Col>*/}
            {/*    </Row>*/}

            {/*    <Row>*/}
            {/*      <Col className={"w-full"}>*/}
            {/*        <EChartPie signs={["Тонн"]}*/}
            {/*                   filterCallback={this.callbackFiltersPie}*/}
            {/*                   data={[counterpartySellingPrices?.tons]}*/}
            {/*                   toggleNames={["Тонны"]}*/}
            {/*                   height={300}*/}
            {/*                   showFilter={true}*/}
            {/*                   useToggle={false}*/}
            {/*                   value={counterpartyFilters?.pie}*/}
            {/*                   title={"Продажи по сортам"}*/}
            {/*                   subtitle={"В тоннах"} />*/}
            {/*      </Col>*/}
            {/*      <Col className={"w-full"}>*/}
            {/*        <EChartPie signs={["Руб"]}*/}
            {/*                   data={[counterpartySellingPrices?.money]}*/}
            {/*                   toggleNames={["Деньги"]}*/}
            {/*                   height={300}*/}
            {/*                   useToggle={false}*/}
            {/*                   title={"В рублях"} />*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*  </div>*/}

            {/*</div>*/}

            {/*<div className={"flex flex-col items-end my-10"}>*/}
            {/*  <TableCore headers={this.headers}*/}
            {/*             rows={counterpartyReports}*/}
            {/*             title={"Отчет по контрагенту"}*/}
            {/*             filters={tableFilters}*/}
            {/*             props={*/}
            {/*               {*/}
            {/*                 triggerData: this.triggerData,*/}
            {/*                 defaultOpen: "name",*/}
            {/*                 disableToggle: true,*/}
            {/*                 hideControls: true*/}
            {/*               }*/}
            {/*             }*/}
            {/*             Component={TableRowDropdown}*/}
            {/*             canDelete={false}*/}
            {/*             canEdit={false}*/}
            {/*             getData={fetchGetCounterpartyReport}*/}
            {/*             canView={false}*/}
            {/*             className={"mb-6 self-stretch"}*/}
            {/*  />*/}
            </div>

          </LoaderWrapper>

        </div>
      </div>

    );
  }
}

Counterparty.propTypes = {};

const mapStateToProps = ({ counterparties, global }) => (
  {
    sellingPrices: counterparties.sellingPrices,
    counterparty: counterparties.counterparty,
    counterpartySellingPrices: counterparties.counterpartySellingPrices,
    counterpartyReports: counterparties.counterpartyReports?.items || [],
    isSuccess: global.isSuccess,
    errorMessage: global.errorMessage,
    isLoaded: global.isLoaded,
    debitCredit: counterparties.debitCredit,
    counterpartyFilters: counterparties.counterpartyFilters
  }
);

const mapDispatchToProps = dispatch => ({
  fetchGetSellingPrices: e => dispatch(fetchGetSellingPrices(e)),
  fetchGetCounterpartyReport: e => dispatch(fetchGetCounterpartyReport(e)),
  fetchGetCounterparty: e => dispatch(fetchGetCounterparty(e)),
  setCounterpartyFilters: e => dispatch(setCounterpartyFilters(e)),
  setErrorMessage: e => dispatch(setErrorMessage(e)),
  setIsLoadedGlobal: e => dispatch(setIsLoadedGlobal(e)),
  fetchSailingMarksChart: e => dispatch(fetchSailingMarksChart(e)),
  fetchDebitCreditChart: e => dispatch(fetchDebitCreditChart(e)),
});

export default withToast(connect(mapStateToProps, mapDispatchToProps)(Counterparty));
