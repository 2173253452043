import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { OrderBlockTitle } from "./order-block-title"
import { ClientsApi } from "@/api/clients";
import { connect } from "react-redux";
import { Divider, Col, DatePicker, Form, Input, Row } from "antd";
import { InputSelectRemote } from "@/components/shared-components/input-select-remote";
import { CustomInputNumber } from "@/components/shared-components/input-number";
import { getFormattedValuteDefault } from "@/utils/numeral";
import { LoadingOutlined } from "@ant-design/icons";
import { InputAutocomplete } from "@/components/shared-components/input-autocomplete";
import { CrmAutocomplete } from "@/views/app-views/crm/requisitions/order-form/utils";

const OrderMainBlock = ({ order, isClient, maxTonnage, tonnageTooltipVisible, gasPriceIsLoading }) => {

	const [tooltipVisibility, setTooltipVisibility] = useState(false)

	useEffect(() => {
		setTooltipVisibility(tonnageTooltipVisible)
	}, [tonnageTooltipVisible])

	const fetchClients = useCallback(() => ClientsApi.autocomplete().then(clients =>
		clients.map(({ id, name }) => ({ value: id, label: name }))
	), [])

	const maxTonnageAmountMessage = useMemo(() =>
			`макс. ${getFormattedValuteDefault(maxTonnage, true)} т.`
		, [maxTonnage])

	const tonnageTooltipProps = useMemo(() => {
		console.log('tonnageTooltipProps', tooltipVisibility)
		return {
			visible: tooltipVisibility,
			title: maxTonnageAmountMessage,
			trigger: 'hover',
			onVisibleChange: function (vis) {
				console.log('onVisibleChange', vis)
				setTooltipVisibility(vis)
			}
		}
	}, [tooltipVisibility, maxTonnageAmountMessage])

	return <div className={"mb-4"}>
		{order && <OrderBlockTitle status={'new'} title={"Заказ создан"} dateTime={order.created}
		                           highlight={order.status === 'new'} />}
		<div className={"p-3"} style={{ backgroundColor: 'white', borderRadius: '0.625rem' }}>
			<Row gutter={16}>
				<Col sm={7}>
					<Form.Item
						label="Клиент"
						name="clientId"
						rules={[{ required: true, message: 'Выберите клиента' }]}
					>
						<InputSelectRemote disabled={isClient || !!order} size={"small"} prefetch={true}
						                   style={{ width: '100%' }} fetch={fetchClients} />
					</Form.Item>
				</Col>
				<Col sm={4}>
					<Form.Item
						label="Кол-во"
						name="tonnage"
						trigger={'onBlur'}
						getValueFromEvent={event => {
							return event
						}}
						rules={[{ required: true, message: 'Введите тоннаж' }]}
					>
						<CustomInputNumber suffix={'т.'}
						                   disabled={order && (order.status !== 'new' || isClient)}
						                   size={"small"}
						                   style={{ width: '100%' }}
						                   min={0} />
					</Form.Item>
				</Col>
				<Col sm={1} className={"justify-content-end"}>
					<div style={{ height: '1.9875rem', marginBottom: '25px', textAlign: "center" }}>
						<h5>x</h5>
					</div>
				</Col>
				<Col sm={5}>
					<Form.Item
						label="Цена/ед."
						name="price"
						trigger={'onBlur'}
						getValueFromEvent={event => {
							return event
						}}
					>
						<CustomInputNumber suffix={gasPriceIsLoading ? (<LoadingOutlined spin />) : 'руб.'}
						                   size={"small"}
						                   style={{ width: '100%' }}
						                   disabled={isClient || (order && order.status !== 'new')}
						/>
					</Form.Item>
				</Col>
				<Col sm={1} className={"justify-content-end"}>
					<div style={{ height: '1.9875rem', marginBottom: '25px', textAlign: "center" }}>
						<h5>=</h5>
					</div>
				</Col>
				<Col sm={6}>
					<Form.Item
						label="Итого"
						name="sum"
					>
						<CustomInputNumber suffix={'руб.'} size={"small"}
						                   style={{ width: '100%' }}
						                   disabled
						/>
					</Form.Item>
				</Col>
			</Row>

			{
				!isClient && <> <Divider /> <Row gutter={16}>
					{/*<Col sm={6}>*/}
					{/*	<Form.Item*/}
					{/*		label="Пасп-т кач-ва прод."*/}
					{/*		name="productPassportNumber"*/}
					{/*		rules={[{ required: true, message: 'Поле обязательно' }]}*/}
					{/*	>*/}
					{/*		<InputAutocomplete size={"small"} disabled={order && order.status !== 'new'} prefetch={true}*/}
					{/*		                   fetchMethod={CrmAutocomplete.productPassportNumber} />*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}
					{/*<Col sm={6}>*/}
					{/*	<Form.Item*/}
					{/*		label="Дата выдачи пасп-та"*/}
					{/*		name="productPassportDate"*/}
					{/*		rules={[{ required: true, message: 'Поле обязательно' }]}*/}
					{/*	>*/}
					{/*		<DatePicker size={"small"}*/}
					{/*		            style={{ width: '100%' }}*/}
					{/*		            format="DD.MM.YYYY"*/}
					{/*		            disabled={order && order.status !== 'new'}*/}
					{/*		/>*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}
					<Col sm={6}>
						<Form.Item
							label="№ документов"
							name="annexNumber"
							rules={[{ required: true, message: 'Поле обязательно' }]}
						>
							<Input size={"small"}
							       disabled={order && order.status !== 'new'} />
						</Form.Item>
					</Col>
					{/*<Col sm={6}>*/}
					{/*	<Form.Item*/}
					{/*		label="Марка газа"*/}
					{/*		name="productGrade"*/}
					{/*		rules={[{ required: true, message: 'Поле обязательно' }]}*/}
					{/*	>*/}
					{/*		<Input size={"small"}*/}
					{/*		       disabled={order && order.status !== 'new'} />*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}
				</Row> </>
			}
		</div>
	</div>
}

const mapStateToProps = ({ auth }) => {
	const { authData } = auth;
	const isClient = authData && authData.groups && authData.groups.includes('clients')
	return { isClient }
};

export default connect(mapStateToProps, {})(OrderMainBlock)
