import React from 'react';
import PropTypes from 'prop-types';
import { TextBubble } from "@/components/util-components/TextBubble/TextBubble";
import { IconCell } from "@/components/table-components/Cell/IconCell/IconCell";
import { Link } from "react-router-dom";

export const TableRowCounterpartiesReports = ({
  data,
  className,
  canView,
  canEdit,
  canDelete,
  triggers,
  callback,
  headers,
  hideControls,
  ...props
}) => {

  const { id, name, debitStart, creditStart, loaded, sold, receivedBuyers, debitEnd, creditEnd } = data

  return (
    <tr className={"children:px-2.5 children:py-3.5 children:whitespace-nowrap bg-white"}>
      <td>
        <div className="text-base leading-5 font-normal text-gray-900 z-30 pl-3">
          {name}
        </div>
      </td>
      <td>
        <div className="text-base leading-5 font-normal text-gray-900">
          {debitStart}
        </div>
      </td>
      <td>
        <div className="text-base leading-5 font-normal text-gray-900">
          {creditStart}
        </div>
      </td>
      <td>
        <div className="text-base leading-5 font-normal text-gray-900">
          {loaded}
        </div>
      </td>
      <td>
        <div className="text-base leading-5 font-normal text-gray-900">
          {sold}
        </div>
      </td>
      <td>
        <div className="text-base leading-5 font-normal text-gray-900">
          {receivedBuyers}
        </div>
      </td>
      <td>
        <div className="text-base leading-5 font-normal text-gray-900">
          {debitEnd}
        </div>
      </td>
      <td>
        <div className="text-base leading-5 font-normal text-gray-900">
          {creditEnd}
        </div>
      </td>

      {
        !hideControls ? (canDelete || canEdit || canView) ?
          <IconCell id={id} canDelete={canDelete} canEdit={canEdit} canView={canView} />
          : <td></td>
          : null

      }
    </tr>
  );
};

TableRowCounterpartiesReports.defaultProps = {
  className: "",
}

TableRowCounterpartiesReports.propTypes = {
  data: PropTypes.object.isRequired,
  canView: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool
}
