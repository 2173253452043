import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import {
  FETCH_ADD_COUNTERPARTIES,
  FETCH_DELETE_COUNTERPARTIES,
  FETCH_EDIT_COUNTERPARTIES,
  FETCH_GET_COUNTERPARTIES,
  FETCH_GET_COUNTERPARTY,
  FETCH_GET_COUNTERPARTY_REPORT,
  FETCH_GET_COUNTERPARTY_TYPE_PRODUCT,
  FETCH_GET_SELLING_PRICES,
  FETCH_COUNTERPARTY_SAILING_MARKS_CHART,
  FETCH_DEBIT_CREDIT_CHART
} from "@/redux/constants/Counterparties";
import {
  addCounterparties,
  deleteCounterparties,
  editCounterparties,
  getCounterparties,
  getCounterparty,
  getCounterpartyReport,
  getCounterpartyTypeProduct,
  getSellingPrices,
  getCounterpartySailingMarksChart,
  getDebitCreditChart,
} from "@/redux/actions/Counterparties";
import { CounterpartiesAPI } from "@/api/counterpartiesAPI";
import {setErrorMessage, setIsLoadedGlobal, setSort, setSuccess} from "@/redux/actions/global";
import { WidgetService } from "@/api/WidgetService";

//Counterparties
export function* fetchCounterparty() {
  yield takeEvery(FETCH_GET_COUNTERPARTY, function* ({id}) {
    try {
      const response = yield call(() => CounterpartiesAPI.getCounterparty(id));
      yield put(getCounterparty(response[0]));
      yield put(setIsLoadedGlobal(true));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export function* fetchCounterparties() {
  yield takeLatest(FETCH_GET_COUNTERPARTIES, function* ({payload}) {
    try {
      const response = yield call(() => CounterpartiesAPI.getCounterparties(payload.filters, payload.page, payload.sort));
      yield put(getCounterparties(response));
      yield put(setIsLoadedGlobal(true));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export function* fetchAddCounterparties() {
  yield takeEvery(FETCH_ADD_COUNTERPARTIES, function* ({payload}) {
    try {
      const response = yield call(() => CounterpartiesAPI.addCounterparty(payload));
      yield put(addCounterparties(response));
      yield put(setSuccess("Контрагент добавлен"));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export function* fetchEditCounterparties() {
  yield takeEvery(FETCH_EDIT_COUNTERPARTIES, function* ({payload}) {

    try {
      const response = yield call(() => CounterpartiesAPI.editCounterparty(payload.id, payload));
      yield put(editCounterparties(response));
      yield put(setSuccess("Контрагент изменен"));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export function* fetchDeleteCounterparties() {
  yield takeEvery(FETCH_DELETE_COUNTERPARTIES, function* ({payload}) {

    try {
      yield call(() => CounterpartiesAPI.deleteCounterparty(payload));
      yield put(deleteCounterparties(payload));
      yield put(setSuccess("Контрагент удален"));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

//Counterparties Reports
export function* fetchCounterpartiesReport() {
  yield takeLatest(FETCH_GET_COUNTERPARTY_REPORT, function* ({payload}) {
    try {
      const response = yield call(() => CounterpartiesAPI.getCounterpartiesReport(payload.filters, payload.id, payload.sort));
      yield put(getCounterpartyReport(response));
      yield put(setIsLoadedGlobal(true));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

//Counterparties Type Product
export function* fetchCounterpartiesTypeProduct() {
  yield takeLatest(FETCH_GET_COUNTERPARTY_TYPE_PRODUCT, function* ({payload}) {

    try {
      const response = yield call(() => CounterpartiesAPI.getTypeProductReports(payload.filters, payload.page, payload.sort));
      yield put(getCounterpartyTypeProduct(response));
      yield put(setIsLoadedGlobal(true));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

//Counterparties Selling Price
export function* fetchCounterpartiesSellingPrice() {
  yield takeLatest(FETCH_GET_SELLING_PRICES, function* ({payload}) {
    try {
      const response = yield call(() => CounterpartiesAPI.getSellingPrice(payload.filters, payload.page, payload.sort));
      yield put(getSellingPrices(response));
      yield put(setIsLoadedGlobal(true));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}


export function* fetchSailingMarksChart() {
  yield takeEvery(FETCH_COUNTERPARTY_SAILING_MARKS_CHART, function* ({ id, filters }) {

    try {
      const response = yield call(() => CounterpartiesAPI.getCounterpartySellingByMarks(id, filters));
      yield put(getCounterpartySailingMarksChart(response));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export function* fetchDebitCreditChart() {
  yield takeEvery(FETCH_DEBIT_CREDIT_CHART, function* ({ id, filters = {} }) {

    try {
      const response = yield call(() => WidgetService.debitCredit(filters, id));
      yield put(getDebitCreditChart(response));
    } catch (error) {
      yield put(setErrorMessage(error));
      console.error(error);
    }
  })
}

export default function* rootSaga() {
  yield all([
    fetchCounterparties(),
    fork(fetchAddCounterparties),
    fork(fetchDeleteCounterparties),
    fork(fetchEditCounterparties),
    fork(fetchCounterpartiesReport),
    fork(fetchCounterpartiesTypeProduct),
    fork(fetchCounterpartiesSellingPrice),
    fork(fetchCounterparty),
    fork(fetchSailingMarksChart),
    fork(fetchDebitCreditChart)
  ]);
}
