import {
  ADD_COUNTERPARTIES,
  DELETE_COUNTERPARTIES,
  EDIT_COUNTERPARTIES,
  GET_COUNTERPARTIES,
  GET_COUNTERPARTY,
  GET_COUNTERPARTY_REPORT,
  GET_COUNTERPARTY_TYPE_PRODUCT,
  GET_SELLING_PRICES,
  GET_SETTINGS_MARKS,
  GET_SETTINGS_MARK,
  GET_SAILING_MARKS_CHART,
  GET_DEBIT_CREDIT_CHART, SET_COUNTERPARTY_FILTERS,
} from "@/redux/constants/Counterparties";

const initState = {
  counterparty: {},
  counterpartyFilters: {
  },
  counterpartySellingPrices: {},
  counterparties: {
    items: []
  },
  counterpartyReports: [],
  counterpartyTypeProducts: [],
  sellingPrices: [],
  settingsMarks: [],
  settingsMark: {},
  nomenclatures: [],
  debitCredit: {},
};

const Counterparties = (state = initState, action) => {
  switch (action.type) {
    case GET_COUNTERPARTY:
      return {
        ...state,
        counterparty: action.payload
      }
    case SET_COUNTERPARTY_FILTERS:
      return {
        ...state,
        counterpartyFilters: {...state.counterpartyFilters, ...action.payload}
      }
    case GET_COUNTERPARTIES:
      return {
        ...state,
        counterparties: {...action.payload, isLoaded: true},
        filters: action.filters,
      }
    case ADD_COUNTERPARTIES:
      return {
        ...state,
        counterparties: {...state.counterparties, items: [...state.counterparties.items, action.payload]}
      }
    case EDIT_COUNTERPARTIES:
      return {
        ...state,
        counterparties: {...state.counterparties, items: state.counterparties.items.map(item => item.id === action.payload.id ? action.payload : item)}
      }
    case DELETE_COUNTERPARTIES:
      return {
        ...state,
        counterparties: {
          ...state.counterparties,
          items: state.counterparties.items.filter(item => item.id !== action.payload)
        }
      }
    case GET_COUNTERPARTY_REPORT:
      return {
        ...state,
        counterpartyReports: {items: action.payload, isLoaded: true}
      }
    case GET_COUNTERPARTY_TYPE_PRODUCT:
      return {
        ...state,
        counterpartyTypeProducts: {items: action.payload, isLoaded: true}
      }
    case GET_SELLING_PRICES:
      return {
        ...state,
        sellingPrices: {items: action.payload, isLoaded: true}
      }
    case GET_SETTINGS_MARKS:
      return {
        ...state,
        settingsMarks: action.payload,
        isLoadedMarks: true
      }
    case GET_SETTINGS_MARK:
      return {
        ...state,
        settingsMark: action.payload,
        isLoadedMark: true
      }
    case GET_SAILING_MARKS_CHART:
      return {
        ...state,
        counterpartySellingPrices: action.payload,
      }
    case GET_DEBIT_CREDIT_CHART:
      return {
        ...state,
        debitCredit: action.payload,
      }
    default:
      return state;
  }
};

export default Counterparties;
