import React, {useMemo, useState, useEffect} from 'react'
import {Button, message, Space, Table, DatePicker, Typography} from 'antd'
import moment from "moment";
import {getRequisitionStatusBadge} from "@/views/app-views/crm/utils";
import {getFormattedValuteDefault, getFormattedValuteDefaultWithPostfix} from "@/utils/numeral";
import {CheckCircleOutlined, EyeOutlined, FilePdfOutlined} from "@ant-design/icons";
import {showErrorDefault, useCalculateTableWrapperSize} from "@/utils/hooks";
import {CrmService} from "@/api/crm-orders";
import {ClientsApi} from "@/api/clients";
import  {FilterRangePicker} from "./orders-table/filter-range-picker"

const { Text } = Typography

const { RangePicker } = DatePicker

export const OrdersTable = ({ isLoading, orders, isClient, onViewOrder,
                              useFilters, onFiltersUpdate }) => {

  const [ page, setPage ] = useState(1)

  const [ filters, setFilters ] = useState(null)

  const [ clients, setClients ] = useState([])

  const clientFiltersPrepared = useMemo(() =>
      clients.map( client => ({ text: client.name, value: client.id }))
    ,[clients])

  useEffect(() => {
    async function fetch() {
      try {
        const clients = await ClientsApi.fetchList()
        setClients(clients)
      } catch (e) {
        showErrorDefault(e)
      }
    }

    if (useFilters && !isClient) fetch()
  }, [useFilters])

  const verticalScrollWrapperSize = useCalculateTableWrapperSize()

  const handleTableChange = (selectedRows, filters) => {
    // const filtersPrepared = {}
    const clientId = filters.client && filters.client[0] && +filters.client[0]
    // if (clientId) filtersPrepared.clientId = clientId

    setFilters( filters => {
      let f = { ...filters }
      if (!clientId) delete f.clientId
      else f.clientId = clientId
      onFiltersUpdate(f)
      return f
    })
  }

  // const [datePickerIsVisible, setDatePickerIsVisible] = useState(false)
  const [datePickerInterval, setDatePickerInterval] = useState([])
  const onConfirmDatePicker = (dateInterval) => {
    setDatePickerInterval(dateInterval)
    console.log('onConfirmDatePicker', dateInterval)
    if (!dateInterval || !dateInterval.length) {
      setFilters( filters => {

        const newFilters = {
          ...filters, minDate: null, maxDate: null
        }

        onFiltersUpdate(newFilters)

        return newFilters
      })
    } else {
      const [ minDate, maxDate ] = dateInterval.map( v => v.format('YYYY-MM-DD'))
      setFilters( filters => {
        const f = {
          ...filters, minDate, maxDate
        }
        onFiltersUpdate(f)
        return f
      })
    }
    // setDatePickerIsVisible(false)
  }

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'Номер',
        dataIndex: 'id',
        render: v => `№ ${v}`,
        width: 80
      },
      (!isClient &&
        {
          title: 'Клиент',
          dataIndex: 'client',
          render: client => client.name,
          filterMultiple: false,
          ...(useFilters && clientFiltersPrepared
              ? {
                filters: clientFiltersPrepared
              }
              : {}
          )
        }),
      {
        title: 'Дата заявки',
        dataIndex: 'created',
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a,b, sortOrder) => a.created >= b.created ? 1 : -1,
        defaultSortOrder: 'descend',
        render: v => moment(v).format('DD.MM.YYYY'),
        filterDropdown: (props) => <FilterRangePicker {...props} onConfirm={onConfirmDatePicker}/>
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        render: (v, record) => getRequisitionStatusBadge(v)
      },
      {
        title: 'К отгрузке',
        dataIndex: 'tonnage',
        align: 'right',
        render: (v, record) => {
          return <Space direction={"vertical"}>
            <Text>{ getFormattedValuteDefault(v) } т</Text>
            <Text>{getFormattedValuteDefaultWithPostfix(v * record.price, 'RUB')}</Text>
          </Space>
        }
      },
      {
        title: 'Отгружено',
        align: 'right',
        dataIndex: 'shipmentNet',
        render: (v, record) => {
          return <Space direction={"vertical"}>
            <Text>{ getFormattedValuteDefault(v / 1000) } т</Text>
            <Text>{getFormattedValuteDefaultWithPostfix((v / 1000) * record.price, 'RUB')}</Text>
          </Space>
        }
      },
      {
        title: 'Цена',
        align: 'right',
        dataIndex: 'price',
        render: v => getFormattedValuteDefaultWithPostfix(v,'RUB')
      },
      {
        align: 'center',
        title: '',
        render: (_, record,i) => {
          const buttons = []

          buttons.push(
            <Button size={"small"} block icon={<EyeOutlined />} className={"ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only"} type="link"
                    onClick={() => onViewOrder(record.id)}
                    title={"Просмотр"}/>)

          return <Space direction={"vertical"}>
            { buttons }
          </Space>
        }
      }
    ]
    return columns.filter(Boolean)
  },[clientFiltersPrepared, useFilters])

  return <Table
    className="flex-grow-1"
    loading={isLoading}
    columns={tableColumns}
    pagination={false}
    dataSource={orders}
    scroll={{ y: verticalScrollWrapperSize }}
    onChange={handleTableChange}
    rowKey='id'
  />
}
