import React, {useState, useMemo, useRef, useEffect, useLayoutEffect} from 'react'
import { Table, Tooltip, InputNumber, Typography } from 'antd';
import {getMonthNames} from "../../../utils/date";
import {getFormattedValuteDefault} from "../../../utils/numeral";
import {MyInputComponent} from "@/views/app-views/bdds/cell-input-component";
import { IS_DON_UGLI } from "@/utils/env"
import {BddsComment} from "@/components/shared-components/bdds-comment-input";

const { Text } = Typography;

const getValuePrepared = (orig, formatted) => {
  const className = orig < 0 ? 'value-negative' : null
  return <span className={className}>{ formatted }</span>
}

const getTableRowKey = record => record.codeString

export const getEmptyMonths = () => {
  let months = {}
  for (let i = 1; i <13; i++) {
    months[i] = { actual: 0, plan: 0, weeks: {} }
  }
  return months;
}

export const calculateSummary = (items, view, monthWeeksTree, days) => items.reduce( (acc, item, itemIndex, arr) => {
  if (view === 'days') {
    days.forEach( day => {
      acc.days[day].actual += item.days[day].actual
      acc.days[day].plan += item.days[day].plan
      if (itemIndex === arr.length - 1) {
        acc.days[day]['actual-formatted'] = getFormattedValuteDefault(acc.days[day].actual, false, IS_DON_UGLI)
        acc.days[day]['plan-formatted'] = getFormattedValuteDefault(acc.days[day].plan, false, IS_DON_UGLI)
      }
    })
    acc.month.actual += item.month.actual
    acc.month.plan += item.month.plan
    if (itemIndex === arr.length - 1) {
      acc.month['actual-formatted'] = getFormattedValuteDefault(acc.month.actual, false, IS_DON_UGLI)
      acc.month['plan-formatted'] = getFormattedValuteDefault(acc.month.plan, false, IS_DON_UGLI)
    }
  } else {
    if (view === 'months') {
      for (let i = 1; i < 13; i++) {
        acc.months[i].plan += item.months[i].plan
        acc.months[i].actual += item.months[i].actual
        if (itemIndex === arr.length - 1) {
          acc.months[i]['plan-formatted'] = getFormattedValuteDefault(acc.months[i].plan, false, IS_DON_UGLI)
          acc.months[i]['actual-formatted'] = getFormattedValuteDefault(acc.months[i].actual, false, IS_DON_UGLI)
        }
      }
    } else if (view === 'weeks') {
      for (let i = 1; i < 13; i++) {
        for (let j in monthWeeksTree[i]) {
          const strWeekNumber = monthWeeksTree[i][j]
          if (!acc.months[i].weeks[strWeekNumber]) acc.months[i].weeks[strWeekNumber] = { actual: 0 }
          acc.months[i].weeks[strWeekNumber].actual += item.months[i].weeks[strWeekNumber].actual
          if (itemIndex === arr.length - 1) {
            acc.months[i].weeks[strWeekNumber]['actual-formatted'] = getFormattedValuteDefault(acc.months[i].weeks[strWeekNumber].actual, false, IS_DON_UGLI)
          }
        }
      }
    }

    acc.year.actual += item.year.actual
    acc.year.plan += item.year.plan

    if (itemIndex === arr.length - 1) {
      acc.year['actual-formatted'] = getFormattedValuteDefault(acc.year.actual, false, IS_DON_UGLI)
      acc.year['plan-formatted'] = getFormattedValuteDefault(acc.year.plan, false, IS_DON_UGLI)
    }

  }

  return acc
},
  { months: getEmptyMonths(), year: { actual: 0, plan: 0 }, days: days && Object.fromEntries(
    days.map( day => ([day, { actual: 0, plan: 0 }])),
  ),
    month: { actual: 0, plan: 0 }
  })

const getGetSummaryRowFunc = (view, monthWeeksTree, days) => (pageData) => {
  // console.log('getGetSummaryRowFunc', view, monthWeeksTree, days)
  const summaryData = calculateSummary(pageData.filter( row => row.type !== 'balance' ), view, monthWeeksTree, days)

  console.log('summaryData', summaryData)

  const summaryColumns = [<Table.Summary.Cell className={"border-dark-right"} index={0} key={'name'}>
    ИТОГО ПРИТОК/ОТТОК ДС
  </Table.Summary.Cell>]

  if (view === 'months') {
    for (let i = 1; i < 13; i++) {
      summaryColumns.push(
        <Table.Summary.Cell className={"text-center"} key={`${i}-plan`}>
          <Text className={"text-center"}>
            {getValuePrepared(summaryData.months[i].plan, summaryData.months[i]['plan-formatted'])}
          </Text>
        </Table.Summary.Cell>
      )
      summaryColumns.push(
        <Table.Summary.Cell className={"text-center border-dark-right"} key={`${i}-actual`}>
          <Text className={"text-center"}>
            {getValuePrepared(summaryData.months[i].actual, summaryData.months[i]['actual-formatted'])}
          </Text>
        </Table.Summary.Cell>
      )
    }
    summaryColumns.push(
      <Table.Summary.Cell index={25} className={"text-center"} key={`year-plan`}>
        <Text >
          {getValuePrepared(summaryData.year.plan, summaryData.year['plan-formatted'])}
        </Text>
      </Table.Summary.Cell>
    )
    summaryColumns.push(
      <Table.Summary.Cell index={26} className={"text-center"} key={`year-actual`}>
        <Text className={"text-center"}>
          {getValuePrepared(summaryData.year.actual, summaryData.year['actual-formatted'])}
        </Text>
      </Table.Summary.Cell>
    )
  } else if (view === 'weeks') {
    for (let i = 1; i < 13; i++) {
      for (let j in monthWeeksTree[i]) {
        const weekNumber = monthWeeksTree[i][j]
        summaryColumns.push(
          <Table.Summary.Cell className={"text-center"} key={`${i}-${j}-actual`}>
            <Text className={"text-center"}>
              {getValuePrepared(summaryData.months[i].weeks[weekNumber].actual, summaryData.months[i].weeks[weekNumber]['actual-formatted'])}
            </Text>
          </Table.Summary.Cell>
        )
      }
    }
    const lastColumnIndex = Object.values(summaryData.months).reduce( (acc, { weeks }) => {
      acc += Object.keys(weeks).length
      return acc
    },0)
    console.log('lastColumnIndex', lastColumnIndex)
    summaryColumns.push(
      <Table.Summary.Cell index={lastColumnIndex+1} className={"text-center"} key={`year-actual`}>
        <Text className={"text-center"}>
          {getValuePrepared(summaryData.year.actual, summaryData.year['actual-formatted'])}
        </Text>
      </Table.Summary.Cell>
    )
  } else if (view === 'days') {
    days.forEach( day => {
      summaryColumns.push(
        <Table.Summary.Cell className={"text-center"} key={`${day}-plan`}>
          <Text className={"text-center"}>
            {getValuePrepared(summaryData.days[day].plan, summaryData.days[day]['plan-formatted'])}
          </Text>
        </Table.Summary.Cell>
      )
      summaryColumns.push(
        <Table.Summary.Cell className={"text-center border-dark-right"} key={`${day}-actual`}>
          <Text className={"text-center"}>
            {getValuePrepared(summaryData.days[day].actual, summaryData.days[day]['actual-formatted'])}
          </Text>
        </Table.Summary.Cell>
      )
    })
    summaryColumns.push(
      <Table.Summary.Cell index={1 + (days.length * 2)} className={"text-center"} key={`month-plan`}>
        <Text className={"text-center"}>
          {getValuePrepared(summaryData.month.plan, summaryData.month['plan-formatted'])}
        </Text>
      </Table.Summary.Cell>
    )
    summaryColumns.push(
      <Table.Summary.Cell index={1 + (days.length * 2) + 1} className={"text-center border-dark-right"} key={`month-actual`}>
        <Text className={"text-center"}>
          {getValuePrepared(summaryData.month.actual, summaryData.month['actual-formatted'])}
        </Text>
      </Table.Summary.Cell>
    )
  }

  return <Table.Summary.Row>
    {summaryColumns}
  </Table.Summary.Row>
}

const getColumns = (tableFocusState, onUpdateFocusState, onSendValues, showPayments, view, monthWeeksTree, days, cellInFocus, getOnCell) => {

  let yearChildren = [
  ]

  if (view === 'days') {
    yearChildren = [
      {
        dataIndex: ['month'],
        title: 'План',
        width: 100,
        shouldCellUpdate: () => true,
        className: ['bdds-data-cell'],
        render: (valueObject) => valueObject && getValuePrepared(valueObject.actual, valueObject['plan-formatted']),
        fixed: 'right'
      },
      {
        dataIndex: ['month'],
        title: 'Факт',
        width: 100,
        shouldCellUpdate: () => false,
        className: ['bdds-data-cell'],
        render: (valueObject) => valueObject && getValuePrepared(valueObject.actual, valueObject['actual-formatted']),
        fixed: 'right'
      }
    ]
  } else {
    if (view === 'months') {
      yearChildren.push({
        dataIndex: ['year'],
        title: 'План',
        width: 100,
        shouldCellUpdate: () => true,
        className: ['bdds-data-cell'],
        render: (valueObject, row) =>  row.type === 'balance' ? null : valueObject && getValuePrepared(valueObject.plan, valueObject['plan-formatted']),
        fixed: 'right'
      })
    }

    yearChildren.push({
      dataIndex: ['year'],
      title: 'Факт',
      width: 100,
      shouldCellUpdate: () => false,
      className: ['bdds-data-cell'],
      render: (valueObject) => valueObject && getValuePrepared(valueObject.actual, valueObject['actual-formatted']),
      fixed: 'right'
    })
  }

  const TableChildren = [
    {
      title: "СТАТЬИ",
      dataIndex: "name",
      fixed: "left",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      key: 'name',
      className: ["border-dark-right"],
      render: (name, record) => (<Tooltip placement="topLeft" title={record.tooltipName || record.name}>
        {name}
      </Tooltip>)
    },
    ...(view === 'days' ? (
      days.map( (day) => {
        return {
          title: day,
          className: ["border-dark-right"],
          key: day,
          children: [
            {
              key: 'plan',
              dataIndex: ['days', day],
              title: 'План',
              ...(IS_DON_UGLI ? { onCell: getOnCell(['days', day]) } : {}),
              width: 100,
              shouldCellUpdate: () => true,
              className: ['bdds-data-cell', 'inputable-cell'],
              render: (valueObject, row) => {
                if (!valueObject) return '-'
                if (row.type === 'balance') return null
                if (row.children && row.children.length) {
                  return getValuePrepared(valueObject.plan, valueObject['plan-formatted'])
                } else {
                  const showComments = IS_DON_UGLI
                  const comment = Array.isArray(valueObject.comments) && valueObject.comments.length ? valueObject.comments[0] : ''

                  if (cellInFocus && (cellInFocus.code === row.codeString && cellInFocus.key === day)) {

                    const isExpense = row.codeString.startsWith('2')
                    let valuePrep = IS_DON_UGLI && isExpense ? Math.abs(valueObject.plan) : valueObject.plan

                    return <MyInputComponent value={valuePrep}
                                             signed={IS_DON_UGLI}
                                             onEnter={(newValue) => onSendValues(row, day, (IS_DON_UGLI && isExpense) ? -newValue : newValue)}
                                             onBlur={() => onUpdateFocusState(null)}/>
                  } else {
                    return <>
                      { showComments && comment ? <BddsComment comment={comment}/> : null }
                      <div onClick={() => onUpdateFocusState({ code: row.codeString, key: day})}>{getValuePrepared(valueObject.plan, valueObject['plan-formatted'])}</div>
                    </>
                  }
                }
              },
              fixed: null
            },
            {
              key: 'actual',
              dataIndex: ['days', day],
              title: 'Факт',
              width: 100,
              shouldCellUpdate: () => false,
              className: ['border-dark-right', 'bdds-data-cell', 'cursor-pointer'],
              render: (valueObject, rowData) => {
                if (!valueObject) return '-'
                return (<div onClick={() => showPayments(rowData.name, valueObject.payments)}>
                  {getValuePrepared(valueObject.actual, valueObject['actual-formatted'])}
                </div>)
              },
              fixed: null
            },
          ]
        }
      })
    ) : (
      getMonthNames().map(
        (monthName, i) => {
          if (view === 'weeks') {
            return {
              title: monthName.toUpperCase(),
              className: ["border-dark-right"],
              key: i,
              children: monthWeeksTree[i+1].map( (strWeekNumber, j, weeksArr) => {
                const className = ['bdds-data-cell', 'cursor-pointer' ]
                if (j === weeksArr.length - 1) {
                  className.push('border-dark-right')
                }
                return {
                  title: strWeekNumber,
                  key: strWeekNumber,
                  width: 100,
                  className,
                  dataIndex: ['months', i+1, 'weeks',strWeekNumber],
                  render: (valueObject, rowData) => {
                    if (!valueObject) return null
                    return (<div onClick={() => showPayments(rowData.name, valueObject.payments)}>
                      {getValuePrepared(valueObject.actual, valueObject['actual-formatted'])}
                    </div>)
                  },
                }
              })
            }
          } else if (view === 'months') {
            return {
              title: monthName.toUpperCase(),
              className: ["border-dark-right"],
              key: i,
              children: [
                {
                  key: 'plan',
                  dataIndex: ['months', i+1],
                  title: 'План',
                  width: 100,
                  shouldCellUpdate: () => true,
                  className: ['bdds-data-cell', 'inputable-cell'],
                  render: (valueObject, row) => {
                    if (!valueObject) return null
                    if (row.type === 'balance') return null
                    if (row.children && row.children.length) {
                      return getValuePrepared(valueObject.plan, valueObject['plan-formatted'])
                    } else {
                      let monthNumber = i+1
                      if (!IS_DON_UGLI && cellInFocus && (cellInFocus.code === row.codeString && cellInFocus.key === i+1)) {
                        return <MyInputComponent value={valueObject.plan}
                                                 onEnter={(newValue) => onSendValues(row, i+1, newValue)}
                                                 onBlur={() => onUpdateFocusState(null)}/>
                      } else {
                        const commentRendered = IS_DON_UGLI && valueObject.comments && valueObject.comments.length && <BddsComment comment={valueObject.comments.join('\n')}/> || null
                        return <div onClick={() => onUpdateFocusState({ code: row.codeString, key: monthNumber})}>
                          {commentRendered}
                          {getValuePrepared(valueObject.plan, valueObject['plan-formatted'])}
                        </div>
                      }
                    }
                  },
                  fixed: null
                },

                {
                  key: 'actual',
                  dataIndex: ['months', i+1],
                  title: 'Факт',
                  width: 100,
                  shouldCellUpdate: () => false,
                  className: ['border-dark-right', 'bdds-data-cell', 'cursor-pointer'],
                  render: (valueObject, rowData) => {
                    if (!valueObject) return null
                    return (<div onClick={() => showPayments(rowData.name, valueObject.payments)}>
                      {getValuePrepared(valueObject.actual, valueObject['actual-formatted'])}
                    </div>)
                  },
                  fixed: null
                },
              ]
            }
          }

        })
    )),
    {
      title: view === 'days' ? 'МЕСЯЦ' : 'ГОД',
      //className: ["border-dark-right"],
      key: 'year',
      children: yearChildren
    }
  ]

  console.log('TableChildren', TableChildren)

  return TableChildren

}


export const BddsTable = (props) => {

  const { onUpdateFocusState,
    onSendValues,
    tableFocusState, dataSource, className,
    showPayments,
    tableHeight,
    updateTableHeight,
    view,
    cellInFocus,
    days,
    onCommentCellClick
  } = props

  const monthWeeksTree = useMemo(() => {
    if (view === 'weeks') {
      const firstRow = dataSource[0]
      if (!firstRow || !firstRow.months) return null
      return Object.entries(firstRow.months).reduce((acc, [monthNumber, monthData]) => {
        let weeks

        if (+monthNumber === 1) {
          let w = Object.keys(monthData.weeks)
          let startWeeks = w.filter(week => +week > 50)
          let endWeeks = w.filter(week => +week < 50)
          weeks = [ ...startWeeks, ...endWeeks ]
        } else {
          weeks = Object.keys(monthData.weeks)
        }

        acc[+monthNumber] = weeks
        //acc[+monthNumber] = Object.keys(monthData.weeks)
        return acc
      },{})
    } else return null
  },[view])

  useLayoutEffect(() => {
    console.log('useLayoutEffect', tableHeight)
    if (tableHeight) return

    /**
     *
     * @type {Element}
     */
    const tableHeader = document.querySelector('.ant-table-header')
    if (!tableHeader) return
    const headerHeight = tableHeader.offsetHeight

    const pageWrapper = document.querySelector('.ant-table-wrapper')
    if (!pageWrapper) return
    const wrapperHeight = pageWrapper.offsetHeight

    //updateVerticalScrollWrapperSize(wrapperHeight - headerHeight)
    updateTableHeight(wrapperHeight - headerHeight)

  }, [tableHeight])

  const getOnCell = useMemo(() => {
    return (dataIndex) => (record,rowIndex) => ({
      onClick: e => console.log('on click'),
      onContextMenu: (e, ...rest) => {
        const cell = e.target
        const rect = cell.getBoundingClientRect();
        e.preventDefault()
        console.log('onContextMenu', rect)
        const pagePos = {
          x: rect.left + (rect.width / 2),
          y: rect.top + rect.height
        }
        onCommentCellClick({ pagePos, row: record, dataIndex })
      }
    })
  },[onCommentCellClick])

  if (
    (view === 'weeks' && !monthWeeksTree) ||
    (view === 'days' && dataSource && dataSource[0] && !dataSource[0].days) ||
    (view === 'months' && dataSource && dataSource[0] && !dataSource[0].months)
  ) return null

  const columns = getColumns(tableFocusState, onUpdateFocusState, onSendValues, showPayments, view, monthWeeksTree, days, cellInFocus, getOnCell)

  // summary={getGetSummaryRowFunc(view, monthWeeksTree)}

  return (
    <Table
      defaultExpandAllRows={true} expandIcon={() => <></>}
      summary={getGetSummaryRowFunc(view, monthWeeksTree, days)}
      rowKey={getTableRowKey}
      className={className}
      dataSource={dataSource}
      pagination={false}
      columns={columns}
      scroll={{ x: 'true', y: tableHeight }}/>
  )

}
