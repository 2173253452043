/**
 * https://dadata.ru/api/suggest/party/#usage
 */
import axios from "axios";
const SERVICE_KEY = "8b8547ee5be2b7c4348507e11d317ade18720055";

const service = axios.create({
  baseURL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
  headers: {
    Authorization: `Token ${SERVICE_KEY}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

export const DaDataService = {
  /**
   *
   * @param query: INN or OGRN
   */
  info: searchQuery =>
    service
      .post(
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party",
        {
          query: searchQuery,
          status: ["ACTIVE"]
        }
      )
      .then(
        r => r.data.suggestions.map(el => el.data)
      ),
  suggest: searchQuery =>
    service
      .post(
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party",
        {
          query: searchQuery,
          status: ["ACTIVE"]
        }
      )
      .then(r => r.data.suggestions.map(el => el.data))
};
