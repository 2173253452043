import React from 'react';
import PropTypes from 'prop-types';
import {Select} from "antd";
import './InputMultiSelect.css'

export const InputMultiSelect = ({name, value, placeholder, options, className, setValue, register, required}) => {

        const [focus, setFocus] = React.useState("");

        let initValue;

        if (Array.isArray(value)) {
            initValue = value.filter(item => {
                for (let i = 0; i < options.length; i++) {
                    if (item === options[i].value) {
                        return true
                    }
                }
                return false
            })
        } else {
            initValue = [value];
        }

        React.useEffect(() => {
            setValue(name, initValue)
        }, [])

        return (
            <Select
                {...register(name, {required, validate: value => required ? value !== placeholder : true})}
                name={name}
                mode="multiple"
                className={className + ' multi-select rounded-md' + focus || 'border-gray-300'}
                bordered={false}
                style={{fontSize: "1rem"}}
                placeholder={placeholder}
                defaultValue={initValue}
                onFocus={() => setFocus(" border-indigo-300 ring ring-indigo-200 ring-opacity-75 multi-select-focus")}
                onBlur={() => setFocus("")}
                showArrow
                showSearch={false}
                onChange={(value) => setValue(name, value)}
            >
                {
                    options?.map(option => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        )
                    })
                }
            </Select>
        );
    }
;

InputMultiSelect.propTypes = {};