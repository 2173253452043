import React from 'react'
import {Col, Row} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {formatRUBSimple, getFormattedValuteDefault, getFormattedValuteDefaultWithPostfix} from "@/utils/numeral";

export const CurrencyStat = ({ value, percent, title, className }) => {


  return (<div className={className}>
    {title && <h5 className="mb-0 h5-responsive" style={{whiteSpace: "break-spaces"}}>{title}</h5>}
    <div className={"d-flex"} style={{ alignItems: "center" }}>
    <h2 className="mb-0 font-weight-bold h2-responsive">{formatRUBSimple(value)}</h2>
    {
      percent ?
        <span className={`font-size-md d-inline-block ${percent !== 0 && percent > 0 ? 'text-success' : 'text-danger'}`} >
								&nbsp;{percent}%&nbsp;
          {percent !== 0 && percent > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
							</span>
        :
        null
    }
  </div></div>)
}
