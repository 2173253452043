import React, {
  useMemo,
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
  lazy,
  Suspense,
  useRef
} from 'react'
import {Badge, Button, message, Table, Typography, Space, Form, Modal, Input, Radio, Select, Checkbox, Tag} from 'antd'
import {PlusOutlined, UserAddOutlined, CheckCircleOutlined, FilePdfOutlined, FireOutlined} from "@ant-design/icons";
import {useModalOrderForm} from "@/views/app-views/crm/requisitions/order-form/use-modal-order-form";
import {connect} from "react-redux";
import {getFormattedValuteDefaultWithPostfix} from "@/utils/numeral";
import {OrdersTable} from "@/views/app-views/crm/requisitions/orders-table";
import {useFetchOrderList, useOrdersPooling} from "@/views/app-views/crm/requisitions/hooks";

export const ViewCrmOrdersClient = ({ userData, initFilters }) => {

  const clientName = useMemo(() => userData && userData.client && userData.client.name, [userData])

  const clientBalanceBadge = useMemo(() => {

    const balance = userData && userData.client && userData.client.statistic &&
      (typeof userData.client.statistic.balance === 'number') &&
      userData.client.statistic.balance

    return <Tag>
      Баланс:&nbsp;
      <strong>
        { balance ? getFormattedValuteDefaultWithPostfix(balance, 'RUB') : '-' }
      </strong>
    </Tag>
  },[userData])

  const { loading, loadingRef, orders, fetchOrders } = useFetchOrderList(initFilters)
  const fetchAllOrders = useCallback(() => fetchOrders({}),[fetchOrders])
  const {openNewOrderModal,orderModalIsVisibleRef, openOrderModal,renderedOrderFormModal} = useModalOrderForm(fetchAllOrders)
  useEffect(() => {
    fetchOrders()
  },[])
  // useOrdersPooling(loadingRef,orderModalIsVisibleRef,fetchOrders)

  return <div className="bdds-page">
    <div className="border-bottom bdds-page-header">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2 className={"mb-0"}>
            Заказы {' / ' + clientName}
          </h2>
          <div style={{textAlign: "right", flexGrow: 1}}>
            { clientBalanceBadge }
            <Button icon={<PlusOutlined/>} size={"small"} type={"primary"}
                    onClick={openNewOrderModal}>Новый заказ</Button>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
    <OrdersTable
      orders={orders}
      isLoading={loading}
      isClient={true}
      useFilters={false}
      onViewOrder={openOrderModal}
      onFiltersUpdate={fetchOrders}
    />
    {renderedOrderFormModal}
  </div>
}


const mapStateToProps = ({ auth }) => {
  const { authData, userData } = auth;
  const groups = authData && authData.groups || []
  const isClient = authData && authData.groups && authData.groups.includes('clients')
  return { groups, userData, isClient }
};

export default connect(mapStateToProps, {})(ViewCrmOrdersClient);
