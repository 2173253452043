export const GET_COUNTERPARTY = 'GET_COUNTERPARTY';
export const SET_COUNTERPARTY_FILTERS = 'SET_COUNTERPARTY_FILTERS';
export const FETCH_GET_COUNTERPARTY = 'FETCH_GET_COUNTERPARTY';
export const GET_COUNTERPARTIES = 'GET_COUNTERPARTIES';
export const FETCH_GET_COUNTERPARTIES = 'FETCH_GET_COUNTERPARTIES';
export const ADD_COUNTERPARTIES = 'ADD_COUNTERPARTIES';
export const FETCH_ADD_COUNTERPARTIES = 'FETCH_ADD_COUNTERPARTIES';
export const EDIT_COUNTERPARTIES = 'EDIT_COUNTERPARTIES';
export const FETCH_EDIT_COUNTERPARTIES = 'FETCH_EDIT_COUNTERPARTIES';
export const DELETE_COUNTERPARTIES = 'DELETE_COUNTERPARTIES';
export const FETCH_DELETE_COUNTERPARTIES = 'FETCH_DELETE_COUNTERPARTIES';
export const GET_COUNTERPARTY_REPORT = 'GET_COUNTERPARTY_REPORT';
export const FETCH_GET_COUNTERPARTY_REPORT = 'FETCH_GET_COUNTERPARTY_REPORT';
export const GET_COUNTERPARTY_TYPE_PRODUCT = 'GET_COUNTERPARTY_TYPE_PRODUCT';
export const FETCH_GET_COUNTERPARTY_TYPE_PRODUCT = 'FETCH_GET_COUNTERPARTY_TYPE_PRODUCT';
export const GET_SELLING_PRICES = 'GET_SELLING_PRICES';
export const FETCH_GET_SELLING_PRICES = 'FETCH_GET_SELLING_PRICES';
export const GET_SETTINGS_MARKS = 'GET_SETTINGS_MARKS';
export const FETCH_GET_SETTINGS_MARKS = 'FETCH_GET_SETTINGS_MARKS';
export const ADD_SETTINGS_MARKS = 'ADD_SETTINGS_MARKS';
export const FETCH_ADD_SETTINGS_MARKS = 'FETCH_ADD_SETTINGS_MARKS';
export const EDIT_SETTINGS_MARKS = 'EDIT_SETTINGS_MARKS';
export const FETCH_EDIT_SETTINGS_MARKS = 'FETCH_EDIT_SETTINGS_MARKS';
export const DELETE_SETTINGS_MARKS = 'DELETE_SETTINGS_MARKS';
export const FETCH_DELETE_SETTINGS_MARKS = 'FETCH_DELETE_SETTINGS_MARKS';
export const GET_NOMENCLATURES= 'GET_NOMENCLATURES';
export const FETCH_GET_NOMENCLATURES= 'FETCH_GET_NOMENCLATURES';
export const FETCH_GET_SETTINGS_MARK = 'FETCH_GET_SETTINGS_MARK';
export const GET_SETTINGS_MARK = 'GET_SETTINGS_MARK';
export const FETCH_COUNTERPARTY_SAILING_MARKS_CHART = 'FETCH_COUNTERPARTY_SAILING_MARKS_CHART';
export const GET_SAILING_MARKS_CHART = 'GET_SAILING_MARKS_CHART';
export const FETCH_DEBIT_CREDIT_CHART = 'FETCH_DEBIT_CREDIT_CHART';
export const GET_DEBIT_CREDIT_CHART = 'GET_DEBIT_CREDIT_CHART';
