import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { notification } from 'antd';
import {updateService} from "@/utils/update-service";
import {THEME_CONFIG} from "@/configs/AppConfig";

// const history = createBrowserHistory({ basename: '/your-base-name' });

let hasAuthData = store.getState().auth.authData

if (hasAuthData) {
  store.dispatch({ type: 'FETCH_USER_DATA' })
}

function App() {

  useEffect(() => {
    const onUpdate = () => notification.info({
      message: 'Доступно обновление',
      closeIcon: (<></>),
      duration: null,
      style: { cursor: "pointer" },
      placement: "bottomRight",
      description:
        'Для обновления программы нажмите сюда.',
      onClick: () => {
        window.location.reload()
      },
    });

    updateService(onUpdate)
  },[])

  /*useEffect(() => {
    try {

      const wb = new Workbox('./service-worker.js', {
        updateViaCache: 'none'
      })

      // THIS EVENT IS LOGGED JUST FOR DEBUGGING PURPOSES
      wb.addEventListener('activated', event => {

        if (!event.isUpdate) {
          console.log('Service worker activated for the first time!', { event });

        } else {
          console.log('Service worker activated!', { event });
        }

      });

      wb.addEventListener('installed', event => {
        console.log('Service worker installed!', { event });

        if (event.isUpdate) {

          const key = `open${Date.now()}`;
          const btn = (
            <Button type="primary" size="small" onClick={() => notification.close(key)}>
              Обновить
            </Button>
          );
          notification.open({
            message: 'Доступно обновление',
            description:
              'Нажмите обновить.',
            btn,
            key,
            onClose: () => window.location.reload(),
          });

        }
      });


      // Register the service worker after event listeners have been added.
      wb.register()
        .then(registered =>{
          console.log('service-worker:before-update')
          //
          registered.update();
          console.log('service-worker:after-update')
        })
        .catch(error => console.log({ error }));

    } catch(e) {
     showErrorDefault(e)
    }
  },[])*/

  return (
    <div className={`App ${THEME_CONFIG.rootClassName}`}>
      <div id={"no-context-root"} style={{ zIndex: 10000, position: "relative" }}>

      </div>
      <Provider store={store}>
        <Router basename={process.env.REACT_APP_ROOT}>
          <Switch>
            <Route path="/" component={Views}/>
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
