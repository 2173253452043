import React from 'react';
import PropTypes from 'prop-types';
import { LinkIcon } from "@/components/util-components/LinkIcon/LinkIcon";

export const IconCell = ({ canView, canEdit, canDelete, id }) => {

  if (+id === 0) {
    return <td></td>
  }

  return (
    <td
      className={"px-3.5 h-full text-right leading-5 font-medium"}>
        <div className={"flex children:text-center children:flex children:mx-1 children:items-center children:justify-center children:align-middle items-center justify-end whitespace-nowrap "}>
            {canView && <LinkIcon id={id} link={canView} background={"bg-blue-400"} icon={"file"}/>}
            {canEdit && <LinkIcon id={id} callback={typeof canEdit === "function" ? canEdit : null}
                                  link={typeof canEdit !== "function" ? canEdit : null}
                                  background={"bg-yellow-500"}
                                  icon={"edit"}/>}
            {canDelete &&
            <LinkIcon id={id} callback={canDelete} background={"bg-red-500 js-open-delete"} icon={"trash-alt"}/>}</div>
    </td>
  );
};

IconCell.propTypes = {
  canView: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  canEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  canDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
};
