import React, {useState, useMemo, useRef, useEffect, useCallback} from 'react';
import {AutoComplete, Select, Spin, Input, Button} from 'antd';
import {useDebouncedFetch} from "@/utils/useDebouncedFetch";
import {DaDataService} from "@/api/da-data";

export const InputSelectRemote = React.forwardRef( ({ mode, prefetch, fetch, value, onChange, autofocus = false, ...props }, ref) => {

  const { loading, results, onSearch } = useDebouncedFetch(fetch)

  useEffect(() => {
    if (prefetch) onSearch()
  },[])

  const onSearchMemo = useMemo(() => prefetch ? null : onSearch,[])

  const valueTemp = useMemo(() => {
    if (mode === 'multiple') return value
    if (!results.length) return undefined
    else {
      const result = results.find( result => result.value === value )
      return result
    }
  },[ results, value, mode ])

  const onChangePrepared = useCallback((v) => {
    console.log('onChangePrepared', v)
    onChange(v.value)
  },[])

  return <Select
    ref={ref}
    value={!prefetch ? value : valueTemp}
    mode={mode}
    labelInValue
    notFoundContent={loading ? <Spin size="small" /> : null}
    filterOption={false}
    showSearch={true}
    onSearch={onSearchMemo}
    onChange={!prefetch ? onChange : onChangePrepared}
    options={results}
    {...props}
  />
})
