import React, { useEffect } from "react"
import {useDebouncedFetch} from "@/utils/useDebouncedFetch";
import {Input, AutoComplete, Spin} from "antd";
import {InputAutocomplete} from "./input-autocomplete.js"
import {PhoneInput} from "@/components/PhoneInput";

export const PhoneNumberAutocomplete = React.forwardRef((props, forwardRef) => {

  return <InputAutocomplete filterOption={false} {...props} ref={forwardRef}>
    <PhoneInput/>
  </InputAutocomplete>
})
