import {
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  FETCH_ADD_USER,
  FETCH_DELETE_USER,
  FETCH_EDIT_USER,
  FETCH_GET_USER,
  FETCH_GET_USERS,
  FETCH_GET_USERS_HISTORY,
  GET_USER,
  GET_USERS,
  GET_USERS_HISTORY
} from "@/redux/constants/Users";

export const getUsers = (users) => {
  return {
    type: GET_USERS,
    payload: users
  }
};

export const fetchUsers = ({ filters = {}, page = 1, sort = {}}) => {
  return {
    type: FETCH_GET_USERS,
    payload: {
      filters,
      page,
      sort
    }
  }
};

export const getUsersHistory = (usersHistory) => {
  return {
    type: GET_USERS_HISTORY,
    payload: usersHistory
  }
};

export const fetchGetUsersHistory = ({ filters = {}, page = 1, sort = {} }) => {
  return {
    type: FETCH_GET_USERS_HISTORY,
    payload: {
      filters,
      page,
      sort
    }
  }
};

export const getUser = (user) => {
  return {
    type: GET_USER,
    payload: user
  }
};

export const fetchGetUser = (id) => {
  return {
    type: FETCH_GET_USER,
    payload: id
  }
};

export const addUser = (user) => {
  return {
    type: ADD_USER,
    payload: user
  }
};

export const fetchAddUser = (user) => {
  return {
    type: FETCH_ADD_USER,
    payload: user
  }
};

export const fetchEditUser = (user) => {
  return {
    type: FETCH_EDIT_USER,
    payload: user
  }
};

export const editUser = (user) => {
  return {
    type: EDIT_USER,
    payload: user
  }
};

export const deleteUser = (id) => {
  return {
    type: DELETE_USER,
    payload: id
  }
};

export const fetchDeleteUser = (id) => {
  return {
    type: FETCH_DELETE_USER,
    payload: id
  }
};
