export default class CustomError extends Error {
  constructor(name, message = "", status, ...args) {
    super(message, ...args);
    this.name = name;
    this.message = message;
    this.status = status
  }

  toString() {
    return `${this.name}[${this.status}]: ${this.message}`
  }

}
