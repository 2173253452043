import React, {useRef, useEffect, useReducer, useCallback, useMemo} from 'react'
import debounce from "lodash/debounce";

function reducer(state, action) {
  switch (action.type) {
    case 'LOADING':
      return {
        loading: true,
        results: state.results,
        error: null
      }
    case 'SUCCESS':
      return {
        loading: false,
        results: action.payload,
        error: null
      }
    case 'ERROR':
      return {
        loading: false,
        results: [],
        error: action.payload
      }
  }
}

export function useDebouncedFetch(fetchMethod, searchThreshold) {
  const [ state, dispatch ] = useReducer(reducer, {loading: false, results: [], error: null })

  const fetch = useCallback(async (searchString) => {
      if (searchThreshold !== undefined && searchString && searchString.toString().length < (searchThreshold || 3)) {
        return
      }

      dispatch({ type: 'LOADING' })
      try {
        const results = await fetchMethod(searchString)
        console.log('useDebouncedFetch', results)
        dispatch({ type: 'SUCCESS', payload: results })
      } catch (e) {
        dispatch({type: 'ERROR', payload: e})
      }
  },[fetchMethod])

  const fetchDebounced = useMemo(() => debounce(fetch,800),[fetch])

  return { loading: state.loading, results: state.results, onSearch: fetchDebounced, fetch }

}
