import React, { useMemo } from 'react'
import moment from "moment";
import {Badge, Typography} from "antd";
import {getCrmBadgeClassName} from "@/views/app-views/crm/utils";

const Text = Typography.Text

export const OrderBlockTitle = ({ status, dateTime, title, matchStatus, highlight }) => {
  const dateTimeFormatted = useMemo(() =>
    dateTime ? moment(dateTime).format('DD.MM.YYYY HH:mm') : null
    ,[dateTime])

  const badgeClassName = useMemo(() =>
      getCrmBadgeClassName(status, highlight)
    ,[status])

  const component = useMemo(() => (<Text className={"mb-2 d-block"} style={{fontSize: '12px'}}>
    <Badge color={'transparent'} className={badgeClassName}/>
    {dateTimeFormatted} → {title}
  </Text>), [dateTimeFormatted, badgeClassName, title])

  return component
}
