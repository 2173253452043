import React from 'react';
import {connect} from "react-redux";
import {setErrorMessage, setFilters, setIsLoadedGlobal, setPage, setSort, setSuccess} from "@/redux/actions/global";
import {toast} from "react-toastify";
import {Result} from "antd";
import {getCurrentRange} from "@/helpers/HelperDate";

const mapStateToPropsWithToast = ({global}) => ({
  sort: global.sort,
  filters: global.filters,
  page: global.page,
  isLoadedGlobal: global.isLoaded,
});

const mapDispatchToPropsWithToast = (dispatch) => ({
  setSort: (e) => dispatch(setSort(e)),
  setFilters: (e) => dispatch(setFilters(e)),
  setPage: (e) => dispatch(setPage(e)),
  setIsLoadedGlobal: (e) => dispatch(setIsLoadedGlobal(e)),
});

export const withFilters = (Component) => {
  class FiltersWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isMobile: window.innerWidth < 767
      }
    }

    parseURLToObj = (useDate) => {
      let paramsFiltersObj = {};
      let paramsSortObj = {};
      let params = this.props.history.location.search.split("&");
      params.map(item => {
        const keyValue = item.split("=");
        if (keyValue[1]) {
          if (keyValue[0].includes("sort")) {
            paramsSortObj[keyValue[0].replace("?", "")] = decodeURI(keyValue[1]);
          } else {
            paramsFiltersObj[keyValue[0].replace("?", "")] = decodeURI(keyValue[1]);
          }
        }
      });

      if (!paramsFiltersObj.startDate && useDate && !Object.keys(paramsFiltersObj).length) {

        paramsFiltersObj = {...paramsFiltersObj, ...getCurrentRange()}
      }

      return {
        paramsFiltersObj,
        paramsSortObj
      }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
      if (prevProps.filters !== this.props.filters || prevProps.sort !== this.props.sort) {
        if (this.props.filters || this.props.sort) {
          const paramsObj = {...this.props.filters, ...this.props.sort};
          let filters = Object.keys(paramsObj).map(item => {
            if (paramsObj[item]) {
              return `${item}=${paramsObj[item]}`
            }
          });

          filters = filters.filter(item => item);

          this.props.history.push({
            search: "?" + filters.join("&")
          })
        }
      }
    }

    componentWillUnmount() {
      this.props.setPage(1);
    }

    render() {
      return <Component parseURLToObj={this.parseURLToObj} {...this.props} />
    }
  }

  return connect(mapStateToPropsWithToast, mapDispatchToPropsWithToast)(FiltersWrapper);
}
