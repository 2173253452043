import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
	LOG_IN, FETCH_USER_DATA, SIGNOUT_SUCCESS, CHECKOUT_TO_USER
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";
import { push } from 'react-router-redux';

import FirebaseService from '@/services/FirebaseService'
import {AuthService} from "../../api/AuthService";
import {getAuth, removeAuth, setAuth} from "../../utils/auth";
import {setRootAuth} from "@/utils/auth";

export function* checkoutToUser() {
	yield takeEvery(CHECKOUT_TO_USER, function* ({payload}) {
		try {
			const userId = payload
			if (!userId) throw new Error('no userId')

			const newAuthData = yield call(AuthService.checkoutToUser,userId)

			const rootAuthData = getAuth(window.localStorage);
			setRootAuth(rootAuthData, window.localStorage);
			setAuth(newAuthData, window.localStorage)
			window.location.reload()
		} catch (e) {
			console.error(e)
		}

	})
}

export function* fetchUserData() {
	yield takeEvery(FETCH_USER_DATA, function* () {

		try {
			let dataFromStorage = getAuth(window.localStorage);
			if (!dataFromStorage) {
				yield put(showAuthMessage('error'))
				return
			}

			const userData = yield call(AuthService.fetchUser, +dataFromStorage.userId)

			yield put(authenticated({
				userData,
				authData: dataFromStorage,
				canCheckoutToRoot: !!getAuth(window.localStorage,true)
			}))
		} catch (e) {
			console.error(e)
		}
	})
}

export function* auth() {
	yield takeEvery(LOG_IN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const authData = yield call(AuthService.login, { email, password })
			setAuth(authData, window.localStorage)

			let dataFromStorage = getAuth(window.localStorage);

			const userData = yield call(AuthService.fetchUser, +dataFromStorage.userId)

			yield put(authenticated({
				userData,
				authData: dataFromStorage
			}))
		} catch (err) {
			console.error(err)
			yield put(showAuthMessage(err))
		}
	})
}

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signInEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(authenticated(user.user.uid));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			removeAuth()
			yield put({
				type: SIGNOUT_SUCCESS
			})
			yield put(push('/auth/login'))
			/*const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}*/
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
  	fork(fetchUserData),
  	fork(auth),
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook),
		fork(checkoutToUser)
  ]);
}
