import React from "react";
import {Link} from "react-router-dom";
import {Menu, Grid} from "antd";
import IntlMessage from "../util-components/IntlMessage";
import {connect} from "react-redux";
import {SIDE_NAV_LIGHT, NAV_TYPE_SIDE} from "@/constants/ThemeConstant";
import utils from '@/utils'
import {onMobileNavToggle} from "@/redux/actions/Theme";

const {SubMenu} = Menu;
const {useBreakpoint} = Grid;

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

const setDefaultOpen = (key) => {
    let keyList = [];
    let keyString = "";
    if (key) {
        const arr = key.split("-");
        for (let index = 0; index < arr.length; index++) {
            const elm = arr[index];
            index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
            keyList.push(keyString);
        }
    }
    return keyList;
};

const SideNavContent = (props) => {
    let {sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle, navigationConfig} = props;
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
    const closeMobileNav = () => {
        if (isMobile) {
            onMobileNavToggle(false)
        }
    }

    const [state, setState] = React.useState([])

    const changeMenu = (key) => {
        if(state.includes(key)) {
            const filteredState = state.filter( item => item !== key);
            setState(filteredState);
        } else {
            setState([...state, key]);
        }
    }

    if (window.innerWidth < 767) {
        navigationConfig = navigationConfig.filter((item, index) => index === 0);
    }

    return (
        <Menu
            theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
            mode="inline"
            style={{height: "100%", borderRight: 0}}
            defaultSelectedKeys={[routeInfo?.key]}
            defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
            openKeys={state}
            onMouseLeave={(e) => setState([])}
            className={['my-custom-menu', hideGroupTitle ? "hide-group-title" : ""]}
        >
            {navigationConfig.map((menu) =>
                menu.submenu.length > 0 ? (
                    <SubMenu
                        key={menu.key}
                        icon={menu.icon || null}
                        title={setLocale(localization, menu.title)}
                        onTitleClick={() => changeMenu(menu.key)}
                    >
                        {menu.submenu.map((subMenuFirst) =>
                                subMenuFirst.submenu.length > 0 ? (
                                    <SubMenu
                                        icon={
                                            subMenuFirst.icon || null
                                        }
                                        key={subMenuFirst.key}
                                        title={setLocale(localization, subMenuFirst.title)}
                                    >
                                        {subMenuFirst.submenu.length > 0 ? subMenuFirst.submenu.map((subMenuSecond) => (
                                                <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                                                    <Link onClick={() => closeMobileNav()} to={subMenuSecond.path}/>
                                                    {subMenuSecond.icon || null}
                                                </Menu.Item>
                                            ))
                                            : <Menu.Item key={menu.key}>
                                                <span>{setLocale(localization, menu?.title)}</span>
                                                {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path}/> : null}
                                                {menu.icon}
                                            </Menu.Item>}
                                    </SubMenu>
                                ) : (
                                    <Menu.Item key={subMenuFirst.key}>
                                        <span>{setLocale(localization, subMenuFirst.title)}</span>
                                        <Link onClick={() => closeMobileNav()} to={subMenuFirst.path}/>
                                        {subMenuFirst.icon}
                                    </Menu.Item>
                                )
                        )}
                    </SubMenu>
                ) : (
                    <Menu.Item key={menu.key}>
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path}/> : null}
                        {menu.icon}
                    </Menu.Item>
                )
            )}
        </Menu>
    );
};

const TopNavContent = (props) => {
    const {topNavColor, localization, navigationConfig} = props;

    return (
        <Menu mode="horizontal" style={{backgroundColor: topNavColor}}>
            {navigationConfig.map((menu) =>
                menu.submenu.length > 0 ? (
                    <SubMenu
                        key={menu.key}
                        popupClassName="top-nav-menu"
                        title={
                            <span>
                {menu.icon}
                                <span>{setLocale(localization, menu.title)}</span>
              </span>
                        }
                    >
                        {menu.submenu.map((subMenuFirst) =>
                                subMenuFirst.submenu.length > 0 ? (
                                    <SubMenu
                                        key={subMenuFirst.key}
                                        icon={
                                            subMenuFirst.icon
                                        }
                                        title={setLocale(localization, subMenuFirst.title)}
                                    >
                                        {subMenuFirst.submenu.map((subMenuSecond) => (
                                            <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                                                <Link to={subMenuSecond.path}/>
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Menu.Item key={subMenuFirst.key}>
                                        {subMenuFirst.icon}
                                        <span>{setLocale(localization, subMenuFirst.title)}</span>
                                        <Link to={subMenuFirst.path}/>
                                    </Menu.Item>
                                )
                        )}
                    </SubMenu>
                ) : (
                    <Menu.Item key={menu.key}>
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link to={menu.path}/> : null}
                        {menu.icon}
                    </Menu.Item>
                )
            )}
        </Menu>
    );
};

const MenuContent = (props) => {
    return props.type === NAV_TYPE_SIDE ? (
        <SideNavContent {...props} />
    ) : (
        <TopNavContent {...props} />
    );
};

const mapStateToProps = ({theme, auth}) => {
    const {navigationConfig} = auth
    const {sideNavTheme, topNavColor} = theme;
    return {sideNavTheme, topNavColor, navigationConfig};
};

export default connect(mapStateToProps, {onMobileNavToggle})(MenuContent);
