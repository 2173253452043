import React, {useState, useRef, useMemo, useEffect, useCallback} from 'react'
import {IMaskInput} from "react-imask";
import {usePrevious} from "@/utils/hooks";

const prepareValue = value => Number.isNaN(+value) ? 0 : +value

export const CustomInputNumber = React.forwardRef((props, ref) => {

  const { scale, value, prefix, suffix, className, onFocus, onChange, onBlur, size, style, disabled, ...restProps } = props

  const scaleComputed = useMemo(() => scale || 2,[scale])

  const tempValueRef = useRef(null)

  const iMaskRef = useRef(null)

  useEffect( function offNativeAutocomplete() {
    // console.log('iMaskRef.current',iMaskRef.current)
    iMaskRef.current.element.autocomplete = "off"

  },[])

  const prevValue = usePrevious(value)

  const [isFocused, setIsFocused] = useState(false)

  const innerRef = useRef(null)

  const innerRefWrapper = useRef(null)

  const valuePrepared = useMemo(() => {
    tempValueRef.current = value
    return value === null || value === undefined || Number.isNaN(value) ? '' : value.toString()
  },[value])

  useEffect(() => {
    innerRefWrapper.current = {
      focus: innerRef.current.focus.bind(innerRef.current),
      blur: innerRef.current.blur.bind(innerRef.current),
      target: {
        value: null
      },
      select: innerRef.current.select.bind(innerRef.current)
    }
    ref && ref(innerRefWrapper.current)
  },[innerRef])

  const wrapperSizeClass = useMemo(() => {
    if (size === 'small') return 'ant-input-affix-wrapper-sm'
    else return ''
  },[size])

  const inputSizeClass = useMemo(() => {
    if (size === 'small') return 'ant-input-sm'
    else return ''
  },[size])

  const _onFocus = useCallback(e => {
    setIsFocused(true)
    if (typeof onFocus === 'function') {
      onFocus(e)
    }
  },[onFocus])

  const _onBlur = useCallback(e => {
    // console.log('_onBlur', e)
    // console.log('input-number: _onBlur', innerRefWrapper.current.target.value)
    setIsFocused(false)
    // e.target.value = innerRefWrapper.current.target.value
    if (typeof onBlur === 'function') {
      onBlur(tempValueRef.current || null)
    }
  },[onBlur])

  const _input = useCallback((...args) => {
    const [value, inputMask, inputEvent] = args
    // console.log('_input', value, inputMask, inputEvent)



    // fake input
    if (!inputEvent) {
      return
    }
    // inputEvent.target.value = value
    const valuePrepared = prepareValue(value)
    tempValueRef.current = valuePrepared
    if (typeof onChange === 'function') {
      console.log('onChange', valuePrepared, prevValue)
      if (valuePrepared !== prevValue) {
        onChange(valuePrepared)
      }
    }
    // innerRefWrapper.current.target.value = valuePrepared
  },[onChange, prevValue])

  const _onWrapperClick = useCallback(e => {
    innerRefWrapper.current.select()
  },[])

  const _onInputClick = useCallback(e => {
    e.stopPropagation()
    // console.log('_onInputClick', innerRefWrapper)
    innerRefWrapper.current.select()
    // console.log('_onClick', e)
  },[])

  const inp = <IMaskInput
              signed={restProps.signed || false}
              {...restProps}
              className={`ant-input ${disabled ? 'ant-input-disabled' : ''} ${inputSizeClass}`}
              mask={Number}
              scale={scaleComputed}
              ref={iMaskRef}
              disabled={disabled}
              value={valuePrepared}
              inputRef={el => innerRef.current = el}
              unmask={true}
              thousandsSeparator={" "}
              padFractionalZeros={true}
              onAccept={_input}
              normalizeZeros={true}
              radix=","
              onClick={_onInputClick}
              onFocus={_onFocus}
              onBlur={_onBlur}/>

  return (<span onClick={_onWrapperClick}
                style={style}
    className={`ant-input-affix-wrapper ${disabled ? 'ant-input-affix-wrapper-disabled' : ''} ${wrapperSizeClass} ${className} ${isFocused ? 'ant-input-affix-wrapper-focused' : ''}`}>
    { prefix && <span className={"ant-input-prefix"}>{ prefix }</span>}
    { inp }
    { suffix && <span className={"ant-input-suffix"}>{ suffix }</span>}
  </span>)
})
