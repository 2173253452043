import React, { useCallback, useState, useMemo } from "react";
import { Menu, Dropdown, Avatar, message } from "antd";
import { connect } from 'react-redux'
import {
	LogoutOutlined,
	ArrowLeftOutlined,
	UserOutlined,
	LockOutlined
} from '@ant-design/icons';
import Icon from '@/components/util-components/Icon';
import { signOut } from '@/redux/actions/Auth';
import { toggleCollapsedNavProfile, updateUserData } from "../../redux/actions";
import { AuthService } from "../../api/AuthService";
import { checkoutToRoot } from "@/redux/actions";
import { ModalCheckout } from "@/components/CheckoutModal";
import { getAuth, removeAuth, setAuth } from "@/utils/auth";
import { ModalPasswordUpdate } from "@/components/ModalUpdatePassword";
import { ModalUpdateUserData } from "@/components/ModalUpdateUserData"
import { UtilsUser } from "@/utils/user.js"
import { AvatarWithFallback } from "@/components/shared-components/avatar-fallback.js";

export const NavProfile = ({
	                           inSideBar, dropdownPlacement, toggleCollapsedNavProfile, navProfileCollapsed, signOut,
	                           userData, canCheckoutToRoot, userId, isRoot, updateUserData
                           }) => {
	const [passwordModal, updatePasswordModal] = useState(false)
	const [userModal, updateUserModal] = useState(false)
	const [checkoutModal, updateCheckoutModal] = useState(false)

	let getUserData = useCallback(() => ({ ...userData }), [userData])
	let sendUserData = useCallback((userData) => AuthService.updateUser(userId, userData)
		.then(newUserData => updateUserData(newUserData)), [userId, updateUserData])

	let updatePassword = useCallback(({ password, newPassword }) => AuthService.updatePassword(userId, {
		password,
		newPassword
	}), [userId])

	/*const uploadAvatar = useCallback(async file => {
	  try {
		const newAvatarPath = await AuthService.updateAvatarPath(userId,file)
		updateUserData({ ...userData, avatarPath: newAvatarPath })
	  } catch (e) {
		message.error(e.toString())
	  }
	}, [])*/

	const userMenu = useMemo(() => {
		const menu = []
		if (canCheckoutToRoot) {
			menu.push({
				title: "Вернуться",
				icon: ArrowLeftOutlined,
				key: 'checkout-to-root'
			})
		}
		if (isRoot) {
			menu.push({
				title: "Переключиться",
				icon: UserOutlined,
				key: 'checkout'
			})
		}
		menu.push({
			title: "Профиль",
			icon: UserOutlined,
			key: 'edit-profile'
		})
		menu.push({
			title: "Изменить пароль",
			icon: LockOutlined,
			key: 'update-password'
		})
		return menu
	}, [canCheckoutToRoot])

	const checkoutToRoot = () => {
		const rootAuthData = getAuth(window.localStorage, true)
		removeAuth(true)
		setAuth(rootAuthData, window.localStorage)
		window.location.reload()
	}

	const onMenuItemClick = ({ item, key }) => {
		toggleCollapsedNavProfile(true)
		const action = key
		switch (action) {
			case 'checkout-to-root':
				checkoutToRoot()
			case 'checkout':
				updateCheckoutModal(true)
				break;
			case 'edit-profile':
				updateUserModal(true)
				break;
			case 'update-password':
				updatePasswordModal(true)
				break;
			default:
				// console.log('no modal action')
		}
	}

	const profileImg = userData && UtilsUser.getAvatarPath(userData.avatarPath)
	const profileName = userData && UtilsUser.getUserFullName(userData)

	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-header">
				<div className="d-flex">
					<Avatar size={45} src={profileImg}/>
					<div className="pl-3">
						<h4 className="mb-0">{userData && userData.firstName}<br/>{userData && userData.lastName}</h4>
						{/*<span className="text-muted">{groups}</span>*/}
					</div>
				</div>
			</div>
			<div className="nav-profile-body">
				<Menu mode={'inline'} onClick={onMenuItemClick}>
					{userMenu.map((el, i) => {
						return (
							<Menu.Item key={el.key}>
                <span>
                  <Icon className="mr-3" type={el.icon}/>
                  <span className="font-weight-normal">{el.title}</span>
                </span>
							</Menu.Item>
						);
					})}
					<Menu.Item key={userMenu.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Выйти</span>
            </span>
					</Menu.Item>
				</Menu>
			</div>
		</div>
	);
	return (
		<>
			<Dropdown arrow placement={dropdownPlacement} overlay={profileMenu} trigger={["click"]}
			          visible={!navProfileCollapsed}
			          onVisibleChange={(v) => toggleCollapsedNavProfile(!v)}
			>
				<div className={[inSideBar ? 'sidebar-avatar__wrapper' : null]}>
					{inSideBar && <div className={'sidebar-avatar__name-wrapper'}><h5
						className={'sidebar-avatar__name'}>{profileName}</h5></div>}
					<AvatarWithFallback className={'sidebar-avatar__avatar'} src={profileImg}/>
				</div>
			</Dropdown>
			<ModalCheckout onCancel={() => updateCheckoutModal(false)} visible={checkoutModal}/>
			<ModalUpdateUserData userData={userData} userId={userId} visible={userModal} sendMethod={sendUserData}
			                     closeModal={() => updateUserModal(false)} getInitialValue={getUserData}/>
			<ModalPasswordUpdate visible={passwordModal} onCancel={() => updatePasswordModal(false)}
			                     sendMethod={updatePassword}/>
		</>
	);
}

const mapStateToProps = ({ theme, auth }) => {
	const { navProfileCollapsed } = theme;
	const { userData, authData, canCheckoutToRoot } = auth;
	const groups = authData.groups;
	const isRoot = groups && groups.includes('root')
	return { navProfileCollapsed, userData, groups, isRoot, canCheckoutToRoot, userId: userData && userData.id || null }
};

export default connect(mapStateToProps, {
	signOut,
	toggleCollapsedNavProfile,
	updateUserData,
	checkoutToRoot
})(NavProfile)
