import React from 'react';
import PropTypes from 'prop-types';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./toast.scss";

export const Toast = ({}) => {
    return (
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                // pauseOnFocusLoss
                draggable
                // pauseOnHover
            />
    );
};

Toast.propTypes = {}