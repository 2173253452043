import {
	DashboardOutlined, TableOutlined, ShoppingCartOutlined, UserOutlined, TeamOutlined, SelectOutlined,
	ProfileOutlined, DollarOutlined, ContainerOutlined, SolutionOutlined
} from '@ant-design/icons';
import React from 'react'
import { IS_DON_UGLI, IS_RWSP } from "@/utils/env.js"

const navigationConfig = [{
	key: 'home',
	path: '/home',
	title: 'home',
	icon: <DashboardOutlined/>,
	breadcrumb: false,
	submenu: []
}, {
	key: 'bdds',
	path: '/bdds',
	title: 'bdds',
	icon: <DollarOutlined/>,
	breadcrumb: false,
	submenu: []
},
	{
		key: 'bdr',
		path: '/bdr',
		title: 'bdr',
		icon: <DollarOutlined/>,
		breadcrumb: false,
		submenu: [],
		groups: ['bdr_ro', 'bdr_rw', 'root']
	},
	{
		key: 'gas-transfers',
		path: '/gas-transfers',
		title: 'gas-transfers',
		icon: <ContainerOutlined/>,
		breadcrumb: false,
		submenu: []
	},
	{
		key: 'crm-clients',
		path: '/crm/clients',
		title: 'crm.clients',
		icon: <SolutionOutlined/>,
		breadcrumb: false,
		submenu: []
	}, {
		key: 'crm-orders',
		path: '/crm/orders',
		title: 'crm.orders',
		icon: <ProfileOutlined/>,
		breadcrumb: false,
		submenu: []
	}, {
		key: 'crm-deliveries',
		path: '/crm/deliveries',
		title: 'crm.deliveries',
		icon: <ProfileOutlined/>,
		breadcrumb: false,
		submenu: [],
		groups: ['root', 'stock_ro', 'stock_rw']
	},
	{
		key: 'users',
		title: 'users',
		icon: <UserOutlined/>,
		breadcrumb: false,
		submenu: [
			{
				key: 'user',
				path: '/users',
				title: 'users',
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'history',
				path: '/users/history',
				title: 'history',
				breadcrumb: false,
				submenu: []
			},
		],
	},
	{
		key: 'counterparties',
		title: 'Контрагенты',
		icon: <UserOutlined/>,
		breadcrumb: false,
		path: '/counterparties',
		submenu: [],
	},
]

export const getNavigationConfig = gr => {
	const groups = gr || []
	const isClient = groups.includes('clients')
	const isManager = groups.includes('manager')

	if (IS_DON_UGLI || IS_RWSP) return [
		...(IS_DON_UGLI ? [{
			key: 'home',
			path: '/home',
			title: 'home',
			icon: <DashboardOutlined/>,
			breadcrumb: false,
			submenu: []
		}] : []),
		{
			key: 'bdds',
			path: '/bdds',
			title: 'bdds',
			icon: <DollarOutlined/>,
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'bdr',
			path: '/bdr',
			title: 'bdr',
			icon: <DollarOutlined/>,
			breadcrumb: false,
			submenu: [],
			groups: ['bdr_ro', 'bdr_rw', 'root']
		},
	].filter(route => !route.groups || route.groups.some(g => groups.includes(g)))

	if (isClient) return [
		{
			key: 'crm-orders',
			path: '/crm/orders',
			title: 'crm.orders',
			icon: <ProfileOutlined/>,
			breadcrumb: false,
			submenu: []
		}
	]

	if (isManager) return [
		{
			key: 'home',
			path: '/home',
			title: 'home',
			icon: <DashboardOutlined/>,
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'gas-transfers',
			path: '/gas-transfers',
			title: 'gas-transfers',
			icon: <ContainerOutlined/>,
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'crm-clients',
			path: '/crm/clients',
			title: 'crm.clients',
			icon: <SolutionOutlined/>,
			breadcrumb: false,
			submenu: []
		}, {
			key: 'crm-orders',
			path: '/crm/orders',
			title: 'crm.orders',
			icon: <ProfileOutlined/>,
			breadcrumb: false,
			submenu: []
		}]

	return navigationConfig.filter(route => !route.groups || route.groups.some(g => groups.includes(g)))
}

export default navigationConfig;
