import React, {useRef, useEffect, useReducer, useState, useCallback} from 'react'
import {Button, message, Typography} from 'antd'
import {CloseCircleOutlined, FileExcelOutlined, WarningFilled} from "@ant-design/icons";
import {wait} from "@gagydzer/my-axios-service/src/utils";

export function usePrevious(value) {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}

export function showErrorDefault(e, key) {
  console.error(e)

  const content = (<Typography.Text type={"danger"}>
    <WarningFilled style={{ top: '0' }} type={"danger"}/>
    <span>{e.toString()}</span>
  </Typography.Text>)

  message.open({
    duration: 3,
    content: content,
    key
  })
}

export function useCalculateTableWrapperSize(className) {

  const [verticalScrollWrapperSize, updateVerticalScrollWrapperSize] = useState(0)

  useEffect(() => {

    const tableEl = className
      ? document.querySelector('.' + className)
      : document.querySelector('.ant-table-wrapper')

    /**
     *
     * @type {Element}
     */
    const tableHeader = tableEl.querySelector('.ant-table-header')
    const headerHeight = tableHeader.offsetHeight

    const wrapperHeight = tableEl.offsetHeight

    console.log('useCalculateTableWrapperSize', wrapperHeight, headerHeight)

    updateVerticalScrollWrapperSize(Math.floor(wrapperHeight - headerHeight))

  }, [])

  return verticalScrollWrapperSize

}

export function useFetchWithStatus(method) {
  const [ loading, setLoading ] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)
    await method()
    setLoading(false)
  },[method, loading])

  return { loading, fetch }
}

export function useFetch(method) {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    async function fetch() {
      setIsLoading(true)
      try {
        const data = await method();
        console.log('useFetch', data)
        setData(data)
      } catch (e) {
        showErrorDefault(e)
        setData(null)
      }
      setIsLoading(false)
    }

    fetch()
  },[method])

  return { loading: isLoading, data }
}


export function useStubFetch() {
  const [ loading, setLoading ] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)
    await wait()
    setLoading(false)
  },[loading])

  return { loading, fetch }

}

export function ExcelDownloadButton({ method }) {
  const [loading, setLoading] = useState(false)

  const func = useFileDownload(method)

  const onClick = useCallback(async () => {
    setLoading(true)
    await func()
    setLoading(false)
  })

  return <Button type="primary" size={"small"} icon={<FileExcelOutlined />} loading={loading} onClick={onClick}/>
}

export function useFileDownload(method = wait) {
  return useCallback(async () => {
    console.log('useFileDownload')
    const hide = message.loading('Загрузка файла..', 0)
    try {
      await wait(1000)
      await method()
      hide()
      message.success('Файл загружен', 2)
    } catch (e) {
      showErrorDefault(e)
    }
  },[method])
}

