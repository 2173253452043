import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
	SIGNIN_WITH_FACEBOOK_AUTHENTICATED, AUTH_INIT, AUTH_UPDATE, UPDATE_USER_DATA, CHECKOUT_TO_ROOT
} from '../constants/Auth';

import { getAuth } from "../../utils/auth";
import {getRootAuth, removeRootAuth, setAuth} from "@/utils/auth";
import {getNavigationConfig} from "@/configs/NavigationConfig";

const initAuth = () => {
	const authData = getAuth(window.localStorage)
	const authRootData = getAuth(window.localStorage, true)
	console.log('authRootData', authRootData)
	return {
		authData,
		canCheckoutToRoot: !!authRootData
	}
}

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
	userData: null,
	navigationConfig: [],
	...initAuth()
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case CHECKOUT_TO_ROOT: {
			const rootAuthData = getRootAuth(window.localStorage);
			removeRootAuth();
			setAuth(rootAuthData,window.localStorage)
			window.location.reload()
			return
		}
		case UPDATE_USER_DATA:
			return {
				...state,
				loading: false,
				redirect: '/',
				userData: action.payload.userData
			}
		case AUTH_UPDATE:
			return {
				...state,
				loading: false,
				redirect: '/',
				authData: action.payload.authData
			}
		case AUTH_INIT:
			return {
				...state,
				loading: false,
				redirect: '/',
				userData: action.payload.userData,
				authData: action.payload.authData,
				navigationConfig: getNavigationConfig(action.payload.authData.groups),
				canCheckoutToRoot: action.payload.canCheckoutToRoot
			}
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: '/',
				userData: action.payload.userData,
				authData: action.payload.authData,
				navigationConfig: getNavigationConfig(action.payload.authData.groups),
				canCheckoutToRoot: action.payload.canCheckoutToRoot
			}
		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				authData: null,
				userData: null,
				redirect: '/',
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: action.token
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		default:
			return state;
	}
}

export default auth
