import React from 'react';
import PropTypes from 'prop-types';
import {TextBubble} from '@/components/util-components/TextBubble/TextBubble';
import {Input} from '@/components/forms/Inputs/Input';
import rolesJSON from '@/content/users/roles.json';
import {useForm} from 'react-hook-form';
import {FormLayout} from "@/components/layout-components/InfoLayout/InfoComponents/FormLayout";
import {CardLayout} from "@/components/layout-components/InfoLayout/InfoComponents/CardLayout";

export const InfoLayout = ({
                               blueprint,
                               items,
                               closeButton,
                               editButton,
                               resetButton,
                               userId,
                               editFunc,
                               addFunc
                           }) => {

    const {register, handleSubmit, formState: {errors}, getValues, control, reset, setValue} = useForm();

    const getCell = (info, value, key) => {
        const {type} = info;

        const setSpan = (text) => {
            let output = text;
            if (typeof text?.getMonth === 'function') {
                output = text.toLocaleDateString('ru', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                });
            }

            if (value === "groupId") {

                if (Array.isArray(items[value])) {
                    output = items[value].map(item => {
                        for (let i = 0; i < items[value].length; i++) {
                            if (item === rolesJSON[i].value) {
                                return rolesJSON[i].text
                            }
                        }
                        return
                    });
                    output = output.filter(item => item).join(", ");
                } else {
                    output = rolesJSON?.[items[value]]?.text || items[value];
                }
            }

            return output;
        }

        switch (type) {
            case ('boolean'):
                return <TextBubble key={key} bool={items ? items[value] || '' : ''}/>;
            case ('input'):
                return <Input register={register}
                              control={control}
                              errors={errors}
                              setValue={setValue}
                              getValues={getValues}
                              type={info.inputType}
                              className={`max-w-33 w-full`}
                              input={blueprint.predefinedValues ?
                                  {...info.input, value: items ? items[value] : ''}
                                  : info.input}
                />
            default:
                return <span className={blueprint.type === 'form' && 'block pt-0.5r'}
                             key={key}>{(items ? setSpan(items[value] || ' ') : ' ') + ' '}</span>;
        }
    }

    const getHint = (info, keyHint) => {
        const {value, hint, hintType, hintTrue, hintFalse} = info;
        let text = hint;

        switch (hintType) {
            case ('value'):
                text = items ? items[hint] : hint;
                break;
            case ('role'):
                text = Object.values(rolesJSON).find(option => option.value === keyHint)?.hint || rolesJSON[keyHint]?.hint;
                break;
            case ('boolean'):
                text = items ? items[hint] ? hintTrue : hintFalse : hintFalse;
                break;
            case ('compare'):
                text = items ? items[hint]?.toString()?.trim() === items[value[0]]?.toString()?.trim() ? hintTrue : hintFalse : hintFalse;
                break;
            default:
                break;
        }
        return text;
    }

    const generateChildren = () => {
        return (
            blueprint.data.map(row => {

                    const {hint, className, label, value, input} = row;
                    const {type} = blueprint;
                    const classList = hint ? 'flex flex-col' : '';
                    return (
                        <>
                            {type !== 'form' ?
                                <div className={'text-base font-bold text-gray-800'}>{label}</div>
                                :
                                <label className={'text-base font-bold text-gray-800 flex pt-0.5r'}
                                       htmlFor={input?.id}>{input?.label || label}</label>
                            }
                            <div className={`text-base text-gray-900 ${classList}`}>
                                <div className={className}>
                                    {value?.map((value, index) => getCell(row, value, index))}
                                </div>
                                {hint && ((items && items[value[0]]) || row.input) &&
                                <span className="text-xs text-gray-400 ">{getHint(row, items[value[0]])}</span>}
                            </div>
                        </>
                    )
                }
            )
        )
    };

    const onSubmit = (data) => {
        if (blueprint.predefinedValues) {
            data.id = userId;
            editFunc(data);
            !blueprint.predefinedValues && reset();
        } else {
            addFunc(data);
            !blueprint.predefinedValues && reset();
        }
    }


    return blueprint.type === 'form'
        ? <FormLayout onSubmit={onSubmit}
                      userId={userId}
                      generateChildren={generateChildren}
                      closeButton={closeButton}
                      resetButton={resetButton}
                      items={items}
                      blueprint={blueprint}
                      handleSubmit={handleSubmit}
                      reset={reset}/>
        : <CardLayout onSubmit={onSubmit}
                      generateChildren={generateChildren}
                      closeButton={closeButton}
                      editButton={editButton}
                      resetButton={resetButton}
                      items={items}
                      blueprint={blueprint}
                      handleSubmit={handleSubmit}
                      reset={reset}/>

};

InfoLayout.defaultProps =
    {
        className: ''
    };

InfoLayout.propTypes = {};
