import React from 'react';
import PropTypes from 'prop-types';
import roles from '@/content/users/roles.json';
import { TitlePage } from "@/components/shared-components/TitlePage/TitlePage";
import { InfoLayout } from "@/components/layout-components/InfoLayout/InfoLayout";
import { fetchAddUser, fetchEditUser, fetchGetUser } from "@/redux/actions/Users";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LoaderWrapper from "@/components/util-components/LoaderWrapper/LoaderWrapper";
import { withToast } from "@/hoc/withToast";

class UserCreate extends React.Component {

  constructor(props) {
    super(props);

    this.id = +props.match.params.id;

    // TEMPORARY

    this.blueprint = {
      type: "form",
      predefinedValues: this.id,
      data: [
        {
          "type": "input",
          "inputType": "text",
          "value": ["lastName"],
          "input": {
            "id": 0,
            "name": "lastName",
            "label": "Фамилия",
            "validator": "name",
            "placeholder": "Фамилия",
            "errorMessage": "Введите фамилию",
            "required": false,
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["firstName"],
          "input": {
            "id": 1,
            "name": "firstName",
            "label": "Имя",
            "validator": "name",
            "placeholder": "Имя",
            "errorMessage": "Введите имя",
            "required": true,
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["patronymic"],
          "input": {
            "id": 2,
            "name": "patronymic",
            "label": "Отчество",
            "placeholder": "Отчество",
            "validator": "name",
            "required": false,
            "errorMessage": "Введите отчество",
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["position"],
          "input": {
            "id": 3,
            "name": "position",
            "label": "Должность",
            "placeholder": "Должность",
            "validator": "name",
            "required": false,
            "errorMessage": "Введите название должности",
            "value": ""
          },
        },
        {
          "type": "input",
          "value": ["groupId"],
          "inputType": "multiSelect",
          "input": {
            "id": 4,
            "name": "groupId",
            "label": "Роль",
            "placeholder": "Роль",
            "required": true,
            "value": [],
            "options": roles,
          }
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["email"],
          "input": {
            "id": 5,
            "name": "email",
            "label": "Email",
            "validator": "email",
            "type": "email",
            "placeholder": "Email",
            "errorMessage": "Введите email адрес",
            "required": true,
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["phone"],
          "input": {
            "id": 6,
            "name": "phone",
            "label": "Телефон",
            "validator": "phone",
            "type": "tel",
            "placeholder": "Телефон",
            "errorMessage": "Введите номер телефона",
            "required": false,
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["password"],
          "input": {
            "id": 6,
            "name": "password",
            "label": "Пароль",
            "validator": "password",
            "type": "password",
            "placeholder": "Пароль",
            "errorMessage": "Поле обязательно для ввода",
            "required": !this.id,
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "checkbox",
          "value": ["access"],
          "input": {
            "id": 7,
            "name": "access",
            "label": "Доступ",
            "placeholder": "Разрешить доступ в систему",
            "type": "checkbox",
            "value": ""
          },
        },
      ]
    };

    if (this.id) {
      this.blueprint.data.push({
          "label": "Cоздан",
          "value": ["created"]
        },
        {
          "label": "Обновлен",
          "value": ["updatedAt"]
        },
        {
          "label": "Последний вход",
          "value": ["lastVisited"]
        });
    }
  }

  componentDidMount() {
    if (this.id) {
      this.props.fetchGetUser(this.id);
    }
  }

  setURL = () => {
    let { prevURL } = this.props;

    if (prevURL.includes("users")
      || prevURL.includes("history")) {
      return prevURL
    }

    return '/users'
  }

  render() {

    const { user, fetchEditUser, fetchAddUser, isSuccess, isLoaded } = this.props;

    return (
      <div>
        {isSuccess && <Redirect to={{
          pathname: "/users",
        }} />}

        <TitlePage title={`${this.id ? "Редактирование" : "Создание"} сотрудника`}
                   className={"mr-auto"} />
        <div className={"py-6 flex flex-column min-w-full sm:px-2.5 lg:px-8"}>

          <LoaderWrapper condition={isLoaded || !this.id}>

            <InfoLayout blueprint={this.blueprint}
                        items={user}
                        userId={this.id}
                        closeButton={this.setURL()}
                        className={"my-8"}
                        editFunc={fetchEditUser}
                        addFunc={fetchAddUser} />

          </LoaderWrapper>

        </div>
      </div>
    );

  }
}

UserCreate.propTypes = {};

const mapStateToProps = ({ users, global }) => (
  {
    user: users.user,
    isLoaded: global.isLoaded,
  }
);

const mapDispatchToProps = (dispatch) => ({
  fetchGetUser: (e) => dispatch(fetchGetUser(e)),
  fetchAddUser: (e) => dispatch(fetchAddUser(e)),
  fetchEditUser: (e) => dispatch(fetchEditUser(e)),
});

export default withToast(connect(mapStateToProps, mapDispatchToProps)(UserCreate));
