import React, {useCallback, useState} from 'react'
import {PaymentsList} from "@/components/payments-list";
import {Modal} from "antd";

export const usePaymentsModal = () => {

  const [ visible, setVisible ] = useState(false)

  const [ modalData, setModalData ] = useState({ title: '', payments: [] })

  const hideModal = useCallback(() => setVisible(false),[])

  const clearPaymentsData = useCallback(() => setModalData({ title: '', payments: [] }),[])

  const showPayments = useCallback(({ title, payments }) => {
    setModalData({ title, payments })
    setVisible(true)
  },[])

  const PaymentsModal = (<Modal
    maskClosable={false}
    footer={null}
    width={'60%'}
    title={modalData.title}
    onCancel={hideModal}
    afterCloseModal={clearPaymentsData}
    visible={visible}
  >
    <PaymentsList payments={modalData.payments}/>
  </Modal>)

  return {
    showPayments, PaymentsModal
  }
}
