import React, {useCallback, useMemo} from 'react'
import { OrderBlockTitle } from "./order-block-title"
import {ClientsApi} from "@/api/clients";
import {connect} from "react-redux";
import {checkoutToRoot, signOut, toggleCollapsedNavProfile, updateUserData} from "@/redux/actions";
import {NavProfile} from "@/components/layout-components/NavProfile";
import {Button, Col, DatePicker, Form, Image, Row, Space, Typography} from "antd";
import {InputSelectRemote} from "@/components/shared-components/input-select-remote";
import {CustomInputNumber} from "@/components/shared-components/input-number";
import {getFormattedValuteDefault} from "@/utils/numeral";
import {useDocumentsDownload} from "@/views/app-views/crm/requisitions/order-form/hooks";
import {PaperClipOutlined} from "@ant-design/icons";
import {showErrorDefault, useFetchWithStatus, useStubFetch} from "@/utils/hooks";
import {CrmService} from "@/api/crm-orders";
import moment from "moment";

const Text = Typography.Text

export const OrderShipmentBlock = ({ order }) => {

  const docTtn = useDocumentsDownload(order, 'ttn-documents')
  const docZalivka = useDocumentsDownload(order, 'zalivka-documents')
  const docAcceptance = useDocumentsDownload(order, 'acceptance-documents')

  const netto = useMemo(() => {

    const { shipmentGross, shipmentContainerWeight } = order

    if (!shipmentGross || !shipmentContainerWeight) return null

    else return shipmentGross - shipmentContainerWeight
  },[order])

  return <div className={"mb-4"}>
    {
      order.status === 'shipped'
        ? (<OrderBlockTitle status={'shipped'} title="Отгружен" dateTime={order.shipmentWeighing2DateTime}
                            highlight={true}/>)
        : (<OrderBlockTitle status={'on-shipment'} title="Ожидание отгрузки" dateTime={order.shipmentStarted}
                            highlight={true}/>)
    }
    { order.status !== 'on-shipment' && <Row gutter={8}>
      <Col sm={12}>
        <div className={"p-3 mb-2"} style={{backgroundColor: 'white', borderRadius: '0.625rem'}}>
          <Row gutter={16}>
            <Col sm={12}>
              <Form.Item name="shipmentWeighing1DateTime" label="Дата">
                <DatePicker disabled size={"small"}
                            style={{width: '100%'}}
                            placeholder={"Введите дату"}
                            format="DD.MM.YYYY HH:mm"
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="shipmentContainerWeight" label="Тара">
                <CustomInputNumber disabled size={"small"} suffix={"кг"}  style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>
      <Col sm={12}>
        <div className={"p-3 mb-2"} style={{backgroundColor: 'white', borderRadius: '0.625rem'}}>
          <Row gutter={16}>
            <Col sm={12}>
              <Form.Item name="shipmentWeighing2DateTime" label="Дата">
                <DatePicker disabled size={"small"}
                            style={{width: '100%'}}
                            placeholder={"Введите дату"}
                            format="DD.MM.YYYY HH:mm"
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="shipmentGross" label="Брутто">
                <CustomInputNumber disabled size={"small"} suffix={"кг"}  style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>}
    { order.status !== 'on-shipment' && <div className={"p-3 mb-2"} style={{backgroundColor: 'white', borderRadius: '0.625rem'}}>
      <Row gutter={16}>
        <Col sm={8}>
          <Form.Item name="shipmentPressure" label="Давление (налив)">
            <CustomInputNumber disabled size={"small"} suffix={(<><span>кг/см</span> <sup>2</sup></>)}  style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item name="shipmentTemperature" label="Температура воздуха">
            <CustomInputNumber disabled size={"small"} suffix={"°C"}  style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item name="shipmentDensity" label="Плотность">
            <CustomInputNumber disabled size={"small"} suffix={(<><span>кг/м</span> <sup>3</sup></>)}  style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </div>}
    { order.status !== 'on-shipment' && <div className={"p-3 mb-2"} style={{backgroundColor: 'white', borderRadius: '0.625rem'}}>
      <Row gutter={16}>
        <Col sm={12}>
          <h5>
            Документы
          </h5>
          <Space direction={"vertical"}>
            <Button size={"small"} block className={"download-order-button"} loading={docTtn.downloading}
                    icon={<PaperClipOutlined/>} type="link"
                    onClick={docTtn.download}>
              Товарно-транспортная накладная.pdf
            </Button>
            <Button size={"small"} block className={"download-order-button"} icon={<PaperClipOutlined/>} type="link"
                    loading={docZalivka.downloading} onClick={docZalivka.download}>
              Акт заливки.pdf
            </Button>
            <Button size={"small"} block className={"download-order-button"} icon={<PaperClipOutlined/>} type="link"
                    loading={docAcceptance.downloading} onClick={docAcceptance.download}>
              Акт приема-передачи товара.pdf
            </Button>
          </Space>
        </Col>
        <Col sm={12} className={"flex-column justify-content-start align-items-center"}>
          <h5>Отгруженно, нетто</h5>
          {netto ? <h2>
            {getFormattedValuteDefault(netto)} кг
          </h2> : <h2>-</h2>
          }
        </Col>
      </Row>
    </div>}
  </div>

}
