import {bddsMonthInnerKeys} from "@/views/app-views/bdds";
import {getFormattedValuteDefault} from "@/utils/numeral";
import {IS_DON_UGLI} from "@/utils/env";

export class BddsUtils {

  static innerKeys = ['plan','actual']

  static findInTree(items, codeList) {
    return codeList.reduce( (currentChildren, code, i) => {
      const result = currentChildren.find( el => el.code === code )
      if (i === codeList.length - 1) return result
      else return result.children
    },items)
  }

  static getDaysSums(days) {
    return Object.values(days).reduce((acc, { actual, plan, payments}) => {
      acc.actual += (actual || 0)
      acc.plan += (plan || 0)
      if (payments && payments.length) acc.payments.push(...payments)
      return acc
    },{ actual: 0, plan: 0, payments: [] })
  }

  static getMonthSums(months) {
    return Object.values(months).reduce( (acc, month) => {
      acc.actual += (month.actual || 0)
      acc.plan += (month.plan || 0)
      if (month.payments) acc.payments.push(...month.payments)
      return acc
    },{ actual: 0, plan: 0, payments: [] })
  }

  static prepareBddsItems(items, currentYear, codeArray = [], parentCodesString = '', level = 1) {
    return items.reduce((acc, item) => {
      const {children, ...entry} = item
      //const children = entry.children
      //entry.ch = children
      //const { children, ...entry } = item
      // \xa0\xa0
      let codeString = parentCodesString ? `${parentCodesString}.${item.code}` : `${item.code}`
      let namePrefix = new Array(level - 1).fill('\xa0\xa0\xa0\xa0').join('')
      entry.tooltipName = `${codeString} ${entry.name}`
      entry.nameOriginal = entry.name
      entry.name = `${namePrefix}${codeString} ${entry.name}`
      entry.codeString = codeString
      entry.codeArray = [...codeArray, entry.code]
      entry.level = level

      // форматируем числа


      if (children && children.length) {

        entry.children = BddsUtils.prepareBddsItems(children, currentYear, entry.codeArray, codeString, level + 1)

        if (entry.days) {

          let dayKeys = Object.keys(entry.days)

          const { days, month } = entry.children.reduce((acc, child) => {

            dayKeys.forEach(day => {
              const { plan, actual, payments } = child.days[day]

              acc.month.plan += (plan || 0)
              acc.month.actual += (actual || 0)
              if (!acc.days[day]) acc.days[day] = { plan: 0, actual: 0, payments: [] }

              acc.days[day].plan += (plan || 0)
              acc.days[day].actual += (actual || 0)
              if (payments && payments.length) acc.days[day].payments.push(...payments)
            })

            return acc
          }, { days: {}, month: { plan: 0, actual: 0 }})

          entry.days = days
          entry.month = month

        } else {

          const {year, months} = entry.children.reduce((acc, child) => {
            for (let monthNumber in entry.months) {
              acc.months[monthNumber].plan += child.months[monthNumber].plan
              acc.months[monthNumber].actual += child.months[monthNumber].actual
              if (child.months[monthNumber].payments) {
                acc.months[monthNumber].payments.push(...child.months[monthNumber].payments)
                acc.year.payments.push(...child.months[monthNumber].payments)
              }
              if (entry.months[monthNumber].weeks) {
                for (let weekNumber in entry.months[monthNumber].weeks) {
                  if (!acc.months[monthNumber].weeks[weekNumber]) {
                    acc.months[monthNumber].weeks[weekNumber] = { actual: 0, payments: [] }
                  }
                  acc.months[monthNumber].weeks[weekNumber].actual += child.months[monthNumber].weeks[weekNumber].actual
                  if (child.months[monthNumber].weeks[weekNumber].payments) acc.months[monthNumber].weeks[weekNumber].payments.push(...child.months[monthNumber].weeks[weekNumber].payments)
                }
              }
            }
            acc.year.actual += (child.year.actual || 0)
            acc.year.plan += (child.year.plan || 0)
            return acc
          }, {
            year: {actual: 0, plan: 0, payments: []},
            months: Object.fromEntries(Object.keys(entry.months).map(monthNumber => ([monthNumber, {plan: 0, actual: 0, payments: [],
              weeks: {} }])))
          })

          entry.year = year
          entry.months = months

        }
      } else {
        if (entry.days) {
          entry.month = BddsUtils.getDaysSums(entry.days)
        } else {
          entry.year = BddsUtils.getMonthSums(entry.months)
        }
      }



      if (entry.days) {

        for (let dayNumber in entry.days) {
          for (let i in BddsUtils.innerKeys) {
            const innerKey = BddsUtils.innerKeys[i]
            const value = entry.days[dayNumber][innerKey]
            entry.days[dayNumber][`${innerKey}-formatted`] = getFormattedValuteDefault(value, false, IS_DON_UGLI)
          }
        }

        // prepare year data
        for (let i in BddsUtils.innerKeys) {
          const innerKey = BddsUtils.innerKeys[i]
          const value = entry.month[innerKey]
          entry.month[`${innerKey}-formatted`] = getFormattedValuteDefault(value, false, IS_DON_UGLI)
        }

      } else {

        for (let monthNumber in entry.months) {
          for (let i in BddsUtils.innerKeys) {
            const innerKey = BddsUtils.innerKeys[i]
            const value = entry.months[monthNumber][innerKey]
            entry.months[monthNumber][`${innerKey}-formatted`] = getFormattedValuteDefault(value, false, IS_DON_UGLI)
          }
          if (entry.months[monthNumber].weeks) {
            for (let weekNumber in entry.months[monthNumber].weeks) {
              const value = entry.months[monthNumber].weeks[weekNumber].actual
              entry.months[monthNumber].weeks[weekNumber][`actual-formatted`] = getFormattedValuteDefault(value, false, IS_DON_UGLI)
            }
          }
        }

        // prepare year data
        for (let i in BddsUtils.innerKeys) {
          const innerKey = BddsUtils.innerKeys[i]
          const value = entry.year[innerKey]
          const hasValue = value !== null && value !== undefined
          entry.year[`${innerKey}-formatted`] = getFormattedValuteDefault(value, false, IS_DON_UGLI)
        }

      }

      acc.push(entry)
      return acc
    }, [])
  }
}
