import React from 'react';
import PropTypes from 'prop-types';
import { TitlePage } from "@/components/shared-components/TitlePage/TitlePage";
import { InfoLayout } from "@/components/layout-components/InfoLayout/InfoLayout";
import { connect } from "react-redux";
import { fetchAddCounterparties, fetchEditCounterparties, fetchGetCounterparty } from "@/redux/actions/Counterparties";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import {setErrorMessage, setIsLoadedGlobal, setSuccess} from "@/redux/actions/global";
import LoaderWrapper from "@/components/util-components/LoaderWrapper/LoaderWrapper";
import {withToast} from "@/hoc/withToast";

class CounterpartyCreate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.id = +props.match.params.id;
    // TEMPORARY
    this.blueprint = {
      type: "form",
      predefinedValues: !!this.id || this.id.toString() === "0",
      data: [
        {
          "type": "input",
          "value": ["type"],
          "inputType": "select",
          "input": {
            "id": 4,
            "name": "type",
            "label": "Тип",
            "placeholder": "Вид контрагента",
            "required": true,
            "value": "",
            "options": [
              {
                "value": "Юридическое лицо",
                "text": "Юридическое лицо",
              },
              {
                "value": "Физическое лицо",
                "text": "Физическое лицо",
              },
            ]
          }
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["name"],
          "input": {
            "id": 1,
            "name": "name",
            "required": true,
            "label": "Название",
            "placeholder": "Название",
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["fullName"],
          "input": {
            "id": 1,
            "name": "fullName",
            "label": "Полное название",
            "required": true,
            "placeholder": "Полное название",
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["country"],
          "input": {
            "id": 2,
            "name": "country",
            "label": "Страна регистрации",
            "placeholder": "Страна регистрации",
            "required": true,
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["inn"],
          "input": {
            "id": 3,
            "name": "inn",
            "label": "ИНН",
            "required": true,
            "placeholder": "ИНН",
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["kpp"],
          "input": {
            "id": 5,
            "name": "kpp",
            "required": true,
            "label": "КПП",
            "placeholder": "КПП",
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["ogrn"],
          "input": {
            "id": 6,
            "name": "ogrn",
            "label": "ОГРН",
            "required": true,
            "placeholder": "ОГРН",
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["checkingAccount"],
          "input": {
            "id": 7,
            "name": "checkingAccount",
            "label": "Расчетный счет",
            "placeholder": "Расчетный счет",
            "required": true,
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["correspondentAccount"],
          "input": {
            "id": 7,
            "name": "correspondentAccount",
            "label": "Корреспондентский счет",
            "required": true,
            "placeholder": "Корреспондентский счет",
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["address"],
          "input": {
            "id": 7,
            "name": "address",
            "label": "Юр. адрес",
            "required": true,
            "placeholder": "Юр. адрес",
            "value": ""
          },
        },
        {
          "type": "input",
          "inputType": "text",
          "value": ["realAddress"],
          "input": {
            "id": 7,
            "name": "realAddress",
            "label": "Физ. адрес",
            "required": true,
            "placeholder": "Физ. адрес",
            "value": ""
          },
        },
        {
          "label": "Последняя синхронизация",
          "value": ["lastActivity"]
        },
        // {
        //     "label": "Обновлен",
        //     "value": ["updated"]
        // },
        // {
        //     "label": "Последний вход",
        //     "value": ["last_login"]
        // },
      ]
    }
  }

  componentDidMount() {
    if(this.id) {
      this.props.fetchGetCounterparty(this.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.counterparty !== this.props.counterparty) {
      if (this.props.isSuccess) {
        toast.success(`Контрагент ${this.id ? "изменен " : "создан"}`)
        this.props.setSuccess(false)
      }
    }
  }

  setURL = () => {
    let prevURL = this.props.prevURL;
    if(prevURL.includes("counterparties") || prevURL.includes("history")) {
      return prevURL
    } else {
      return '/counterparties'
    }
  }

  render() {

    const {
      isSuccess,
      counterparty,
      fetchAddCounterparties,
      fetchEditCounterparties
    } = this.props;

    return (
      <div>
        {isSuccess && <Redirect to={{ pathname: "/counterparties" }} />}

        <TitlePage title={`${this.id ? "Редактирование " : "Создание"} контрагента`}
                   className={"mr-auto"} />

        <div className={"py-6 flex flex-column min-w-full sm:px-2.5 lg:px-8"}>

          <LoaderWrapper condition={this.props.isLoaded || !this.id}>

          <InfoLayout blueprint={this.blueprint}
                      items={counterparty}
                      userId={this.id}
                      addFunc={fetchAddCounterparties}
                      editFunc={fetchEditCounterparties}
                      closeButton={this.setURL()}
                      className={"my-8"} />

          </LoaderWrapper>

        </div>
      </div>
    );

  }
}

CounterpartyCreate.propTypes = {};

const mapStateToProps = ({ counterparties, global }) => (
  {
    counterparty: counterparties?.counterparty,
    isLoaded: global?.isLoaded,
  }
);

const mapDispatchToProps = (dispatch) => ({
  fetchGetCounterparty: (e) => dispatch(fetchGetCounterparty(e)),
  fetchAddCounterparties: (e) => dispatch(fetchAddCounterparties(e)),
  fetchEditCounterparties: (e) => dispatch(fetchEditCounterparties(e)),
  setIsLoadedGlobal: (e) => dispatch(setIsLoadedGlobal(e)),
});

export default withToast(connect(mapStateToProps, mapDispatchToProps)(CounterpartyCreate));
