import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  AUTH_INIT,
  LOG_IN,
  UPDATE_USER_DATA,
  CHECKOUT_TO_ROOT,
  CHECKOUT_TO_USER,
  AUTH_UPDATE
} from '../constants/Auth';

export const checkoutToUser = ({userId}) => {
  return {
    type: CHECKOUT_TO_USER,
    payload: userId
  }
}

export const logIn = ({ email, password }) => {
  return {
    type: LOG_IN,
    payload: { email, password }
  }
}

export const updateUserData = (userData) => {
  return {
    type: UPDATE_USER_DATA, payload: {userData}
  }
}


export const authInit = ({ userData, authData, canCheckoutToRoot }) => {
  return {
    type: AUTH_INIT, payload: {userData, authData, canCheckoutToRoot }
  }
}

export const authUpdate = (authData) => {
  return {
    type: AUTH_UPDATE, payload: { authData }
  }
}

export const checkoutToRoot = () => {
  return {
    type: CHECKOUT_TO_ROOT
  }
}

export const authenticated = ({ userData, authData, canCheckoutToRoot }) => {
  return {
    type: AUTHENTICATED,
    payload: {
      userData, authData, canCheckoutToRoot
    }
  }
};

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user
  }
};

export const signOut = () => {
  return {
    type: SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK
  };
};

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
