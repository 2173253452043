import React from 'react';
import {connect} from "react-redux";
import {setErrorMessage, setPrevUrl, setSuccess} from "@/redux/actions/global";
import {toast} from "react-toastify";
import {Result} from "antd";

const mapStateToPropsWithToast = ({global}) => ({
  errorMessage: global.errorMessage,
  isSuccess: global.isSuccess,
  prevURL: global.prevURL
});

const mapDispatchToPropsWithToast = (dispatch) => ({
  setErrorMessage: (e) => dispatch(setErrorMessage(e)),
  setSuccess: (e) => dispatch(setSuccess(e)),
  setPrevUrl: (e) => dispatch(setPrevUrl(e)),
});

export const withToast = (Component) => {
  class ToastWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isMobile: window.innerWidth < 767
      }
    }

    componentDidMount() {
      window.addEventListener("resize", () => {
        this.setState({isMobile: window.innerWidth < 767})
      });
    }

    componentDidUpdate(prevProps) {
      if (prevProps.errorMessage !== this.props.errorMessage) {
        if (this.props.errorMessage) {
          toast.error(this.props.errorMessage);
          this.props.setErrorMessage("");
        }
      }
      if (prevProps.isSuccess !== this.props.isSuccess) {
        if (this.props.isSuccess) {
          toast.success(this.props.isSuccess);
          this.props.setSuccess("");
        }
      }
    }

    componentWillUnmount() {
      window.removeEventListener("resize", () => {
        this.setState({isMobile: window.innerWidth < 767})
      })
      this.props.setPrevUrl(this.props.location.pathname + (this.props.location.search.length > 1 ? this.props.location.search : ""))
    }

    render() {
      return this.state.isMobile ? <div className={"flex justify-center items-center"}>
          <Result className={""}
                  title="ERP доступна только в браузере на компьютере или планшете"/>
        </div>
        : <Component parseURLToObj={this.parseURLToObj} {...this.props} />
    }
  }

  return connect(mapStateToPropsWithToast, mapDispatchToPropsWithToast)(ToastWrapper);
}
