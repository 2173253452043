import React, {useCallback, useState } from 'react'
import {Modal, Form, Input, Typography} from 'antd'
import {wait} from "@gagydzer/my-axios-service/src/utils";

export const ModalPasswordUpdate = ({ visible, sendMethod, onCancel }) => {
  const [form] = Form.useForm()

  const [formErrors,updateFormErrors] = useState([])

  const onOk = useCallback(async () => {
    updateFormErrors([])
    try {
      await form.validateFields()
    } catch (e) {
      console.error(e)
      return
    }

    try {
      const values = form.getFieldsValue()
      console.log('field values', values)
      await sendMethod(values)
      updateFormErrors([<div className={"text-right"}><Typography.Text type={"success"}>Пароль обновлен</Typography.Text></div>])
      await wait(1000)
      onCancel()
    } catch (e) {
      console.error(e)
      updateFormErrors([<div className={"text-right"}><Typography.Text type={"danger"}>{e.toString()}</Typography.Text></div>])
    }

  },[form, sendMethod, formErrors])

  return (<Modal
    maskClosable={false}
    visible={visible}
    title="Обновление пароля"
    okText="Обновить"
    cancelText="Назад"
    onCancel={onCancel}
    onOk={onOk}>
      <Form
        layout="vertical"
        form={form}
        name="basic"
      >
        <Form.Item
          label="Старый пароль"
          name="password"
          rules={[{ required: true, message: 'Введите старый пароль' }]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item
          label="Новый пароль"
          name="newPassword"
          rules={[{ required: true, message: 'Введите новый пароль' }]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item
          label="Новый пароль (повторно)"
          name="newPasswordRepeat"
          rules={[{ required: true, message: 'Введите новый пароль повторно' }, ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject("Пароли не совпадают")
            }
          })]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.ErrorList errors={formErrors}/>
      </Form>
    </Modal>)
}
