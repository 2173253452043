import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { CrmService } from "@/api/crm-orders";
import { showErrorDefault } from "@/utils/hooks";
import { Button, Popover } from "antd";
import { wait } from "@gagydzer/my-axios-service/src/utils";
import { mapOrderToFormData } from "@/views/app-views/crm/requisitions/order-form/utils";

const PRICE_PLUS_MINUS = 3000 // коридор возможной цены: актуальная +- PRICE_PLUS_MINUS

export const useFetchOrder = orderId => {
	const [loading, setLoading] = useState(!!orderId)
	const [order, setOrder] = useState(null)
	const orderPriceDataRef = useRef({
		minPrice: null,
		maxPrice: null,
		actualPrice: null
	})

	useEffect(() => {
		async function fetch() {
			setLoading(true)
			try {
				const order = await CrmService.orders.fetchOrder(orderId)
				setOrder(order)
				orderPriceDataRef.current = {
					minPrice: order.price - PRICE_PLUS_MINUS,
					maxPrice: order.price + PRICE_PLUS_MINUS,
					actualPrice: order.price
				}
			} catch (e) {
				showErrorDefault(e)
				setOrder(null)
			} finally {
				setLoading(false)
			}
		}

		if (orderId) fetch()
	}, [orderId])

	const resetOrder = useCallback(() => {
		setOrder(null)
		orderPriceDataRef.current = {
			minPrice: null,
			maxPrice: null,
			actualPrice: null
		}
	}, [])

	return { order, loading, resetOrder, orderPriceDataRef }
}

export const useMaxTonnage = (updateFlag) => {
	const [maxTonnage, setMaxTonnage] = useState(0)
	const maxTonnageRef = useRef(null)

	useEffect(() => {
		async function fetch() {
			try {
				const { availableForSale } = await CrmService.fetchActualTonnageStatistic()
				maxTonnageRef.current = availableForSale
				setMaxTonnage(Math.floor(availableForSale))
			} catch (e) {
				showErrorDefault(e)
			}
		}

		if (updateFlag) fetch()
	}, [updateFlag])

	return { maxTonnage, maxTonnageRef }
}

export const useGasPrice = (needLoad, visible) => {

	const [actualPriceIsLoading, setActualPriceIsLoading] = useState(false)
	const actualPriceRef = useRef(null)
	const actualPriceDataRef = useRef({
		actualPrice: null,
		minPrice: null,
		maxPrice: null
	})


	/*const actualPriceDataRef = useMemo(() => {
	  console.log('actualPriceData', actualPriceRef.current)
	  const actualPrice = actualPriceRef.current
	  return {
		actualPrice,
		minPrice: actualPrice - PRICE_PLUS_MINUS,
		maxPrice: actualPrice + PRICE_PLUS_MINUS
	  }
	},[actualPriceRef.current])*/

	useEffect(() => {
		async function fetch() {
			setActualPriceIsLoading(true)
			try {
				// await wait(5000)
				const actualPrice = await CrmService.fetchActualPrice()
				actualPriceRef.current = actualPrice
				actualPriceDataRef.current = {
					actualPrice,
					minPrice: actualPrice - PRICE_PLUS_MINUS,
					maxPrice: actualPrice + PRICE_PLUS_MINUS
				}
				// setActualPrice(actualPrice)
			} catch (e) {
				showErrorDefault(`Цена недоступна по техн. причинам: ${e}`)
			}
			setActualPriceIsLoading(false)
		}

		console.log('useGasPriceEffect')

		if (needLoad && visible) fetch()
	}, [needLoad, visible])

	return { actualPriceRef, actualPriceDataRef, loading: actualPriceIsLoading }
}

const saveOrder = async order => {
	return await CrmService.orders.updateOrder(order);
	// return order;
}

export const useFormOrderActions = (
	{
		staffPassRegistrationIsVisible,
		canConfirmPayment,
		isClient,
		orderStatus,
		isCancelling,
		isLoading,
		onCancelOrder,
		onUpdateOrderStatus
	}
) => {

	const { okButtonTitle, method } = useMemo(() => {
		if (!orderStatus) {
			return {
				okButtonTitle: 'Создать заказ',
				method: CrmService.orders.createOrder
			}
		} else if (orderStatus === 'new') {
			if (isClient) {
				return {}
			} else {
				return {
					okButtonTitle: 'Утвердить заказ',
					method: async order => {
						await CrmService.orders.updateOrder(order)
						return CrmService.orders.approve(order)
					},
				};
			}
		} else if (orderStatus === 'on-payment') {
			if (canConfirmPayment) {
				return {
					okButtonTitle: 'Перевести в оплаченные',
					method: CrmService.orders.confirmPayment
				}
			} else return {}
		} else if (orderStatus === 'paid') {
			if (!isClient) {
				if (!staffPassRegistrationIsVisible) return {}
				else return { okButtonTitle: 'Отправить заявку', method: CrmService.orders.passRegistration }
			} else return { okButtonTitle: 'Отправить заявку', method: CrmService.orders.passRegistration }
		} else if (orderStatus === 'pass-registration') {
			if (isClient) {
				return {
					okButtonTitle: 'Отправить заявку',
					method: CrmService.orders.passRegistration
				}
			} else return {}
		} else return {}
	}, [orderStatus, staffPassRegistrationIsVisible])

	console.log('useFormOrderActions', okButtonTitle, staffPassRegistrationIsVisible)

	const modalButtons = useMemo(() => {

		const hasNoActionsStatuses = ['on-shipment', 'shipped']
		if (hasNoActionsStatuses.includes(orderStatus)) {
			return <div></div>
		} else {
			const buttons = []
			const canCancelStatuses = ['new', 'on-payment', 'paid', 'pass-registration']
			if (canCancelStatuses.includes(orderStatus)) {
				const cancelOrderPopoverContent = <div>
					<Button danger block size={"small"} onClick={onCancelOrder} loading={isCancelling}>Отменить</Button>
				</div>

				const cancelPopover = <Popover key={"1"} content={cancelOrderPopoverContent} title="Отменить заказ?"
				                               trigger="click">
					<Button size={"small"} danger>Отменить заказ</Button>
				</Popover>

				buttons.push(cancelPopover)
			}

			if (okButtonTitle) {
				const okButton = <Button key={"2"} onClick={() => onUpdateOrderStatus(method)}
				                         size={"small"}
				                         type={"primary"}
				                         loading={isLoading}>
					{okButtonTitle}
				</Button>
				buttons.push(okButton)
			}

			if(orderStatus === 'new') {
				const saveButton = <Button key={"3"} onClick={() => onUpdateOrderStatus(saveOrder)}
				                         size={"small"}
				                         type={"outline"}
				                         loading={isLoading}>
					Сохранить
				</Button>
				buttons.push(saveButton)
			}

			return <div>{buttons}</div>
		}

	}, [orderStatus, isLoading, isCancelling, onUpdateOrderStatus, onCancelOrder, okButtonTitle, method])

	return modalButtons
}

export const useDocumentsDownload = (order, documentType, withoutSign = 0) => {
	const [downloading, setIsDownloading] = useState(false)

	const download = useCallback(async () => {
		setIsDownloading(true)
		try {
			await CrmService.orders.documents[documentType](order.id, {
				without_sign: withoutSign
			})
		} catch (e) {
			showErrorDefault(e)
		}
		setIsDownloading(false)
	}, [order])

	return {
		downloading,
		download
	}
}

export const useShowBlockConditions = (order, isClient) => {

	return useMemo(() => {
		return {
			isShowPaymentBlock: order && ((order.status !== 'new' && order.status !== 'cancelled') || (order.status === 'cancelled' && order.invoicePaid)),
			isShowWaitingPassRegistrationBlock: !isClient && order && order.status === 'paid',
			isShowPassRegistrationClientBlock: isClient && order && order.status !== 'new' && order.status !== 'on-payment' && order.status !== 'cancelled',
			isShowPassRegistrationStaffBlock: !isClient && order && order.status !== 'new' && order.status !== 'on-payment' && order.status !== 'paid' && order.status !== 'cancelled',
			isShowCancelledBlock: order && order.status === 'cancelled',
			isShowShipmentBlock: order && (order.status === 'on-shipment' || order.status === 'shipped'),
		}
	}, [order, isClient])

}

export const useApplyOrderMeta = (form) => {

	const [orderMetaIsLoading, setOrderMetaIsLoading] = useState(false)

	const fetchAndApplyOrderMeta = useCallback(async function fetchAndApply(clientId, forceReplace) {
		setOrderMetaIsLoading(true)
		try {
			const currentValues = form.getFieldsValue()
			// подставляем в заказ
			const EXTRA_DATA_FIELDS = [
				'productPassportNumber',
				'productPassportDate',
				'productGrade',
				'annexNumber'
			]
			const extraFieldsFulfilled = EXTRA_DATA_FIELDS.every(k => currentValues[k] !== null
				&& currentValues[k] !== undefined
			)

			if (extraFieldsFulfilled && !forceReplace) {
				return
			}

			const newOrderMeta = await CrmService.orders.fetchInitOrderMeta(clientId)

			const mergedExtraValues = forceReplace
				? newOrderMeta
				: Object.fromEntries(
					EXTRA_DATA_FIELDS.map(key => ([key, currentValues[key] || newOrderMeta[key] || null]))
				)

			form.setFieldsValue(mapOrderToFormData(mergedExtraValues))
		} catch (e) {
			showErrorDefault(e)
		} finally {
			setOrderMetaIsLoading(false)
		}
	}, [])

	return {
		orderMetaIsLoading,
		fetchAndApplyOrderMeta
	}

}
