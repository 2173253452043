import React, { useMemo } from 'react';
import { Layout, Grid } from 'antd';
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import SideNav from '@/components/layout-components/SideNav';
import TopNav from '@/components/layout-components/TopNav';
import MobileNav from '@/components/layout-components/MobileNav'
import HeaderNav from '@/components/layout-components/HeaderNav';
import PageHeader from '@/components/layout-components/PageHeader';
import Footer from '@/components/layout-components/Footer';
import AppViews from '@/views/app-views';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP
} from '@/constants/ThemeConstant';
import utils from '@/utils';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, navigationConfig }) => {
  const currentRouteInfo = useMemo( () => utils.getRouteInfo(navigationConfig, location.pathname), [location])
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    return SIDE_NAV_COLLAPSED_WIDTH
  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout className="app-container">
        <SideNav routeInfo={currentRouteInfo}/>
        <Layout className="app-layout" style={{paddingLeft: getLayoutGutter()}}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <Content>
              <Switch>
                <Route path="" component={AppViews} />
              </Switch>
            </Content>
          </div>
        </Layout>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { navigationConfig } = auth
  const { navCollapsed, navType, locale } =  theme;
  return { navCollapsed, navType, locale, navigationConfig }
};

export default withRouter(connect(mapStateToProps)(AppLayout));
