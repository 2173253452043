import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import {message} from "antd";
import OrderFormModal from "@/views/app-views/crm/requisitions/order-form";

export const useModalOrderForm = (onSendForm) => {
  const [visible, setVisible] = useState(false)
  const visibleRef = useRef(false)
  const [modalOrderId, setModalOrderId] = useState(null)

  const openNewOrderModal = useCallback(() => {
    setVisible(true)
    visibleRef.current = true
  },[])

  const openOrderModal = useCallback((orderId) => {
    console.log('openOrderModal', orderId)
    setVisible(true)
    visibleRef.current = true
    setModalOrderId(orderId)
  },[])

  const _onCloseModal = useCallback((resultMessage) => {
    setVisible(false)
    visibleRef.current = false
    setModalOrderId(null)
    if (resultMessage) message.success({ content: resultMessage, duration: 5 })
  },[])

  const renderedOrderFormModal = <OrderFormModal
    orderId={modalOrderId}
    onClose={_onCloseModal}
    onSendForm={onSendForm}
    visible={visible}
  />

  return {
    openOrderModal,
    openNewOrderModal,
    renderedOrderFormModal,
    orderModalIsVisible: visible,
    orderModalIsVisibleRef: visibleRef
  }
}
