import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Users from "@/redux/reducers/Users";
import Global from "@/redux/reducers/Global";
import Counterparties from "@/redux/reducers/Counterparties";

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    global: Global,
    users: Users,
    counterparties: Counterparties
});

export default reducers;