import React, {lazy, Suspense} from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "@/layouts/app-layout";
import AuthLayout from '@/layouts/auth-layout';
import AppLocale from "@/lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider, message } from 'antd';
import {wait} from "@gagydzer/my-axios-service/src/utils";
import Loading from "../components/shared-components/Loading";
import ruLang from 'antd/lib/locale/ru_RU';
import { IS_DON_UGLI, IS_RWSP } from "@/utils/env"

const GetMyAsyncGuard = (validationPromise, rightRedirect, leftRedirect) => lazy(  async () => {
  return validationPromise.then( resolve => {
    console.log('validationPromise:resolve')
    return { // component import imitation
      default: () => rightRedirect
    }
  }, reject => {
    return {
      default: () => leftRedirect
    }
  })

})

function InviteInterceptor(props) {
  const location = props.location
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')
  const activationCode = searchParams.get('activationCode')
  if (!email || !activationCode) {
    message.error(`wrong URL params`)
    return <Route render={() => <Redirect
      to={{
        pathname: "/auth",
        state: { from: location }
      }}
    />}/>
  } else {
    return <Route path={'/invite'} render={() => {
      const validationPromise = new Promise( async resolve => {
        await wait(500)
        return resolve()
      })
      const rightRedirect = <Redirect to={{
        pathname: "/auth/invite",
        state: {
          from: location,
          email,
          activationCode
        }
      }}/>
      const leftRedirect = <Redirect to={{
        pathname: "/auth/",
        state: {
          from: location
        }
      }}/>
      const AsyncGuardedRedirect = GetMyAsyncGuard(validationPromise, rightRedirect, leftRedirect)

      return <AsyncGuardedRedirect/>
    }}/>
  }
}

function ResetPasswordInterceptor(props, ...rest) {
  const location = props.location
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')
  const activationCode = searchParams.get('activationCode')
  if (!email || !activationCode) {
    message.error(`wrong URL params`)
    return <Route render={() => <Redirect
      to={{
        pathname: "/auth",
        state: { from: location }
      }}
    />}/>
  } else {
    return <Route path={'/reset-password'} render={() => {
      const validationPromise = new Promise( async resolve => {
        await wait(500)
        return resolve()
      })
      const rightRedirect = <Redirect to={{
        pathname: "/auth/reset-password",
        state: {
          from: location,
          email,
          activationCode
        }
      }}/>
      const leftRedirect = <Redirect to={{
        pathname: "/auth/",
        state: {
          from: location
        }
      }}/>
      const AsyncGuardedRedirect = GetMyAsyncGuard(validationPromise, rightRedirect, leftRedirect)

      return <AsyncGuardedRedirect/>
    }}/>
  }
}



function RouteInterceptor({ children, groups, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  console.log('Views', props)
  const { locale, isAuthenticated, location, groups } = props;
  const isClient = groups && groups.includes('clients')
  console.log('Views', isClient)

  const currentAppLocale = AppLocale[locale];
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={ruLang}>
        <Switch>
          <Route path="/auth">
            <AuthLayout />
          </Route>
          <Suspense path="/invite" fallback={<Loading cover="content"/>}>
            <Switch>
              <InviteInterceptor path="/invite"/>
            </Switch>
          </Suspense>
          <Suspense path="/reset-password" fallback={<Loading cover="content"/>}>
            <Switch>
              <ResetPasswordInterceptor path="/reset-password"/>
            </Switch>
          </Suspense>
          {isClient
            ? <Redirect from="/" to="/crm/orders" exact/>
            : (IS_RWSP || IS_DON_UGLI ? <Redirect from="/" to="/bdds" exact/> : <Redirect from="/" to="/home" exact/>)}
          <RouteInterceptor path="/" groups={groups} isAuthenticated={isAuthenticated}>
            <AppLayout location={location}/>
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale } =  theme;
  const isAuthenticated = auth.authData;
  return { locale, isAuthenticated, groups: auth.authData ? auth.authData.groups : [] }
};

export default withRouter(connect(mapStateToProps)(Views));
