import {service, getFile} from "@/api/service";

const prepareClient = client => ({
  ...client,
  ...Object.fromEntries(
    ['inn','ogrn','kpp','okpo','okato'].map( key => ([key,client[key] && typeof client[key] === 'string' ? client[key].replace(/^s+/,'') : null]))
  )
})

const fetchInvitations = (clientId) => service.get(`/clients/${clientId}/invitations`).then( r => r.data )
const fetchList = () => service.get('/clients').then( r => r.data.map(prepareClient) )
const fetchClient = (clientId) => service.get(`/clients/${clientId}`).then( r => prepareClient(r.data) )
const createOrUpdateClient = (clientData) => service.put(`/clients/`, clientData).then( r => prepareClient(r.data) )
const deleteClient = clientId => service.delete(`/clients/${clientId}`).then( r => (r.data) )

export const ClientsApi = {
  fetchList,
  autocomplete: () => service.get('/clients/autocomplete').then( r => r.data ),
  fetchClient,
  createOrUpdateClient,
  downloadContractFile: (clientId) => getFile(`/clients/${clientId}/contract-file`),
  uploadContractFile: (clientId, file) => service
    .post(`/clients/${clientId}/contract-file`, { file },{ headers: { "Content-Type": "multipart/form-data" } })
    .then( r => r.data ),
  deleteClient,
  invitations: {
    fetchList: fetchInvitations,
    add: (clientId, email) => service.post(`/clients/${clientId}/invitations`, { email }).then( r => r.data ),
    delete: (clientId, invitationId) => service.delete(`/clients/${clientId}/invitations/${invitationId}`).then( r => r.data )
  }
}
