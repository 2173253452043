import{ Spin, Dropdown, Tooltip, Popover, Button, Typography, Input } from "antd";
import {CommentOutlined, EnterOutlined, LoadingOutlined, CheckOutlined } from "@ant-design/icons";
import React, {useMemo, useState, useCallback, useRef, useEffect} from "react";
import ReactDOM from "react-dom";
import {wait} from "@gagydzer/my-axios-service/src/utils";

const { TextArea } = Input;

const { Paragraph } = Typography;

export function BddsComment({ comment }) {
  return <Tooltip title={comment}>
    <CommentOutlined className={"bdds-comment-icon"}/>
  </Tooltip>
}

export function useCommentPopover(onUpdateComment) {

  const [comment, setComment] = useState('')
  const commentRef = useRef('')
  const [pagePos, setPagePos] = useState(null)
  const [saving, setSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    if (pagePos && inputRef.current) inputRef.current.focus()
  },[pagePos])

  const close = useCallback(() => {
    setComment('')
    commentRef.current = ''
    setPagePos(null)
    setSaving(false)
    setIsSaved(false)
  },[])

  /*const onChange = useCallback((e) => {
    const value = e.target.value
    setComment(value)
    commentRef.current = value
  },[])*/

  const onPressEnter = useCallback(async (e) => {
    if (e.ctrlKey) {
      inputRef.current.blur()
      setSaving(true)
      await onUpdateComment(e.target.value)
      setIsSaved(true)
      await wait(500)
      setSaving(false)
      close()
    }
  },[close, onUpdateComment])

  const childStyle = useMemo(() => ({
    position: "absolute", left: pagePos && (pagePos.x + 'px'), top: pagePos && (pagePos.y + 'px')
  }), [pagePos])

  const spinIndicator = useMemo(() => {
    return isSaved ? <CheckOutlined/> : <LoadingOutlined/>
  },[isSaved])

  const content = pagePos && <Spin tip="Сохранение..." indicator={spinIndicator} size="small" spinning={saving}>
      <TextArea
        ref={inputRef}
        autoFocus={true}
        defaultValue={comment}
        autoSize={{minRows: 3}}
        onPressEnter={onPressEnter}
      />
      <span style={{ position: "absolute", right: '10px', bottom: '5px', fontSize: "0.8em", pointerEvents: 'none' }}>Ctrl + <EnterOutlined/></span>
    </Spin>

  const onVisibleChange = useCallback((visible) => {
    if (!visible) {
      setPagePos(null)
      setComment('')
      commentRef.current = ''
      setSaving(false)
      setIsSaved(false)
    }
  },[])

  const showPopover = useCallback(({ pagePos, comment, updateMethod }) => {
    setPagePos(pagePos)
    setComment(comment)
    commentRef.current = comment
  },[])

  const renderedPopover = ReactDOM.createPortal(<Popover overlayClassName={"bdds-comment-popover"} trigger={"click"} visible={!!pagePos}
                                        content={content}
  onVisibleChange={onVisibleChange}
  >
    <div style={childStyle}>
    </div>
  </Popover>, document.getElementById('no-context-root'))

  return {
    renderedPopover,
    showPopover,
    closePopover: close
  }
}
