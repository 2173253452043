import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";

export const TextBubble = ({bool, text, background, hint, className}) => {

    if (!text) {
        background = bool ? "bg-primary-green" : "bg-red-500";
        text = bool ? "Да" : "Нет";
    }

    return (
        <React.Fragment>
        <span data-tip={hint}
              className={`p-1.5 rounded-md text-xs leading-5 text-xs font-bold text-white ${background} ${hint && "cursor-pointer"} ${className}`}>
            {text}
        </span>
            {hint && <ReactTooltip multiline={true} place={"bottom"}/>}
        </React.Fragment>
    );
};


TextBubble.defaultProps = {
    background: "bg-blue-400",
    hint: ""
};

TextBubble.propTypes = {
    text: PropTypes.string,
    bool: PropTypes.bool,
    background: PropTypes.string
};