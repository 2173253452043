import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import "./linkIcon.scss";

export const LinkIcon = ({id, link, callback, background, icon}) => {


  if (callback) {
    return (
      <button onClick={() => callback(id)}
              className={`${background} focus:outline-none rounded-md flex items-center justify-center w-7 h-7`}>
        <FontAwesomeIcon className={"text-base pb-px font-normal text-white"} icon={icon}/>
      </button>
    )
  } else {
    return (
      <Link to={`${link}${id}`}
            className={`${background} focus:outline-none rounded-md flex items-center justify-center w-7 h-7`}>
        <FontAwesomeIcon className={"text-base pb-px font-normal text-white"} icon={icon}/>
      </Link>
    )
  }


};

LinkIcon.propTypes = {
  link: PropTypes.string,
  background: PropTypes.string,
  icon: PropTypes.string.isRequired
};
