import React from 'react';
import PropTypes from 'prop-types';
import './dateRange.scss';
import {ConfigProvider, DatePicker} from 'antd';
import "moment/locale/ru";
import locale from "antd/es/locale/ru_RU";
import moment from 'moment';
import {getCurrentRange, getRangeFilters} from "@/helpers/HelperDate";
import {isEmpty} from "@gagydzer/my-axios-service/src/utils/formData";
import {vala} from "react-syntax-highlighter/dist/cjs/languages/hljs";

const {RangePicker} = DatePicker;

class DateRangeInput extends React.Component {
  constructor(props) {
    super(props);

    this.defaultDate = getCurrentRange();

    this.state = {
      date: props.value || props.defaultValue || undefined,
    }
  }

  componentDidMount() {
    const {setValue} = this.props;
    const {date} = this.state;

    if (date) {
      if (date[0]) {
        setValue('startDate', date[0]?.format("YYYY-MM-DD"))
      }
      if (date[1]) {
        setValue('endDate', date[1]?.format("YYYY-MM-DD"))
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {getValues, value} = this.props;


    if (prevProps !== this.props) {

      let inputValue = this.props.value;

      if (getValues) {
        inputValue = [getValues("startDate"), getValues("endDate")];
      }

      if (!Array.isArray(inputValue) || (this.props?.value && isEmpty(this.props.value))) {
        this.setState({date: this.props.defaultValue || []});
      } else {
        this.setState({date: value});
      }

    }

  }

  onChange = moments => {
    let {name, setValue, callback, calendarDateType, callbackSubmit} = this.props;
    let date = [];

    if (moments !== null) {
      if (calendarDateType === "month") {
        date = [
          moment(moments[0]).startOf('month').format('YYYY-MM-DD 00:00:00'),
          moment(moments[1]).endOf('month').format('YYYY-MM-DD 23:59:59'),
        ];

      } else {
        date = moments.map(element => moment(element).format("YYYY-MM-DD"));
      }
    } else {

    }

    if (this.props.setFiltersState) {
      this.props.setFiltersState({
        "startDate": date[0],
        "endDate": date[1],
      });
    }

    this.setState({date: moments},
      () => {
        let returnRange = {
          "startDate": date[0],
          "endDate": date[1],
        };

        setValue(name, date);

        setValue("startDate", date[0]);
        setValue("endDate", date[1]);

        if (callbackSubmit) {
          callbackSubmit();
        }

        if (callback) {
          callback(returnRange);
        }
      });
  };

  render() {
    let {
      calendarDateType,
      register,
      required,
      name,
      minValue,
      maxValue,
      maxLength,
      minLength,
      value
    } = this.props;

    let {date} = this.state;

    const isMonthType = calendarDateType !== "day" && "small";



    if (calendarDateType === "month") {
      value = register ? date : value ? [moment(value.startDate), moment(value.endDate)] : undefined;

    }

    return (
      <>

        {register && <input type={"hidden"}
                            {...register(
                              name,
                              {
                                required,
                                maxLength,
                                minLength,
                                min: minValue,
                                max: maxValue,
                              }
                            )} />}

        <ConfigProvider locale={locale}>
          <RangePicker picker={calendarDateType}
                       onChange={this.onChange}
                       value={value}
                       className={isMonthType}
                       size={isMonthType}/>
        </ConfigProvider>

      </>
    );
  }
}

DateRangeInput.defaultProps = {
  calendarDateType: "day",
  name: "date",
  setValue: () => {
  },
  defaultValue: {},
  useDefaultValue: false,
};

DateRangeInput.propTypes = {
  calendarDateType: PropTypes.string,
  callback: PropTypes.func,
};

export {DateRangeInput};
