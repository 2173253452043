import React, {useState, useEffect, useCallback, useMemo, useLayoutEffect, useRef} from 'react'
import {BddsTable} from "./bdds-table";
import {message, Modal, Menu, Dropdown, Button, Typography, Radio, DatePicker } from "antd";
import {getFormattedValuteDefault} from "../../../utils/numeral";
import {BddsService} from "../../../api/bddsService";
import {PaymentsList} from "./payments-list";
import moment from "moment"
import {wait} from "@gagydzer/my-axios-service/src/utils";
import { DownOutlined, FileExcelOutlined } from '@ant-design/icons';
import Loading from '@/components/shared-components/Loading';
import {showErrorDefault, usePrevious} from "@/utils/hooks";
import {usePaymentsModal} from "@/components/payments-modal";
import { IS_DON_UGLI } from "@/utils/env"
import {useCommentPopover} from "@/components/shared-components/bdds-comment-input";
import {DATE_FORMAT_FORM} from "@/constants/DateConstant";
const { Link } = Typography;

const getFocusContainerForBddsTree = (bddsItems) => {

  const getMonthsEmptyFocusState = () => Object.fromEntries(
    new Array(12).fill(false).map( (v,i) => ([i+1,v]))
  )

  let getFlatLastLevel = (items) => items.reduce( (acc, item) => {
    if (item.children) {
      let nItems = getFlatLastLevel(item.children)
      acc.push(...nItems)
    } else acc.push(item)
    return acc
  }, [])

  let flatItems = getFlatLastLevel(bddsItems)

  return Object.fromEntries(
    flatItems.map( item => ([item.codeString, getMonthsEmptyFocusState()]))
  )
}

const scrollToCurrentMonth = async () => {
  console.log('scrollToCurrentMonth')

  // scroll to current month
  const scrollWrapper = document.querySelector('.ant-table-body')
  if (!scrollWrapper) return null
  const currentMonthNumber = new Date().getMonth()+1

  await wait(100)

  const currentColumn = document.querySelector(`.ant-table-thead tr:first-child th:nth-child(${currentMonthNumber+1})`)

  if (currentColumn) {
    const rect = currentColumn.getBoundingClientRect()
    console.log(rect.left)
    const scrollLeft = rect.left - 250
    //document.querySelector('.ant-table-header').scrollLeft =  scrollLeft
    scrollWrapper.scrollLeft = scrollLeft
    console.log(rect,scrollLeft,scrollWrapper.scrollLeft)
    message.info(`${scrollWrapper.scrollLeft} left:${rect.left} width:${rect.width}`)
  }

}

export const BddsPage = (props) => {
  const { year, history, match } = props

  const [ currentBddsSyncDate, updateCurrentBddsSyncDate ] = useState(null)
  const prevSyncDate = usePrevious(currentBddsSyncDate)

  const [ newBddsAvailableMessageIsVisible, updateNewBddsMessage ] = useState(false)
  const [ fileDownloading, updateFileDownloading ] = useState(false)
  const [ bddsList, updateBddsList ] = useState(null)
  const [ bddsTree, updateBddsTree ] = useState(null);
  const [ bddsTreeIsLoading ,updateBddsTreeIsLoading ] = useState(true)
  const [ bddsFocusState, updateBddsFocusState ] = useState({})

  const [ cellInFocus, setCellInFocus ] = useState(null)

  const { showPayments, PaymentsModal } = usePaymentsModal()

  const [ currentView, updateCurrentView ] = useState('months')
  const [ currentMonthNumber, setCurrentMonthNumber ] = useState(() => new Date().getMonth())
  const [actualMonthNumber, setActualMonthNumber] = useState(() => new Date().getMonth())

  const [ tableHeight , updateTableHeight ] = useState(0)

  const ViewSelect = useMemo(() => <Radio.Group
                                                style={{ marginTop: '2px' }}
                                                className="custom-label-radio-group"
                                                value={currentView} size="small"
                                                buttonStyle="solid"
                                                onChange={e => updateCurrentView(e.target.value)}>
    <Radio.Button value="months">По месяцам</Radio.Button>
    <Radio.Button value="weeks">По неделям</Radio.Button>
    { IS_DON_UGLI && <Radio.Button value="days">По дням</Radio.Button>}
  </Radio.Group>,[currentView])

  const MonthSelect = useMemo(() => {
    if (currentView !== 'days') return null
    const value = moment(new Date(year,currentMonthNumber))

    const onChange = (date) => {
      const monthIndex = date.toDate().getMonth()
      setCurrentMonthNumber(monthIndex)
    }

    return <DatePicker value={value} onChange={onChange} picker="month" format={"MMMM"} bordered={false}/>
  },[currentView, currentMonthNumber])

  const days = useMemo(() => {
    if (currentView === 'days') {
      let daysInMonth = moment(new Date(year,currentMonthNumber)).daysInMonth()
      return new Array(daysInMonth).fill(null).map((_,i) => i+1)
    } else return null
  },[currentView, currentMonthNumber])

  const activeBddsListItemIndex = useMemo(() => {
    try {
      if (!bddsList) return -1
      const result = bddsList.findIndex(el => el.date.startsWith(year))
      console.log('activeBddsListItemIndex', bddsList, result)
      if (result === -1) throw new Error(`bdds item for year:${year} not found`)
      return result
    } catch (e) {
      showErrorDefault(e)
      return -1
    }
  },[year,bddsList])

  useEffect(() => {
    const interval = setInterval(async () => {
      console.log('check new bdds', currentBddsSyncDate)
      if (activeBddsListItemIndex === -1) return
        try {
          const syncDate = await BddsService.fetchBddsLastSync(bddsList[activeBddsListItemIndex].id)
          console.log('check new bdds syncDate', syncDate, currentBddsSyncDate)
          if (syncDate !== null && syncDate !== currentBddsSyncDate) {
            if (!newBddsAvailableMessageIsVisible) {
              updateNewBddsMessage(true)
              message.info({
                key: 'new-data-available',
                content: (<span>
                Данные с 1С синхронизированы&nbsp;
                <Link href="#" onClick={(e) => {
                  message.destroy('new-data-available')
                  e.preventDefault()
                  updateNewBddsMessage(false)
                  updateCurrentBddsSyncDate(syncDate)
                }}>Обновить?</Link>
              </span>),
                duration: 0
              })
            } else {
              updateNewBddsMessage(true)
            }
          }
        } catch (e) {
          showErrorDefault(e)
          clearInterval(interval)
        }
    }, 1000 * 60)

    return () => {
      clearInterval(interval)
      message.destroy('new-data-available')
    }
  },[activeBddsListItemIndex, currentBddsSyncDate])


  const isReadyToShowTable = useMemo(() => !bddsTreeIsLoading
    && bddsTree
    && (currentView === "days"
      ? currentMonthNumber === actualMonthNumber
      : true
    ),[bddsTreeIsLoading, bddsTree, currentView, currentMonthNumber, actualMonthNumber])

  useEffect(() => {
    async function fetchData() {
      setCellInFocus(null)
      updateBddsTree(null)
      updateBddsTreeIsLoading(true)
      try {
        const bddsListResponse = await BddsService.fetchList()
        const matchedBddsItem = bddsListResponse.find(el => el.date.startsWith(year))
        const { items: bddsItems, lastSync } = await BddsService.fetchItem(matchedBddsItem.id, currentView, currentMonthNumber + 1)
        updateBddsTree(bddsItems)
        updateBddsList(bddsListResponse)
        setActualMonthNumber(currentView === "days" ? currentMonthNumber : null)
        // updateBddsFocusState(getFocusContainerForBddsTree(bddsItems))
        updateCurrentBddsSyncDate(lastSync)
      } catch (e) {
        showErrorDefault(e)
      }
      updateBddsTreeIsLoading(false)
    }

    if (currentBddsSyncDate !== null && prevSyncDate === null) return
    fetchData()
  },[year, currentBddsSyncDate, currentView, currentMonthNumber])

  const sendValue = useCallback( async (row, monthOrDay, value) => {
    try {
      const key = 'updatable';
      message.loading({ content: 'Обновление БДДС...', key });

      // updateSums
      if (currentView === 'days') {
        updateBddsTree( oldTree => {
          let newTree = [...oldTree]
          const oldCellValue = row.days[monthOrDay].plan
          const diff = value - oldCellValue

          row.codeArray.reduce( (children, codeValue) => {
            if (!children) return
            const treeItem = children.find( item => item.code === codeValue)
            if (!treeItem) {
              debugger
            }
            treeItem.days[monthOrDay].plan += diff
            treeItem.days[monthOrDay]['plan-formatted'] = getFormattedValuteDefault(treeItem.days[monthOrDay].plan, false, IS_DON_UGLI)
            treeItem.month.plan += diff
            treeItem.month['plan-formatted'] = getFormattedValuteDefault(treeItem.month.plan, false, IS_DON_UGLI)
            if (treeItem.children && treeItem.children.length) return treeItem.children;
            else return null
          },newTree)

          return newTree
        })
      } else {
        updateBddsTree( oldTree => {
          let newTree = [ ...oldTree ]
          const oldCellValue = row.months[monthOrDay].plan
          const diff = value - oldCellValue

          row.codeArray.reduce( (children, codeValue) => {
            if (!children) return
            const treeItem = children.find( item => item.code === codeValue)
            if (!treeItem) {
              debugger
            }
            treeItem.months[monthOrDay].plan += diff
            treeItem.months[monthOrDay]['plan-formatted'] = getFormattedValuteDefault(treeItem.months[monthOrDay].plan, false, IS_DON_UGLI)
            treeItem.year.plan += diff
            treeItem.year['plan-formatted'] = getFormattedValuteDefault(treeItem.year.plan, false, IS_DON_UGLI)
            if (treeItem.children && treeItem.children.length) return treeItem.children;
            else return null
          },newTree)

          return newTree
        })
      }

      let payload = { codeId: row.id, value }

      if (currentView === 'days') {
        payload.date = moment(new Date(year, currentMonthNumber, monthOrDay)).format('YYYY-MM-DD')
      } else {
        payload.month = monthOrDay
      }

      await BddsService.updatePlans(bddsList[activeBddsListItemIndex].id, payload)
      message.success({ content: 'Обновлено!', key, duration: 2 });
    } catch (e) {
      showErrorDefault(e)
    }
  },[bddsList, activeBddsListItemIndex])

  const showPaymentsForCell = useCallback( (name, pArr) => {
    let payments = [ ...pArr ]

    payments.sort( (a,b) => {
      return a.dateTime < b.dateTime ? 1 : -1
    } )

    showPayments({
      title: `${name}`.replace(/^\s+/,''),
      payments
    })

  },[])

  const onCurrentYearChange = useCallback(({ key }) => {
    const year = key

    const params = new URLSearchParams({ year })

    history.push(`${match.path}/?${params.toString()}`)
  },[])

  const yearsDropdown = useMemo(() => {
    if (!bddsList) return null
    const items = bddsList.map( (item, i) => (<Menu.Item key={item.date.substr(0,4)}>
      {item.date.substr(0,4)}
    </Menu.Item>))

    return <Dropdown overlay={<Menu selectedKeys={[year.toString()]} onClick={onCurrentYearChange}>{items}</Menu>} overlayClassName={"bdds-year-picker-dropdown"}>
      <span style={{ cursor: "pointer" }}>
        <span style={{ borderBottom: "1px dashed grey" }}>{year}&nbsp;</span>
        <DownOutlined />
      </span>
    </Dropdown>
  },[year,bddsList, activeBddsListItemIndex])

  const downloadExcel = useCallback(async () => {
    updateFileDownloading(true)
    try {
      await BddsService.downloadExcel(bddsList[activeBddsListItemIndex].id)
      message.success('БДДС загружено')
    } catch (e) {
      showErrorDefault(e)
    }
    updateFileDownloading(false)
  })

  const updatePlanCommentCurrentRowMetaRef = useRef(null)
  const onUpdatePlanComment = useCallback(async (comment) => {
    try {
      const { row, dataIndex } = updatePlanCommentCurrentRowMetaRef.current
      const [ _, day ] = dataIndex
      const date = moment(new Date(year,currentMonthNumber, day)).format(DATE_FORMAT_FORM)
      console.log('onUpdatePlanComment', date)
      const bddsId = bddsList[activeBddsListItemIndex].id
      await BddsService.updatePlans(bddsId, { comment, date, codeId: row.id })
      updateBddsTree( tree => {
        const finalTree = [...tree]
        row[dataIndex[0]][dataIndex[1]].comments = [comment]
        return finalTree
        /*const itemFound = row.codeArray.reduce((children, codeValue, i, arr) => {
          if (!children) return
          const treeItem = children.find(item => item.code === codeValue)
          if (i === arr.length - 1) return treeItem
          else return
        }, finalTree)*/
      })

    } catch (e) {
      showErrorDefault(e)
    }

  },[activeBddsListItemIndex, bddsList, year, currentMonthNumber])

  const commentPopover = useCommentPopover(onUpdatePlanComment)

  const showCommentPopover = useCallback(({ pagePos, row, dataIndex }) => {
    const dataObj = dataIndex.reduce((acc, dataKey) => acc[dataKey], row)
    const comment = !dataObj.comments
      ? ''
      : Array.isArray(dataObj.comments) && dataObj.comments.length ? dataObj.comments[0] : ''
    updatePlanCommentCurrentRowMetaRef.current = {row, dataIndex}
    commentPopover.showPopover({ pagePos, comment })
  },[])


  return (
    <div className="bdds-page">
      <div className="border-bottom bdds-page-header">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className={"mb-0"}>
              БДДС { yearsDropdown }
              &nbsp;&nbsp;
              { currentBddsSyncDate && (<span style={{ fontWeight: 400, fontSize: '0.7em' }}>{`от ${moment(currentBddsSyncDate).format('DD.MM.YYYY HH:mm')}`}</span>) }

            </h2>
            &nbsp;&nbsp;&nbsp;&nbsp;{ ViewSelect }&nbsp;{ MonthSelect }
            <div style={{textAlign:"right", flexGrow: 1}}>
              <Button type="primary" size={"small"} icon={<FileExcelOutlined />} loading={fileDownloading} onClick={downloadExcel}/>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
      {isReadyToShowTable ? <BddsTable
        view={currentView}
        year={year}
        days={days}
        className={["bdds-page__table", currentView === 'months' ? '_bdds-view-months' : '_bdds-view-weeks' ]}
        dataSource={bddsTree}
        tableFocusState={bddsFocusState}
        onUpdateFocusState={setCellInFocus}
        showPayments={showPaymentsForCell}
        tableHeight={tableHeight}
        updateTableHeight={updateTableHeight}
        onSendValues={sendValue}
        cellInFocus={cellInFocus}
        onCommentCellClick={showCommentPopover}/>
        : <div className="flex-grow-1 d-flex justify-content-center align-items-center"><Loading/></div>
      }
      { PaymentsModal }
      { IS_DON_UGLI && commentPopover.renderedPopover }
    </div>
  )
}

export default BddsPage
