import {getFile, service} from "@/api/service";
import {connect} from "react-redux";
import {ViewCrmOrdersClient} from "@/views/app-views/crm/requisitions/view-client";

const mapPaymentResponse = paymentResponse => ({
  ...paymentResponse,
  normalizedStatus: paymentResponse.paidDate ? 'paid' : paymentResponse.status
})

export const RegistryPaymentsService = {
  fetchRegistriesList: () => service.get('registries').then( r => r.data ),
  registry: {
    search: (search) => service.get('/registries/payments/search', { params: { search } } )
      .then( r => r.data.map(mapPaymentResponse)),
    payments: {
      fetchList: (registryId) => service.get(`/registries/${registryId}/payments`).then( r => r.data.map(mapPaymentResponse) ),
      downloadExcel: (registryId) => getFile(`/registries/${registryId}/payments`, { download: 1 }),
      fetchPayment: (registryId, paymentId) => service.get(`/registries/${registryId}/payments/${paymentId}`).then( r => r.data ),
      putPayment: (registryId, paymentData) => service.put(`/registries/${registryId}/payments/`, paymentData).then( r => r.data ),
      deletePayments: (idList, registryId) => service.delete(`/registries/${registryId}/payments/`, idList).then( r => r.data ),
      markPaymentsAsPaid: (idList) => service.patch(`/registries/payments/mark-as-paid`, idList).then( r => r.data ),
      copyPayments: (idList) => service.post(`/registries/payments/copy`, idList).then( r => r.data ),
      approve: (idList) => service.patch(`/registries/payments/approve`, idList).then( r => r.data ),
      reject: (idList) => service.patch(`/registries/payments/reject`, idList).then( r => r.data ),
    },
    close: newDate => service.patch('registries/close', { newDate }).then( r => r.data ),
    sendMail: (registryId, { subject, body, emails, registryAttach }) => service
      .post(`/registries/${registryId}/send-email`, { subject, body, emails, registryAttach })
      .then( r => r.data )
  },
  autocomplete: {
    fetch: (column, search) => service
      .get('registries/autocomplete', { params: { column, search }})
      .then( r => r.data && r.data.length ? r.data.map( v => ({ label: v, value: v })) : [] ),
    fetchBddsCodes: () => {
      return service.get('bdds/codes')
        .then( r => {
          const tree = r.data
          const expandTree = (items, parentsCodeArray, level, acc) => {
            return items.reduce((acc, item) => {
              const codeArray = [...parentsCodeArray, item.code]
              if (level !== 2 || (level === 2 && item.code !== 1)) {
                 if (item.children && item.children.length) {
                  expandTree(item.children, codeArray, level + 1, acc)
                 } else {
                   acc.push({
                     ...item,
                     codeArray
                   })
                 }
              }
              return acc
            }, acc)
          }

          const flatCodesArray = expandTree(tree,[],1,[])
          return flatCodesArray.map( ({ name, codeArray}) => {
            const codeString = codeArray.join('.')
            return {
              label: `${codeString}. ${name}`,
              value: codeString + '.'
            }
          })
        })
    },
    fetchEmails: (search) => service.get('/users', { params: { search }})
      .then( r => {
        const users = r.data

        return users.map( ({ firstName, lastName, email }) => ({
          label: `${firstName} ${lastName} (${email})`,
          value: email
        }))
      })
  }
}
