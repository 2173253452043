import moment from 'moment'
import "moment/locale/ru";

moment.locale("ru");



let monthNames = undefined

export const getMonthNames = () => {
  if (monthNames) return monthNames

  monthNames = moment.months()
  return monthNames
}

export const getDaysArrayBetween = (minDate, maxDate) => {
  let date = moment(minDate)
  let end = moment(maxDate)
  let data = [];
  for (let time = date; !time.isAfter(end); time.add(1,'days')) {
    const timeFormatted = time.format('YYYY-MM-DD')
    data.push({ formatted: timeFormatted, moment: time.clone() })
  }
  return data;
}

export function calcWeeksInMonth(dateObject) {
  const date = moment(dateObject)
  const dateFirst = moment(date).date(1);
  const dateLast = moment(date).date(date.daysInMonth());
  const startWeek = dateFirst.isoWeek();
  const endWeek = dateLast.isoWeek();

  if (endWeek < startWeek) {
    // Yearly overlaps, month is either DEC or JAN
    if (dateFirst.month() === 0) {
      // January
      return endWeek + 1;
    } else {
      // December
      if (dateLast.isoWeekday() === 7) {
        // Sunday is last day of year
        return endWeek - startWeek + 1;
      } else {
        // Sunday is NOT last day of year
        return dateFirst.isoWeeksInYear() - startWeek + 1;
      }
    }
  } else {
    return endWeek - startWeek + 1;
  }
}

window.calcWeeksInMonth = calcWeeksInMonth
