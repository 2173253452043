export const GET_USERS = 'GET_USERS';
export const FETCH_GET_USERS = 'FETCH_GET_USERS';
export const GET_USERS_HISTORY = 'GET_USERS_HISTORY';
export const FETCH_GET_USERS_HISTORY = 'FETCH_GET_USERS_HISTORY';
export const GET_USER = 'GET_USER';
export const FETCH_GET_USER = 'FETCH_GET_USER';
export const FETCH_ADD_USER = 'FETCH_ADD_USER';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const FETCH_EDIT_USER = 'FETCH_EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_DELETE_USER = 'FETCH_DELETE_USER';