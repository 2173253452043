import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react'
import {message} from "antd";
import OrderFormModal from "@/views/app-views/crm/requisitions/order-form";
import { useHistory, useLocation } from "react-router-dom";
import {showErrorDefault, usePrevious} from "@/utils/hooks";
import {CrmService} from "@/api/crm-orders";

export const useShowOrderFromSearchParams = (showOrderModal) => {
  const location = useLocation();

  const orderId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const orderId = searchParams.get('orderId') || null
    console.log('orderId', orderId)
    return +orderId
  },[location])

  useEffect(() => {
    if (orderId) {
      showOrderModal(orderId)
    }
  }, [orderId])
}

export const useOrdersPooling = (isLoadingRef, modalIsVisibleRef, fetch) => {

  useEffect(() => {
    const TIMER = 1000 * 30

    const timerId = setInterval(() => {
      console.log('useOrdersPooling', isLoadingRef.current, modalIsVisibleRef.current)
      if (isLoadingRef.current || modalIsVisibleRef.current) return
      fetch()
    }, TIMER)

    return () => clearInterval(timerId)
  },[])

}

export const useDocumentsDownload = () => {
  return useCallback(async (order) => {
    const key = 'order-documents-download';
    message.loading({ content: 'Загрузка...', key });
    try {
      await CrmService.orders.fetchOrderDocuments(order.id);
      message.success({ content: 'Платежные документы загружены!', key, duration: 2 });
    } catch (e) {
      showErrorDefault(e)
    }
  },[])
}

export const useFetchOrderList = (initFilters) => {
  const [loading, setLoading] = useState(true)
  const loadingRef = useRef(true)
  const [orders,setOrders] = useState([])

  const fetchOrders = useCallback(async (filters = {}) => {
    console.log('fetchOrders', filters)
    setLoading(true)
    loadingRef.current = true
    try {
      const orders = await CrmService.orders.fetchList({
        ...(initFilters || {}),
        limit: 999,
        ...(filters || {})
      })
      setOrders(orders)
    } catch (e) {
      showErrorDefault(e)
    } finally {
      setLoading(false)
      loadingRef.current = false
    }
  },[initFilters])

  return {
    loading, loadingRef, orders, fetchOrders
  }
}
