import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "@/components/util-components/Button/Button";

export const InputFile = ({id, name, value, label, placeholder, type, callback, hasError, className}) => {
    const errorClassList = "";

    const [file, setFile] = React.useState(placeholder);

    const onChange = event => {
        let fileName = event.currentTarget.value;
        fileName = fileName.split("\\").splice(-1, fileName.length - 1,"...").join();
        if (fileName.length > 20) {
            fileName = fileName.split("").splice(0, 21).join("") + "...";
        }
        setFile(fileName)

        // callback(event.target.value)
    }

    return (
        <div>
            <label className={"min-w-50 text-white border-transparent focus:outline-none text-base tracking-wide rounded-md transition-colors duration-200 py-2 px-4 border-px inline-flex items-center bg-yellow-500 hover:bg-yellow-400 cursor-pointer"}
                   htmlFor={id}>{file}</label>
            <input
                className={`hidden form-input rounded-md py-0.5r px-1r border-px border-gray-300 focus:border-indigo-300 outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md ${className}`}
                onChange={event => onChange(event)}
                type={type}
                name={name}
                id={id}
                value={value}
                placeholder={placeholder}/>
        </div>
    );
};

InputFile.defaultProps = {
    className: ""
}
InputFile.propTypes = {}