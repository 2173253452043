import React, { useState } from "react";
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE, SIDE_NAV_COLLAPSED_WIDTH } from '@/constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent'
import utils from "../../utils";
import Logo from "./Logo";
import { toggleCollapsedNav, onMobileNavToggle } from '@/redux/actions/Theme';
import NavProfile from "./NavProfile";
import {toggleCollapsedNavProfile} from "../../redux/actions";
import {THEME_CONFIG} from "@/configs/AppConfig";

const { Sider } = Layout;

export const SideNav = ({navCollapsed, sideNavTheme, routeInfo, hideGroupTitle, toggleCollapsedNav, navProfileCollapsed, toggleCollapsedNavProfile, onMobileNavToggle, isMobile, mobileNav, localization = true }) => {
  const props = { sideNavTheme, routeInfo , hideGroupTitle, localization}

  const [ navProfileIsOpened, updateNavProfileVisibility ] = useState(false)

  const otherProps = {}

  if (!isMobile) {
    otherProps.trigger = null
  }

  const className = ['`d-flex', 'flex-column', 'side-nav', sideNavTheme === SIDE_NAV_DARK? 'side-nav-dark' : 'ant-layout-sider-light', THEME_CONFIG.siderClassName]
  if (isMobile && !navCollapsed) {
    className.push('side-nav--hover')
  }

  return (
    <Sider
      collapsible
      prefixCls={`my-layout-sider`}
      className={className}
      width={SIDE_NAV_WIDTH}
      collapsed={false}
      { ...otherProps }
    >
      <div className="my-layout-sider__logo-wrapper">
        {
          !isMobile
            ? (<div className="my-layout-sider__logo-wrapper-inner">
              <img src={THEME_CONFIG.logo} className={'my-layout-sider__logo'}/>
            </div>)
            : (<div className="my-layout-sider__logo-wrapper-inner">
              <img src={THEME_CONFIG.logo} className={'my-layout-sider__logo'}/>
            </div>)
        }
      </div>
      <Scrollbars className={'flex-grow-1'}>
        <MenuContent
          className={'sidebar-menu'}
          type={NAV_TYPE_SIDE}
          {...props}
        />
      </Scrollbars>
      <NavProfile dropdownPlacement={"topLeft"} inSideBar={true}/>
    </Sider>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navProfileCollapsed, sideNavTheme } =  theme;
  return { navCollapsed, navProfileCollapsed, sideNavTheme }
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle, toggleCollapsedNavProfile})(SideNav);
