const getFileNameFromResponseDefault = (response) => {
  return decodeURI(getFileNameFromHeaders(response.headers)).replace(/\+/g, " ");

  function getFileNameFromHeaders(headers) {
    try {
      let cd = headers["content-disposition"];
      if (!cd)  throw new Error(`header content-disposition not found: ${cd}}`)
      try {
        return cd.match(/filename\=?\"?(.+?)\"?($|;)/i)[1]
      } catch (e) {
        throw new Error(`filename not found in content-disposition: ${cd}}`)
      }
    } catch (e) {
      console.error(e)
      return 'fileName'
    }
  }
}

export const saveResponseAsFile = (response, getFileNameFromResponse = getFileNameFromResponseDefault) => {
  try {
    let blob = new Blob([new Uint8Array(response.data)])
    let filename = getFileNameFromResponse(response)

    const view = {}; //view || {}

    var download_url = window.URL || window.webkitURL || window,
      blob_url = download_url.createObjectURL(blob),
      download_link = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      ),
      use_download_link = "download" in download_link,
      click = function(node) {
        var event = new MouseEvent("click");
        node.dispatchEvent(event);
      },
      is_opera =
        (!!view.opr && !!view.opr.addons) ||
        !!view.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0,
      is_chrome = !!view.chrome && !!view.chrome.webstore,
      is_safari =
        Object.prototype.toString.call(view.HTMLElement).indexOf("Constructor") >
        0 ||
        (!is_chrome && !is_opera && view.webkitAudioContext !== undefined),
      is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent),
      forceable_type = "application/octet-stream",
      type = blob.type,
      forced_download = type === forceable_type;
    // now we can start checking which download we use
    if (
      typeof window === "undefined" ||
      (typeof window.navigator !== "undefined" &&
        /MSIE [1-9]\./.test(window.navigator.userAgent))
    ) {
      return; // IE <10 is not supported
    } else if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else if (use_download_link) {
      download_link.href = blob_url;
      download_link.download = filename;
      click(download_link);
    } else if (
      (is_chrome_ios || (forced_download && is_safari)) &&
      window.FileReader
    ) {
      var reader = new FileReader();
      reader.onloadend = function() {
        var url = is_chrome_ios
          ? reader.result
          : reader.result.replace(/^data:[^;]*;/, "data:attachment/file;");
        var openWindow = window.open(url, "_blank");
        if (!openWindow) window.location.href = url;
      };
    } else {
      /*console.log("branch2");
      if (force) {
        console.log("branch2 force");
        window.location.href = blob_url;
      } else {
        console.log("branch2");
        var openWindow = window.open(blob_url, "_blank");
        console.log("branch2 openWindow", openWindow);
        if (!openWindow) window.location.href = url;
      }*/

      var url = is_chrome_ios
        ? reader.result
        : reader.result.replace(/^data:[^;]*;/, "data:attachment/file;");

      var openWindow = window.open(blob_url, "_blank");
      if (!openWindow) window.location.href = url;
    }
    return Promise.resolve()
  } catch(e) {
    return Promise.reject(e)
  }
}
