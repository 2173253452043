import React from 'react';
import PropTypes from 'prop-types';
import {TableRowDefault} from "@/components/table-components/Rows/TableRowDefault";

export const TableBody = ({headers, rows, Component, props, canDelete, canEdit, canView}) => {

    return (
        <tbody className={"divide-y divide-gray-200"}>
        {rows && rows.map((row, index) => {
            const rowInfo = row?.data || row;

            return (
                Component ?
                    <Component key={index}
                               id={row.id}
                               child={row.child}
                               row={row}
                               canView={canView}
                               canEdit={canEdit}
                               canDelete={canDelete}
                               data={rowInfo}
                               headers={headers}
                               {...props}
                    />
                    : <TableRowDefault canView={canView}
                                       canEdit={canEdit}
                                       canDelete={canDelete}
                                       headers={headers}
                                       id={row.id}
                                       data={rowInfo}/>
            )
        })}
        </tbody>
    );
};

TableBody.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    component: PropTypes.element,
    props: PropTypes.object,
    canView: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
    canEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
    canDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
};
