import React, { useEffect, useCallback, useReducer, useMemo, useState } from 'react'
import {message, Modal, Select, Spin, Typography} from 'antd'
import debounce from 'lodash/debounce';
import {UserApi} from "@/api/users";
import { connect } from 'react-redux'
import {checkoutToUser} from "@/redux/actions";
import {showErrorDefault} from "@/utils/hooks";
import {useDebouncedFetch} from "@/utils/useDebouncedFetch";
import {wait} from "@gagydzer/my-axios-service/src/utils";

const { Option } = Select;

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS": {
      return {
        isLoading: false,
        data: action.payload
      }
    };
    case "LOADING": {
      return {
        isLoading: true,
        data: []
      }
    };
    case "ERROR": {
      return {
        isLoading: false,
        data: []
      }
    }
  }
}

const fetchUsersMethod = search => UserApi.fetchList({search})

const _ModalCheckout = ({ visible, onCancel, checkoutToUser }) => {

  const { loading, results, onSearch } = useDebouncedFetch(fetchUsersMethod)

  const [searchValue,updateSearchValue] = useState(undefined)
  const [isCheckouting, updateIsCheckouting ] = useState(false)

  const handleChange = useCallback((user) => {
    updateSearchValue(user)
  },[searchValue])

  const onOk = async () => {
    updateIsCheckouting(true)
    await checkoutToUser({ userId: searchValue.value })
    updateIsCheckouting(false)
  }

  const selectOptions = useMemo(() => {
    return results.map( user => {
      const name = `${user.firstName} ${user.lastName}`
      return <Option key={user.id} value={user.id}>
        <div>
          <span>{name}</span>
          &nbsp;
          <Typography.Text type="secondary">&lt;{user.email}&gt;</Typography.Text>
        </div>
      </Option>
    })
  },[results])


  return <Modal
    maskClosable={false}
    visible={visible}
    title="Переключение на пользователя"
    okText="Переключиться"
    cancelText="Назад"
    onCancel={onCancel}
    onOk={onOk}
  >
    <Select
      showSearch
      labelInValue
      placeholder="Выберите пользователя"
      notFoundContent={loading ? <Spin size="small"/> : null}
      filterOption={false}
      defaultActiveFirstOption={false}
      showArrow={false}
      onSearch={onSearch}
      onChange={handleChange}
      value={searchValue}
      style={{width: '100%'}}
    >
      {selectOptions}
    </Select>
  </Modal>
}


const mapStateToProps = (state, props) => {
  return props
}

export const ModalCheckout = connect(mapStateToProps, { checkoutToUser })(_ModalCheckout)
