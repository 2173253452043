import createMask from 'imask/esm/masked/factory'
import {useMemo} from "react";

const phoneMask = createMask({
  mask: '+{7}(000)000-00-00'
})

const cutStart = (value) => {
  if (!value) return null
  const vStr = value.toString()
  if (vStr.startsWith('8') || vStr.startsWith('7')) return vStr.substr(1)
  else return vStr
}

export const formatPhoneNumber = (phone) => {
  if (!phone) return null
  let phonePrep = typeof phone === "number" ? phone.toString() : phone
  phonePrep = cutStart(phonePrep)
  return phoneMask.resolve(phonePrep)
}
