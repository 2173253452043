import React, {useEffect, useState} from "react";
import {DatePicker, Button} from 'antd'

const { RangePicker } = DatePicker

export const FilterRangePicker = ({ visible, onConfirm, confirm, value }) => {

  const [open, setOpen] = useState(true)
  const [interval, setInterval] = useState([])

  useEffect(() => setOpen(visible),[visible])

  /*useEffect(() => {
    setInterval(value)
  },[value])*/

  const onChange = (interval) => {
    console.log('FilterRangePicker', interval)
    setInterval(interval.map( v => v.clone() ))
  }

  const onOk = () => {
    setOpen(false)
    onConfirm(interval)
    confirm({ closeDropdown: true })
  }

  const onReset = () => {
    setOpen(false)
    confirm({ closeDropdown: true })
    setInterval([])
    onConfirm([])
  }

  const footer = () => <div className={"d-flex justify-content-between p-1"} style={{ width: '100%' }}>
    <Button size={"small"} type={"link"} onClick={onReset}>Сбросить</Button>
    <Button size={"small"} type={"primary"} disabled={!interval.length} onClick={onOk}>ОК</Button>
  </div>

  return <RangePicker className={"custom-date-picker--no-input"} open={open} value={interval} onChange={onChange} renderExtraFooter={footer}/>
}
